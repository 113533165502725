.wordpress-post-view .event-post-view {
    .post-entry {
        overflow: inherit;
        margin-bottom: 55px;
        iframe {
            width: 100%;
        }
    }
    .button-primary, .button-secondary {
        display: inline-block;
        font-weight: 700;
        text-align: center;
    }
    .page-title {
        font-size: 38px;
        @include media-query(767px) {
            display: none;
        }
        &.responsive-page-title {
            display: none;
            font-size: 32px;
            @include media-query(767px) {
                display: block;
            }
        }
    }
    .past-event-notice {
        font-weight: 700;
        margin-top: -8px;
        margin-bottom: 20px;
        span {
            display: inline-block;
            padding: 5px 15px;
            color: $brand-danger;
            border: 1px solid $brand-danger;
            background-color: rgba($brand-danger, 0.08);
            border-radius: 4px;
        }
    }
    .tribe-events-schedule {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 700;
    }
    .event-social, .event-details {
        margin-top: 40px;
    }
    .event-share, .calendar {
        display: inline-block;
        vertical-align: top;
        font-size: 0;
    }
    .event-share {
        margin-right: 60px;
    }
    .calendar {
        margin-top: 32px;
        a {
            margin-right: 10px;
            margin-bottom: 10px;
            font-size: 12px;
            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }
    .event-details, .event-related {
        .col {
            width: 33.33%;
            flex-basis: 33.33%;
        }
        .event-box {
            width: 100%;
            height: 100%;
            padding: 25px 30px;
            border: 1px solid #eaeaea;
            border-radius: 10px;
            box-shadow: 0 0 3px 0 rgba(0,0,0,0.15);
        }
    }
    .event-details {
        position: relative;
        padding-top: 40px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 15px;
            display: block;
            width: calc(100% - 30px);
            height: 1px;
            background-color: #eaeaea;
        }
        .event-box {
            .title {
                margin-top: 0;
                margin-bottom: 20px;
                padding-bottom: 8px;
                border-bottom: 3px solid $brand-primary;
                color: $brand-primary;
                font-size: 22px;
                font-weight: 700;
                text-transform: uppercase;
            }
            h3 {
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 700;
            }
            .address {
                p {
                    margin: 0;
                }
            }
            .actions {
                margin-top: 25px;
                .action {
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .button-primary, .button-secondary {
                        min-width: 160px;
                    }
                }
            }
        }
    }
    .event-related {
        position: relative;
        margin-top: 25px;
        padding-top: 60px;
        .col {
            @include media-query(767px) {
                width: 50%;
                flex-basis: 50%;
            }
            @include media-query(539px) {
                width: 100%;
                flex-basis: 100%;
            }
        }
        .event-box {
            padding: 0;
            .event-image {
                position: relative;
                padding-bottom: 66%;
                height: 0;
                border-bottom: 2px solid #eaeaea;
                &.no-img .image {
                    background-image: url('../images/icon-calendar.svg');
                    background-size: 19.5% 19.5%;
                }
                .image {
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #dedede;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    border-radius: 10px 10px 0 0;
                }
            }

            .event-content {
                padding: 25px 30px;
            }
            h3 {
                margin-top: 0;
                font-weight: 700;
            }
            .tribe-events-schedule {
                margin-bottom: 10px;
                font-size: 15px;
            }
            .excerpt {
                margin-bottom: 20px;
            }
        }
    }
}

.events-listing {
    .page-title-wrapper {
        margin-bottom: 0;
    }
    .events-intro {
        margin-bottom: 30px;
    }
    .events-filters, .filters, .filter, .filter > form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .events-filters {
        background-color: $brand-primary;
        margin-bottom: 30px;
        padding: 15px 30px;
        border-radius: 10px;
        @include media-query(767px) {
            padding: 15px 20px;
        }
        .filters {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-grow: 1;
            .filter {
                flex-basis: calc(50% - 15px);
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                > form {
                    flex-grow: 1;
                }
                .input-group {
                    position: relative;
                    display: inline-block;
                    flex-grow: 1;
                    margin-left: 20px;
                }
                input {
                    width: 100%;
                    height: 48px;
                    padding: 8px 10px;
                    border: none;
                    box-shadow: none;
                    border-radius: 10px;
                }
                &.search {
                    button {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        transform: translateY(-50%);
                        height: 38px;
                        width: 38px;
                        background: none;
                        border: none;
                        &:before {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-family: 'icomoon';
                            content: "\e912";
                            width: 100%;
                            height: 100%;
                            font-size: 25px;
                            line-height: 38px;
                            color: #000;
                        }
                    }
                    input {
                        padding-right: 58px;
                    }
                }
                @include media-query(767px) {
                   flex-basis: 100%;
                   label {
                        width: 70px;
                   }
                   .input-group {
                        width: calc(100% - 80px);
                        margin-left: 10px;
                   }
                }
            }
        }
        .search-title, .filter-title {
            margin: 0;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
        }
        .search-title {
            margin-right: 7%;
            font-size: 24px;
            @include media-query(991px) {
                margin-bottom: 15px;
                margin-right: 0;
            }
        }
        .filter-title {
            font-size: 16px;
        }
    }
    .row-post .col {
        width: 100%;
        flex-basis: 100%;
    }
    .event-list-item {
        padding: 5%;
        .inner-item {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            .date {
                width: 100px;
                margin-right: 4%;
                text-align: center;
                background-color: $brand-primary;
                color: #fff;
                .postDate {
                    width: 100%;
                }
            }
            .content {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;
                flex: 1;
            }
            .infos, .image {
                flex: 1;
            }
            .infos {
                margin-right: 4%;
                h2 {
                    font-weight: 700;
                    margin-top: 0;
                    line-height: 1;
                }
                .tribe-events-schedule {
                    font-size: 15px;
                    font-weight: 700;
                }
                .read-more {
                    a {
                        display: inline-block;
                        margin-top: 15px;
                        background-color: $brand-secondary;
                        color: #fff;
                        font-weight: 700;
                        text-transform: uppercase;
                        padding: 5px 15px;
                        border-radius: 4px;
                        @include transition(400ms);
                        &:hover {
                            background-color: $brand-primary;
                        }
                    }
                }
            }
            @include media-query(767px) {
                .infos, .image {
                    flex-basis: 100%;
                }
                .image {
                    margin-bottom: 20px;
                    order: 2;
                }
                .infos {
                    order: 3;
                    margin-right: 0;
                }
            }
        }
    }
}