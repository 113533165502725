body.cms-brands {
  .column.main {
    padding-top:40px;
  }
}
.column.main {
  h1 {
    margin: 0 0 20px 0;
    padding: 0 0 10px;
    text-transform: capitalize;
    color: $pt-black;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 36px;
    border-bottom: 4px solid $brand-secondary;
    text-align: left;
    font-family: 'effra';
    @include media-query(767px) {
      font-size: 34px;
    }
    @include media-query(539px) {
      font-size: 28px;
    }
  }
  .brand-searcher {
    background: $brand-secondary;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
    h3 {
      color: white;
      text-align: center;
      margin: 7px auto;
      font-weight: bold;
      text-transform: capitalize;
      font-family: 'effra';
    }
    .ambrands-search-wrapper {
      float: none;
      width: 100%;
      @include media-query(600px) {
        width: calc(100% - 20px);
      }
      .input-outer {
        position: relative;
      }
      .ambrands-search-input {
        background-color: #fff;
        padding: 10px;
        height: 44px;
        border-radius: 6px;
        font-family: 'effra';
        &::-webkit-input-placeholder {
          color: #333;
          text-transform: capitalize;
          font-family: inherit;
          font-size: 14px;
          font-weight: 400;
          font-family: 'effra';
        }
        &::-moz-placeholder {
          color: #333;
          text-transform: uppercase;
          font-family: inherit;
          font-size: 14px;
          font-weight: 400;
          font-family: 'effra';
        }
        &:-ms-input-placeholder {
          color: #333;
          text-transform: uppercase;
          font-family: inherit;
          font-size: 14px;
          font-weight: 400;
          font-family: 'effra';
        }
        &:-moz-placeholder {
          color: #333;
          text-transform: uppercase;
          font-family: inherit;
          font-size: 14px;
          font-weight: 400;
          font-family: 'effra';
        }
      }
      #livesearch {
        top: 39px;
        width: 100%;
        border: none;
        border-radius: 0 0 6px 6px;
        box-shadow: 0 5px 4px 0px rgba(0,0,0,0.2);
        color: #000;
        float: left;
        position: absolute;
        background: #FFF;
        z-index: 9;
      }
        #livesearch {
            a {
                color: #000;
                width: 100%;
                float: left;
                padding: 5px;
                &:hover {
                    color: $brand-primary;
                    text-decoration: underline;
                }
            }
        }
    }
  }

  .brands-filters {
    position: relative;
    margin-bottom: 38px;
    .letters-box {
      display: flex;
      flex-basis: auto;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      .letter {
        margin: 0 2px 10px;
        border: 1px solid #e1e5eb;
        background: $pt-black;
        color: #fff;
        text-transform: uppercase;
        padding: 5px 10px;
        display: inline-block;
        box-sizing: border-box;
        font-weight: bold;
        font-size: 20px;
        transition: all 0.15s ease-out;
        &:first-child() {
          margin-left: 0;
        }
        &:last-child() {
          margin-right: 0;
        }
        &.disabled {
          opacity: 0.5;
          cursor: default;
          pointer-events: none;
          background: $pt-gray-04;
          color: $pt-gray-09;
        }
        &:not(.disabled):hover {
          border-color: $brand-hover;
        }
        &:focus {
          background: $brand-hover!important;
          color: white;
          outline: 2px solid #fff;
          box-shadow: 0 0 0 4px $brand-hover;
        }
        &:hover {
          background: $brand-hover!important;
          color: white;
          outline: 2px solid #fff;
          box-shadow: 0 0 0 4px $brand-hover;
        }
      }
      &#clear-filter {
        position: absolute;
        top: 100%;
        left: 0;
        align-items: flex-start;
        width: 100%;
        height: 28px;
        a {
          margin-bottom: 0;
          padding: 3px 8px;
          font-size: 16px;
          text-decoration: inherit;
        }
      }
    }
  }
  .brand-listing {
    dl {
      @include media-query(768px) {
        margin-bottom: 10px;
      }
      &.brands-letter {
        .letter {
          position: relative;
          border-bottom: 3px solid $brand-secondary;
          font-size: 18px;
          font-weight: 400;
          &:focus {
            outline: none;
          }
          .title {
            padding: 5px 48px 5px 8px;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 30px;
            background: transparent;
            color: $pt-black;
            font-family: 'effra';
            @include media-query(768px) {
              font-size: 25px;
            }
          }
          [class^="icon-"] {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            display: none;
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 20px 12px;
            @include media-query(768px) {
              display: block;
            }
          }
          .icon-close {
            background-image: url('../images/arrow-down-blue.svg');
          }
          .icon-open {
            background-image: url('../images/arrow-up-blue.svg');
          }
        }
      }
    }
    dd {
      ul {
        &.bare-list {
          display: block;
          margin: 40px 0 60px;
          padding-left: 0;
          columns: 4;
          column-gap: 15px;
          @include media-query(991px) {
            margin: 35px 0 55px;
            columns: 3;
          }
          @include media-query(768px) {
            margin: 20px 0 40px;
            columns: 2;
          }
          @include media-query(460px) {
            columns: 1;
          }
          li {
            list-style: none;
            &.brand-item {
              margin: 0 0 8px;
              .item {
                display: inline-block;
                font-size: 16px;
                line-height: 1em;
                color: $pt-gray-09;
                @include transition(400ms);
                &:hover, &:focus {
                  color: $brand-primary;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}
