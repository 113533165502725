.checkout-index-index {
    #shippingpopupiframe {
        max-width: 100%;
    }
}
.epicor_comm-onepage-shippingpopup {
    margin-right: 8px;
    .scrollToTop, .helpcrunch-iframe-wrapper, #_hj_feedback_container {
        display: none !important;
    }
    #maincontent, .column.main {
        padding-right: 0;
        padding-left: 0;
    }
    #maincontent {
        margin-top: 10px;
    }
    .admin__data-grid-toolbar {
        margin: 0;
    }
    button, .form-button {
        display: inline-block;
        padding: 5px 10px 4px;
        background: $brand-primary;
        border: none;
        border-radius: 4px;
        font: 400 inherit inherit;
        @include transitions(400ms);
        &:hover, &:focus {
            background: $brand-secondary;
        }
        &.action-previous, &.action-next {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: calc(50% - 5px);
                left: 50%;
                display: block;
                background: url('../images/arrow-down-white.svg') no-repeat center center;
                background-size: contain;
                width: 15px;
                height: 9px;
            }
        }
        &.action-previous {
            margin-right: 10px;
            &:before {
                left: calc(50% - 2px);
                transform: translate(-50%) rotate(90deg);
            }
        }
        &.action-next {
            margin-left: 10px;
            &:before {
                transform: translate(-50%) rotate(-90deg);
            }
        }
    }
    .admin__data-grid-header.admin__data-grid-toolbar label.admin__control-support-text {
        padding-top: 0;
        margin-bottom: 0;
    }
    .admin__data-grid-pager-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        width: 100%;
        flex-basis: 100%;
        select {
            display: inline-block;
            color: #373737;
            padding: 5px 35px 4px 14px;
            width: 100%;
            max-width: 100%;
            margin: 0 5px 0 0;
            border: none;
            box-shadow: none;
            border-radius: 5px;
            appearance: none;
            background-color: $fc_grey;
            background-image: url(../images/arrow-down-black.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) center;
            background-size: 12px 8px;
        }
        .admin__data-grid-pager {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            padding: 0 15px;
            .page-control-input {
                width: 31px !important;
                height: 31px;
                text-align: center;
            }
            label {
                padding: 0 3px;
            }
        }
        .admin__control-support-text {
            margin-left: 5px;
            padding-top: 0;
            &:last-child {
                flex-grow: 1;
                margin-right: 0;
                text-align: right;
            }
        }
    }
    .admin__data-grid-header-row {
        display: block;
        float: none;
    }
    .table-wrapper {
        margin: 15px 0 0;
        overflow: auto;
    }
    .data-grid {
        margin-bottom: 0;
        thead {
            background-color: #fff;
            th {
                color: $brand-primary;
                border-bottom: 1px solid $brand-primary;
            }
            select {
                display: inline-block;
                color: #373737;
                padding: 11px 35px 11px 14px;
                width: 100%;
                min-width: 130px;
                margin: 0 5px 0 0;
                border: none;
                box-shadow: none;
                border-radius: 5px;
                appearance: none;
                background-color: $fc_grey;
                background-image: url(../images/arrow-down-black.svg);
                background-repeat: no-repeat;
                background-position: calc(100% - 10px) center;
                background-size: 12px 8px;
            }
        }
        .no-display {
            display: none !important;
        }
    }
}

@include media-query(640px) {
    .epicor_comm-onepage-shippingpopup {
        .admin__data-grid-pager-wrap .admin__control-support-text {
            &:last-child {
                width: 100%;
                margin-top: 15px;
                margin-left: 0;
                text-align: left;
            }
        }
        .table-wrapper .data-grid {
            thead {
                display: none;
            }
            tr, td {
                display: block;
            }
            tr {
                margin-bottom: 10px;
                padding: 10px;
                border: 1px solid #ddd;
                @include transitions(400ms);
                &:hover {
                    border-color: $brand-primary;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            td {
                position: relative;
                padding: 2px 0 2px 100px;
                border: none;
                &:before {
                    content: attr(data-th) ': ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 85px;
                    font-weight: 700;
                    color: $brand-primary;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@include media-query(440px) {
    .epicor_comm-onepage-shippingpopup {
        .admin__data-grid-pager-wrap {
            .admin__data-grid-pager {
                width: 100%;
                padding: 15px 0 0;
            }
        }
    }
}