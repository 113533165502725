$mobile-width: 600px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin media-query($queryMax, $queryMin: null) {
  @if $queryMin == null {
    @media only screen and (max-width: $queryMax) { @content; }
  } @else {
    @media only screen and (min-width: $queryMin) and (max-width: $queryMax) { @content; }
  }
}

@mixin media-query-orientation($query, $orientation) {
  @media only screen and (max-device-width: $query) and (orientation: $orientation)  { @content; }
}