@mixin transitions($args)
{
  -webkit-transition: background-color $args linear;
  -moz-transition: background-color $args linear;
  -o-transition: background-color $args linear;
  transition: background-color $args linear;
}

@mixin flash-transition($args)
{
  -moz-transition: background-color $args ease-in-out, color $args ease-in-out, border-color $args ease-in-out;
  -o-transition: background-color $args ease-in-out, color $args ease-in-out, border-color $args ease-in-out;
  -webkit-transition: background-color $args ease-in-out, color $args ease-in-out, border-color $args ease-in-out;
  transition: background-color $args ease-in-out, color $args ease-in-out, border-color $args ease-in-out;
}