.nav-sections-item-content > .mx-megamenu {
  display: block;
}

/* Fix the iOS background scrolling issue */
.megamenu-init-toggle.nav-open,
.megamenu-init-toggle body {
  height: 100%;
  overflow: hidden;
}
/* Fix the iOS background scrolling issue */

.mx-megamenu .navigation > ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mx-megamenu .navigation .mx-megamenu__item.level-top {
  padding: 0.5em;
}

.mx-megamenu .navigation .mx-megamenu__item.level-top .mx-megamenu__link {
  display: block;
  padding: 0.5em 0;
}

.mx-megamenu .navigation .mx-megamenu__item > .mx-megamenu__link > span {
  display: block;
}

.mx-megamenu .navigation .mx-megamenu__item.level-top .mx-megamenu__submenu {
  display: none;
  width:100%!important;
}

.mx-megamenu .navigation .mx-megamenu__item.current > .mx-megamenu__submenu {
  display: block;
}

.mx-megamenu__sidebar,
.mx-megamenu__header {
  display: none;
}

.yo-ico {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-top: -0.2em;
  &:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &.yo-left {
    margin-right: 0.4em;
  }
  &.yo-right {
    margin-left: 0.4em;
  }
  &.yo-mail:before {
    background-image: url(../images/yoma-icon/mail.svg);
  }
  &.yo-telephone:before {
    background-image: url(../images/yoma-icon/telephone.svg);
  }
  &.yo-map:before {
    background-image: url(../images/yoma-icon/map.svg);
  }
}

.shop-range-menu {
  .mx-megamenu__content {
    margin-top: -21px;
    @include media-query(992px) {
      margin-top: -5px;
    }
  }
  .level1.mx-megamenu__category > a {
    min-height: 60px;
    @include media-query(767px) {
      min-height: 0;
    }
  }
}
.shop-category-menu {
  .mx-megamenu__content {
    margin-top: 0;
    @include media-query(992px) {
      margin-top: -5px;
    }
  }
}

.about-menu {
  .yo-icon {
    font-size: 18px;
  }
  .social-media {
    @include media-query(785px) {
      width: 38px;
      height: 38px;
      margin-right: 5px;
    }
  }
}

.follow-us-menu-section {
  .mx-megamenu__submenu {
    font-size: 0;
  }
}

//@media screen and (min-width: 1025px) {
@include media-query(4040px, 767px) {
  .mx-megamenu .navigation > ul {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }

  .mx-megamenu .navigation .mx-megamenu__item.level-top {
    position: static;
    padding: 0 1em;
  }

  .mx-megamenu .navigation .mx-megamenu__item.level-top.current > .mx-megamenu__submenu {
    display: block;
    left: 0;
    right: 0;
    position: absolute;
    top: 100%;
    border-left: 0;
    border-right: 0;
    margin-left: 0;
    background: white;
    z-index: 999999;
  }

  .mx-megamenu .navigation .mx-megamenu__item.level-top > .mx-megamenu__link {
    display: inline-block;
    padding: 0 20px;
    &:before {
      content: '';
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 1px;
      height: 80%;
      background-color: rgba(255,255,255,0.3);
    }
  }
  .mx-megamenu .navigation .mx-megamenu__item.level-top  {
    display: inline-block;
    padding: 0;
    &:last-child {
      > .mx-megamenu__link {
        &:after {
          content: '';
          right: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 1px;
          height: 80%;
          background-color: rgba(255,255,255,0.3);
        }
      }
    }
  }

  .mx-megamenu .navigation .mx-megamenu__item.level-top.current > .mx-megamenu__link {
    position: relative;
  }

  .mx-megamenu__content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include media-query(767px) {
      display: block;
    }
  }

  .shop-category-menu, .shop-range-menu, .engineer-training-menu, .about-menu {
    .mx-megamenu__content {
      flex-wrap: wrap;
      margin-right: -12px;
      margin-left: -12px;
      .level1 {
        width: calc(16.6% - 24px);
        flex-basis: calc(16.6% - 24px);
        margin: 0 12px;
        @include media-query(992px) {
          width: calc(25% - 24px);
          flex-basis: calc(25% - 24px);
        }
        @include media-query(767px) {
          width: calc(100% - 24px);
          flex-basis: calc(100% - 24px);
        }
      }
    }
  }

  .engineer-training-menu {
    .mx-megamenu__content {
      .level1 {
        width: calc(33.33% - 24px);
        flex-basis: calc(33.33% - 24px);
        @include media-query(767px) {
          width: calc(100% - 24px);
          flex-basis: calc(100% - 24px);
        }
      }
    }
  }

  .about-menu {
    .mx-megamenu__content {
      .level1 {
        width: calc(33.33% - 24px);
        flex-basis: calc(33.33% - 24px);
        @include media-query(767px) {
          width: calc(100% - 24px);
          flex-basis: calc(100% - 24px);
        }
      }
    }
  }

.mx-megamenu__content {
  .level1 {  
    &[class*="col-"] {
      margin: 0 12px;
      @include media-query(767px) {
        width: calc(100% - 24px);
        flex-basis: calc(100% - 24px);
      }
    }
    &.col-2  { width: calc(16.66% - 24px); flex-basis: calc(16.66% - 24px); }
    &.col-3  { width: calc(25% - 24px); flex-basis: calc(25% - 24px); }
    &.col-4  { width: calc(33.33% - 24px); flex-basis: calc(33.33% - 24px); }
    &.col-5  { width: calc(41.666667% - 24px); flex-basis: calc(41.666667% - 24px); }
    &.col-6  { width: calc(50% - 24px); flex-basis: calc(50% - 24px); }
    &.col-7  { width: calc(58.333333% - 24px); flex-basis: calc(58.333333% - 24px); }
    &.col-8  { width: calc(66.66% - 24px); flex-basis: calc(66.66% - 24px); }
    &.col-9  { width: calc(75% - 24px); flex-basis: calc(75% - 24px); }
    &.col-10 { width: calc(83.333333% - 24px); flex-basis: calc(83.333333% - 24px); }
    &.col-12 { width: calc(100% - 24px); flex-basis: calc(100% - 24px); }
  }
}

  .mx-megamenu .navigation .mx-megamenu__item .mx-megamenu__categories {
    display: block;
    width: 100%;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    max-height: 500px;
  }

  .mx-megamenu__main-content,
  .mx-megamenu__categories {
    padding: 0 1em;
  }

  .mx-megamenu .navigation .mx-megamenu__item .mx-megamenu__categories .mx-megamenu__category {
    margin: 0 0.5em;
  }

  .mx-megamenu__item .mx-megamenu__categories .mx-megamenu__category>.mx-megamenu__link {
    font-weight: bold;
  }

  .mx-megamenu .navigation .mx-megamenu__item .mx-megamenu__categories .mx-megamenu__submenu {
    display: block;
    flex-direction: column;
  }

  .mx-megamenu .navigation .mx-megamenu__item .mx-megamenu__categories .mx-megamenu__category-item .mx-megamenu__link {
    padding: 0 3px;
  }

  .mx-megamenu__sidebar,
  .mx-megamenu__header,
  .mx-megamenu__footer {
    display: flex;
    width: auto;
    text-align: center;
  }

  .mx-megamenu__header,
  .mx-megamenu__footer {
    align-self: center;
  }
}

@include media-query(991px, 768px) {
  .mx-megamenu .navigation > ul {
    position: relative;
    flex-direction: row;
    justify-content: center;
  }

  .mx-megamenu .navigation .mx-megamenu__item.level-top {
    position: static;
    padding: 0 1em;
  }

  .mx-megamenu .navigation .mx-megamenu__item.level-top.current > .mx-megamenu__submenu {
    display: block;
    left: 0;
    position: absolute;
    top: 100%;
    border-left: 0;
    border-right: 0;
    margin-left: 0;
    background: white;
    z-index: 99;
  }

  .mx-megamenu .navigation .mx-megamenu__item.level-top > .mx-megamenu__link {
    display: inline-block;
    padding: 0;
    width: 100%;
  }

  .mx-megamenu .navigation .mx-megamenu__item.level-top.current > .mx-megamenu__link {
    position: relative;
  }

  .mx-megamenu__content {
    display: flex;
    justify-content: flex-start;
  }

  .mx-megamenu .navigation .mx-megamenu__item .mx-megamenu__categories {
    display: block;
    width: 100%;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    max-height: 500px;
  }

  .mx-megamenu__main-content,
  .mx-megamenu__categories {
    padding: 0 1em;
  }

  .mx-megamenu .navigation .mx-megamenu__item .mx-megamenu__categories .mx-megamenu__category {
    margin: 0 0.5em;
  }

  .mx-megamenu__item .mx-megamenu__categories .mx-megamenu__category>.mx-megamenu__link {
    font-weight: bold;
  }

  .mx-megamenu .navigation .mx-megamenu__item .mx-megamenu__categories .mx-megamenu__submenu {
    display: block;
    flex-direction: column;
  }

  .mx-megamenu .navigation .mx-megamenu__item .mx-megamenu__categories .mx-megamenu__category-item .mx-megamenu__link {
    padding: 0 3px;
  }

  .mx-megamenu__header,
  .mx-megamenu__footer {
    align-self: center;
  }
}

@media (min-width: 768px) {
  .main-menu-container {
    .mx-megamenu {
      &.topmenu {
        .navigation {
          ul li a.menu-view-more {
            .glyphicon.glyphicon-menu-down:before {
              content: none;
            }
          }
        }
      }
    }
  }
}

.main-menu-container {
  background: $brand-secondary;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &:after {
    content: '';
    background: rgb(45,41,38);
    background: linear-gradient(90deg, rgba(45,41,38,1) 0%, rgba(194,196,196,1) 10%, rgba(224,225,225,1) 40%, rgba(45,41,38,1) 80%, rgba(224,225,225,1) 90%, rgba(83,86,90,1) 99%);
    height: 10px;
    display: block;
    @media (max-width: 767px) {
      height: 5px;
    }
  }
  
  .mx-megamenu {
    &.topmenu {
      .navigation {
        ul {
          li {
            background: $brand-secondary;
            padding: 10px 0;
            text-align: center;
            box-sizing: border-box;
            @include flash-transition(100ms);
            .mx-megamenu__footer {
              .menu-footer-sections {
                .menu-footer-button {
                  font-family: 'museo-slab';
                }
              }
            }
            > a {
              position: relative;
              &:before, &:after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                width: 1px;
                height: 80%;
                background-color: rgba(255,255,255,0.3);
              }
              &:before {
                content: none;
                left: 0;
              }
              &:after {
                right: 0;
              }
            }
            &:last-child {
              > a:after {
                content: none;
              }
            }
            @include media-query(991px) {
              &:first-child {
                > a {                
                  &:before {
                    content: none;
                  }
                }
              }
              &:last-child {
                > a {                
                  &:after {
                    content: none;
                  }
                }
              }
            }
            a {
              font-family: 'effra';
              color: white;
              font-size: 16px;
              font-weight: bold;
              line-height: 20px;
              text-transform: uppercase;
              transition: all 0.15s ease-out;
              &.menu-view-more {
                color: $brand-primary;
                font-size: 14px;
                font-weight: 400;
                text-transform: none;
                > span {
                  display: inline-block;
                  vertical-align: middle;
                  color: $brand-primary;
                }
                &:hover {
                  color: $pt-black;
                  border-bottom: 1px solid $brand-primary;
                }
                .glyphicon {
                  font-size: 85%;
                  &.glyphicon-menu-down {
                    display: inline-block;
                    width: 12px;
                    height: 6px;
                    background: url('../images/arrow-down-blue.svg') no-repeat center center;
                    background-size: contain;
                    &:before {
                      content: none;
                    }
                  }
                }
              }
              span {
                span:not(.yo-ico) {
                  display: inline-block;
                  font-size: 16px;
                  width: 100%;
                  clear: right;
                  @include media-query(920px) {
                    font-size: 13px;
                  }
                }
              }
              @include media-query(920px) {
                font-size: 13px;
                line-height: 18px;
              }
            }
            &.current {
              background: white;
              color: $brand-primary;
              span {
                color: $brand-primary;
                span {
                  color: $brand-primary;
                }
              }
              .level0 .mx-megamenu__footer {
                width: 100%;
                margin-top: 20px;
                .block-static-block {
                  width: 100%;
                }
              }
              .mx-megamenu__submenu.submenu {
                display: block;
                width: 100%;
                padding: 12px 20px 20px;
                border-radius: 0 0 10px 10px;
                box-shadow: 0 9px 12px -4px rgba(0, 0, 0, 0.4);
                .level1 {
                  position: relative;
                  text-align: left;
                  &.manufacturer-2col-right {
                    .mx-megamenu__submenu {
                      display: flex;
                    }
                  }
                  .mx-megamenu__submenu {
                    display: block;
                  }
                }
                .level1.mx-megamenu__category > a {
                  border-bottom: 2px solid $pt-black;
                  margin-top: 21px;
                  margin-bottom: 1.5em;
                  display: table;
                  width: 100%;
                  height: 1em!important;
                  @include media-query(992px) {
                    margin-top: 5px;
                  }
                  span {
                    display: table-cell;
                    vertical-align: bottom;
                    color: $pt-black;
                  }
                  
                }
                .level1.manufacturer-2col-left {
                  .mx-megamenu__submenu {
                    .mx-megamenu__link {
                      width: 100%;
                      text-align: left;
                      a {
                        text-transform: none;
                        color: #333;
                        font-size: 14px;
                        font-family: 'museo-slab';
                        font-weight: 500;
                      }
                    }
                  }
                }
                .level1.manufacturer-2col-right {
                  .mx-megamenu__submenu {
                    .mx-megamenu__link {
                      a {
                        text-transform: none;
                        color: #333;
                        font-size: 14px;
                      }
                    }
                  }
                }
                .level2.mx-megamenu__item, .level2.mx-megamenu__item_inner {
                  display: block;
                  margin-bottom: 0.5em;
                  @include media-query(992px) {
                    margin-bottom: 0.35em;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                  a {
                    text-transform: none;
                    color: #333;
                    font-size: 14px;
                    font-weight: 400;
                    span {
                      color: #333;
                    }
                    &:hover, &:focus {
                      span {
                        color: $brand-primary;
                        text-decoration: underline;
                      }
                    }
                  }
                }
                .manufacturer-2col-right {
                  .level2.mx-megamenu__item, .level2.mx-megamenu__item_inner {
                    display: flex;
                  }
                }
                .follow-us-menu-section {
                  .level2.mx-megamenu__item {
                    display: inline-block;
                  }
                }
              }
            }
            &.about-menu {
              .submenu {
                .mx-megamenu__content {
                  .social-media-outter {
                    a.social-link {
                      color: #fff;
                      background: $pt-black;
                      font-size: 20px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-right: 10px;
                      height: 44px;
                      width: 44px;
                      border-radius: 30px;
                      &:hover {
                        background: $pt-gray-09;
                      }
                    }
                  }
                }
              }
            }
            .social-media-outter {
              .social-media {
                &.twitter {
                  background-image: url(../images/twitter-icon.svg);
                  &:before {
                    content: none;
                  }
                }
                &.linkedin {
                  background-image: url(../images/linkedin-icon.svg);
                  &:before {
                    content: none;
                  }
                }
                &.youtube {
                  background-image: url(../images/youtube-icon.svg);
                  &:before {
                    content: none;
                  }
                }
                &.facebook {
                  background-image: url(../images/facebook-icon.svg);
                  &:before {
                    content: none;
                  }
                }
                &.instagram {
                  background-image: url(../images/instagram-icon.svg);
                  background-color: #fff;
                  background-size: 44px;
                  &:before {
                    content: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.mx-megamenu__content {
  .mx-megamenu__submenu {
    .level2 {
      a {
        font-family: 'museo-slab'!important;
        font-weight: 500;
      }
    }
  }
}

.manufacturer-2col-right {
  .mx-megamenu__submenu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    max-height: 320px;
    height: 100%;
    .level2 {
      width: 20%;
    }
  }
}

/* Sidebar Block */
.sidebar-block {
  &#layered-navigation {
    #am-shopby-container {
      .block-subtitle {
        display: block;
        margin-bottom: 5px;
        color: $pt-black;
        font-weight: 700;
        text-transform: capitalize;
      }
      .items {
        padding-left: 0;
        .amshopby-item {
          position: relative;
          display: block;
          margin-bottom: 3px;
          padding-left: 18px;
          color: $brand-primary;
          .amshopby-remove {
            top: 3px;
          }
          .amshopby-filter-name {
            font-weight: 700;
            &:after {
              content: ':';
            }
          }
          .amshopby-filter-value {
            display: inline-block;
            margin-left: 2px;
          }
          .action.remove {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            color: #fff;
            line-height: 1;
            @include transitions(400ms);
            &:before {
              color: $brand-primary;
              content: "\f057";
              font-family: "FontAwesome";
              font-size: 14px;
              font-weight: 400;
            }
            span {
              display: none;
            }
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
      + .filter-actions {
        margin-bottom: 20px;
        a {
          position: relative;
          display: inline-block;
          padding: 4px 8px 4px 24px;
          background-color: $brand-primary;
          color: #fff;
          border: 1px solid $brand-primary;
          border-radius: 4px;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          line-height: 1;
          @include transitions(400ms);
          &:before {
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translateY(-50%);
            content: "\f057";
            font-family: "FontAwesome";
            font-size: 14px;
            font-weight: 400;
            @include transitions(400ms);
          }
          &:hover {
            background-color: #fff;
            color: $brand-primary;
            &:before {
              color: $brand-primary;
            }
          }
        }
      }
    }
    [data-role="content"] {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  [data-role="content"], [data-role="content"] .filter-content, [data-role="trigger"] {
    &:focus {
      outline: 0;
    }
  }
  [data-role="trigger"] {
    @include media-query(767px) {
      cursor: pointer;
    }
  }
  [data-role="content"] {
    margin-bottom: 40px;
    padding-bottom: 25px;
    border-bottom: 1px solid #eaeaea;
  }
  .block-title {
    padding-bottom: 8px;
    margin: 0 0 20px;
    border-bottom: 3px solid $brand-secondary;
    color: $pt-black;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
    @include media-query(767px) {
      padding-right: 25px;
      background: url('../images/arrow-down-blue.svg') no-repeat 100% 5px;
      background-size: 20px 10px;
      border-bottom-width: 2px;
      font-size: 18px;
      margin-bottom: 15px;
      padding-bottom: 12px;
    }
  }
  &.active .block-title {
    @include media-query(767px) {
      background-image: url('../images/arrow-up-blue.svg');
    }
  }
  &#search-block {
    #search_mini_form {
      position: relative;
      width: 100%;
      input[type="text"] {
        float: none;
        box-shadow: none;
        border-radius: 5px;
        margin-right: 0;
        padding-right: 45px;
        width: 100%;
        font-family: 'effra';
        text-transform: capitalize;
        border-color: $pt-gray-04;
        &:focus {
          border-color: $pt-black;
          -webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
          -moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
          box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
        }
        &::-webkit-input-placeholder {
          color: $pt-gray-04;
          text-transform: capitalize;
          font-family: 'effra';
          font-size: 12px;
          font-weight: 400;
          @include media-query(919px) {
            font-size: 10px;
          }
          @media screen and (min-width: 1024px) and (max-width: 1224px) {
            font-size: 10px;
          }
        }
        &::-moz-placeholder {
          color: $pt-gray-04;
          text-transform: capitalize;
          font-family: 'effra';
          font-size: 12px;
          font-weight: 400;
          @include media-query(919px) {
            font-size: 10px;
          }
          @media screen and (min-width: 1024px) and (max-width: 1224px) {
            font-size: 10px;
          }
        }
        &:-ms-input-placeholder {
          color: $pt-gray-04;
          text-transform: capitalize;
          font-family: 'effra';
          font-size: 12px;
          font-weight: 400;
          @include media-query(919px) {
            font-size: 10px;
          }
          @media screen and (min-width: 1024px) and (max-width: 1224px) {
            font-size: 10px;
          }
        }
        &:-moz-placeholder {
          color: $pt-gray-04;
          text-transform: capitalize;
          font-family: 'effra';
          font-size: 12px;
          font-weight: 400;
          @include media-query(919px) {
            font-size: 10px;
          }
          @media screen and (min-width: 1024px) and (max-width: 1224px) {
            font-size: 10px;
          }
        }
      }
      .top-link-block {
        position: absolute;
        right: 10px;
        top: 45%;
        width: 22px;
        height: 22px;
        text-align: center;
        z-index: 10;
        cursor: pointer;
        transform: translateY(-50%);
        font-size: 0;
        &:before {
          top: -3px;
          left: 0;
          width: 22px;
          height: 22px;
          font-size: 20px;
          margin-left: 0;
        }
        .btn-top-search {
          top: 0;
          padding: 0;
          height: 100%;
          width: 100%;
          font-size: 0;
          background: none;
          &:before {
            position: absolute;
            font-family: 'icomoon' !important;
            content: "\e912";
            right: -3px;
            top: 40%;
            width: 22px;
            height: 22px;
            margin-top: -15px;
            font-size: 22px;
            color: $brand-primary;
          }
          &:hover {
            background: none!important;
            box-shadow: none;
          }
        }
      }
    }
  }
  &#layered-navigation {
    .filter-options {
      .filter-options-title {
        margin-bottom: 8px;
        color: $brand-primary;
        font-weight: 700;
        text-transform: uppercase;
      }
      dd {
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #eaeaea;
      }
    }
    select {
      display: block;
      color: #373737;
      padding: 14px 35px 13px 14px;
      width: 100%;
      max-width: 100%;
      margin: 0;
      border: none;
      box-shadow: none;
      border-radius: 5px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #f1f2f4;
      background-image: url('../images/arrow-down-black.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
      background-size: 20px 10px;
    }
    select::-ms-expand {
      display: none;
    }
    select:focus {
      outline: none;
    }
    select option {
      font-weight: normal;
    }
    .chosen-container {
      .chosen-choices {
        border: none;
        color: #373737;
        padding: 10px 38px 9px 10px;
        border-radius: 5px;
        box-shadow: none;
        background-color: #f1f2f4;
        background-image: url('../images/arrow-down-black.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) 18px;
        background-size: 20px 10px;
        li.search-choice {
          display: inline-block;
          margin-top: 0;
          padding: 4px 8px 4px 24px;
          background-color: #0051b2;
          background-image: none;
          color: #fff;
          border: 1px solid #0051b2;
          border-radius: 4px;
          box-shadow: none;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          line-height: 1;
          @include transitions(400ms);
          &:hover {
            background-color: #fff;
            color: $brand-primary;
            & .search-choice-close:before {
              color: $brand-primary;
            }
          }
          .search-choice-close {
            position: absolute;
            top: 45%;
            left: 6px;
            transform: translateY(-50%);
            background: none;
            background-image: none !important;
            color: #fff;
            width: calc(100% - 6px);
            &:before {
              content: "\f057";
              font-family: "FontAwesome";
              font-size: 14px;
              font-weight: 400;
              transition: background-color 400ms linear;
            }
          }
        }
        li.search-field {
          position: relative;
          width: 100%;
          background-color: #fff;
          margin-top: 5px;
          border-radius: 5px;
          padding: 3px 10px;
          border: 1px solid #dcdcdc;
          &::before {
            position: absolute;
            font-family: 'icomoon';
            content: "\e912";
            width: 20px;
            height: 20px;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            font-size: 20px;
            line-height: 20px;
            color: #373737;
          }
        } 
        li.search-field input[type="text"] {
          font-family: $font-family-base;
          color: #373737;
        }
      }
      .chosen-drop {
        background-color: #f1f2f4;
        border: 1px solid #aaa;
        border-radius: 5px;
        width: calc(100% + 20px);
        .chosen-results {
          li.highlighted {
            background-color: $brand-primary;
            background-image: none;
          }
        }
      }
      &.chosen-container-active {
        .chosen-drop {
          left: -10px;
          /*top: 35px;*/
        }
      }
    }
    .am-ranges {
      height: 96px;
      padding: 14px;
      background-color: #f1f2f4;
      border-radius: 5px;
      overflow-x: hidden;
      overflow-y: auto;
      .items {
        padding-left: 0;
        margin-bottom: 0;
        .item {
          a {
            position: relative;
            display: block;
            padding: 2px 0 2px 22px;
            color: #373737;
            &:before {
              content: '';
              position: absolute;
              top: 3px;
              left: 0;
              display: block;
              width: 16px;
              height: 16px;
              background-color: #fff;
              border: 1px solid #dcdcdc;
              border-radius: 3px;
            }
            &.am_shopby_link_selected {
              font-weight: 400;
              &:before {
                background: #fff url("../images/blue-tick.svg") no-repeat center center;
                background-size: 11px 11px;
              }
            }
            .label {
              display: inline-block;
              margin-top: 0;
            }
          }
          input[type="checkbox"] {
            display: none;
          }
        }
      }
    }
  }
}

.search-block {
  margin-top: 30px;
  margin-bottom: 45px;
  .search-block-content {
    @include media-query(767px) {
      .search-tooltip {
        top: 58px;
      }
    }
    .search-tooltip-content {
      @include media-query(767px) {
        left: -284px;
      }
      @include media-query(401px) {
        width: 270px;
        left: -244px;
      }
      @include media-query(331px) {
        width: 250px;
        left: -225px;
      }
    }
    form {
      button {
        @include media-query(1199px, 992px) {
          font-size: 16px;
        }
        span {
          transition: 0.3s;
        }
        &:hover, &:focus {
          background: #fff;
          span {
            color: $brand-secondary;
          }
        }
      }
    }
  }
}

/* Footer menu sections styling */
.menu-footer-sections {
  position: relative;
  width: 100%;
  display: inline-block;
  text-align: center;
  padding-top: 21px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: calc(100% + 40px);
    height: 1px;
    background-color: $pt-gray-04;
  }
   a {
     &.menu-footer-button {
       background: $brand-primary;
       color: white;
       font-size: 14px;
       text-transform: uppercase;
       padding: 7px 16px;
       text-align: center;
       margin: 0 10px;
       display: inline-block;
       border-radius: 4px;
       -moz-border-radius: 4px;
       -webkit-border-radius: 4px;
       &:hover {
        background: $brand-hover;
        outline: 2px solid #fff;
        box-shadow: 0 0 0 4px $brand-hover;
      }
      &:focus {
        background: $brand-hover;
        outline: 2px solid #fff;
        box-shadow: 0 0 0 4px $brand-hover;
      }
       &:active {
         background: $brand-primary;
       }
     }
   }
}

.all-sub-cat {
  display: none;
  .close {
    display: none;
    .icon-remove {
      display: inline-block;
      width: 26px;
      height: 26px;
      background: url('../images/nav-close-icon.svg') no-repeat right center;
      background-size: contain;
    }
  }
  .nav-anchor.has-submenu {
    display: none;
    margin-bottom: 20px;
    padding-top: 0 !important;
    border-bottom: 3px solid $brand-primary;
  }
  .sub-column {
    .category-item:nth-of-type(-n+3) {
      display: none;
    }
  }
}

.subMask {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  z-index: 999999;
}

.page-side-menu {
  padding-left: 0;
  &.no-child {
    border-bottom: 1px solid $pt-gray-04;
  }
  &.child {
    padding-left: 20px;
    li:first-child {
      margin-top: 10px;
    }
  }
  li {
    list-style: none;
    padding: 10px 0;
    border-top: 1px solid $pt-gray-04;
    &.have-children {
      padding-bottom: 0;
    }
    a, .description {
      display: block;
      color: $text-color;
    }
    a {
      @include transition(400ms);
      &.active, &:hover {
        color: $brand-primary;
      }
      &.active {
        font-weight: 700;
      }
    }
    .description {
      font-size: 93%;
      line-height: 1.2;
    }
  }
  @include media-query(767px) {
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .all-sub-cat {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 6px 0px rgba(0,0,0,0.35);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    z-index: 9999999;
    .close {
      display: block;
      opacity: 1;
      &:hover {
        opacity: 0.65;
      }
    }
    .nav-anchor.has-submenu {
      display: block;
    }
    .sub-column {
      column-count: 3;
      column-gap: 15px;
      .category-item:nth-of-type(-n+3) {
        display: block;
      }
    }
  }
}


.filter-actions {
    margin-bottom: 25px;
    padding: 0 10px;
}

.filter-title {
    padding-bottom: 8px;
    margin: 0 0 20px;
    border-bottom: 2px solid $brand-secondary;
    color: $pt-black;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: 'effra';
    font-weight: bold;
}

.filter .block-subtitle {
    display: block;
    margin-bottom: 5px;
    color: $pt-black;
    font-weight: bold;
    text-transform: uppercase;
}

.filter-subtitle {
    display: none !important;
}

.amshopby-filter-current .amshopby-item.item {
    color: $pt-black;
}

.amshopby-item .amshopby-filter-name {
    font-weight: 700;
}

.amshopby-item .amshopby-filter-name:after {
    content: ':';
}

.amshopby-filter-current .amshopby-filter-value {
    margin-left: 2px;
}

.amshopby-filter-current .amshopby-remove:after, .amshopby-filter-current .amshopby-remove:before {
    background-color: #f33d2c !important;
}

.filter-actions a {
    position: relative;
    display: inline-block;
    padding: 7px 8px 7px 24px;
    background-color: $pt-black;
    color: #fff;
    border: 1px solid $pt-black;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    font-family: 'effra';
    transition: background-color 400ms linear;
    &:hover {
      background: $pt-black;
      outline: 2px solid #fff;
      box-shadow: 0 0 0 4px $pt-black;
      color: #fff;
    }
    &:focus {
      background: $pt-black;
      outline: 2px solid #fff;
      box-shadow: 0 0 0 4px $pt-black;
      color: #fff;
    }
}

.filter-actions a:before {
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    content: "\f057";
    font-family: "FontAwesome";
    font-size: 14px;
    font-weight: 400;
    transition: background-color 400ms linear;
}


.amshopby-filter-current .amshopby-item.item {
    flex-wrap: wrap;
}

ol.amshopby-items.items > * + * {
    margin-top: 1rem;
}

.filter-options {
    margin: 10px 0 0 0;
    border-top: 1px solid #eaeaea;
    
}

.filter-options-item {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
    button {
      background: none;
      border: none;
      color: $brand-primary;
      &:hover {
        background: none!important;
        color: $brand-primary;
        text-decoration: underline;
        span {
          color: $brand-primary!important;
        }
      }
    }
    .filter-options-content {
      .am-show-more {
        color: $brand-primary;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
}

.filter-options-title {
    cursor: pointer;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    padding: 10px 40px 0 10px;
    position: relative;
    text-transform: capitalize;
    word-break: break-all;
    z-index: 1;
    display: block;
    text-decoration: none;
    color: $pt-black;
    font-family: 'effra';
    font-size: 16px;
}

.filter-options-title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: inherit;
    color: inherit;
    content: '\f107';
    font-family: 'FontAwesome';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}

.filter-options-title:after {
    position: absolute;
    right: 13px;
    top: 4px;
}
.active > .filter-options-title:after {
    content: '\f106';
}

.filter-options .amshopby-search-box {
    margin: 17px 10px 15px !important;
}

.amshopby-search-box input[type="text"] {
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
}

.filter-options ul {
    max-height: 300px;
    padding: 0 15px;
    overflow: auto;
}

.filter-options ol {
    max-height: 300px;
    padding: 0 15px;
    overflow: auto;
}

.filter-options li {
    display: flex;
    margin: 0 0 8px 0;
    flex-direction: row-reverse;
}

.filter-options li input[type="checkbox"] {
    margin: 4px 8px 0 0;
}

.filter-options-content .items a {
    color: #333333 !important;
    width: 100%;
}

.filter-options-content .items a:hover * {
    color: $brand-primary !important;
}

.filter-options-content .items a span {
margin: 0;
line-height: revert;
display: inline;
}

.filter-options-content .items a span.label {
    white-space: unset;
}

.filter-options-content .items a span.count {
    white-space: nowrap;
}

.filter-count-label {
    padding: 0 3px 0 3px;
}

.filter-options input[type="radio"] {
    display: none;
}

.filter-options .am-labels-folding .label {
    margin-top: 0;
}

.filter-options .am-labels-folding .label:hover {
    color: $pt-gray-09;
}

.filter-options-content .count {
    margin: -2px 3px;
}

.filter-options-content .filter-count-label {
border: 0;
clip: rect(0, 0, 0, 0);
height: 1px;
margin: -1px;
overflow: hidden;
padding: 0;
position: absolute;
width: 1px;
}

.filter-options-content .count:before {
    content: '(';
}

.filter-options-content .count:after {
    content: ')';
}


.amshopby-filter-current .amshopby-remove:before {
transform: rotate(-45deg);
}
.amshopby-filter-current .amshopby-remove:after, .amshopby-filter-current .amshopby-remove:before {
position: absolute;
width: 100%;
height: 1px;
background-color: #757575;
content: '';
}

.amshopby-filter-current .amshopby-remove:after {
transform: rotate(45deg);
}
.amshopby-filter-current .amshopby-remove:after, .amshopby-filter-current .amshopby-remove:before {
position: absolute;
width: 100%;
height: 1px;
background-color: #757575;
content: '';
}

a.amshopby-remove {
display: flex;
justify-content: center;
align-items: center;
position: absolute;
left: 0;
width: 12px;
height: 12px;
}

ol.amshopby-items.items {
padding-left: 10px;
}

.amshopby-filter-current .amshopby-item.item {
flex-wrap: wrap;
display: flex;
align-items: center;
position: relative;
padding: 0 0 0 20px;
}
