// Import custom Bootstrap variables
@import "variables";
// Import media query mixins
@import "functions/mediaqueries";

// Import Bootstrap for Sass
@import "../web/bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../web/bower_components/font-awesome/scss/font-awesome";
@import "../web/bower_components/animate-sass/animate";
@import "../web/bower_components/slick-carousel/slick/slick.css";
@import "slick/slick-theme.scss";

// Import Icomoon for Sass
@import "icomoon/font";
@import "sections/header";
@import "sections/offcanvas-menu";

// Import Lity css
@import "../web/bower_components/lity/dist/lity";

// Amasty Xsearch styling
@import "amasty_xsearch/index";

@import "functions/gradients";
@import "functions/transitions";

@import "global";
@import "forms/select";

@import "sections/breadcrumbs";

@import "homepage";
@import "sizes";
@import "popups";
@import "cart";
@import "modals";
@import "checkout";
@import "ecc-search-address";
@import "categories";
@import "product";
@import "minicart";
@import "account";
@import "cms";
@import "contacts";
@import "blog";
@import "events";
@import "quickorder";
@import "wishlist";

@import "blocks/sidebar/posts";
@import "blocks/sidebar/search";

@import "sections/navigation";
@import "sections/header-links";
@import "sections/header-calltoactions";
@import "sections/mini-basket";
@import "sections/add-basket-modal";
@import "sections/navbar";
@import "sections/footer";
@import "sections/obsolete-products";

@import "pages/brands-page";
@import "pages/shopbycategory";
@import "pages/b2bportal-login";
