.navbar-brand {
  width: auto;
  height: auto;
  padding: 0;
  flex: 1;
  min-width: 300px;
  @include media-query(900px, 769px) {
    width: auto;
  }
  @include media-query(768px) {
    margin-right: 8px;
    margin-left: 15px;
  }
  @media (max-width: 767px) {
    padding-bottom: 0;
    margin: 0 auto;
    width: 220px;
    min-width: 100%;
  }
  img {
    max-width: 100%;
    height: 80px;
    @media (max-width: 767px) {
      height: 70px;
      width: 100%;
    }
    @media screen and (min-width: 1024px) and (max-width: 1404px) {
      z-index: 50;
      position: relative;
    }
  }
  &:hover, &:focus, &:active {
    opacity: 0.7;
  }
}
.old-logo {
  width: auto;
  display: inline-block;
  height: 80px;
  @media (max-width: 767px) {
    display: none;
  }
  .first-choice-logo {
    width: 230px;
    height: 50px;
    margin: 20px 0;
    border-left: 2px solid #EDEEEE;
  }
}


.nav-sections {
  background: $brand-primary;
  margin-bottom: 0;
}
