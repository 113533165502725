.block-blog-search {
  .block-content {
    position: relative;
    margin-top: 10px;

    .input-text {
      padding: 0 50px 0 20px;
      background: #fff;
      border: 1px solid $pt-gray-04;
      border-radius: 5px;
    }

    .button {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border: none;
      background: none;
      text-align: center;
      line-height: 50px;
      color: $brand-primary;
      font-size: 20px;
      cursor: pointer;
      &:before {
        position: absolute;
        font-family: 'icomoon';
        content: "\e912";
        width: 100%;
        height: 100%;
        line-height: 40px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 23px;
        color: $brand-secondary;
      }
      &:hover {
        background: none!important;
        &:before {
          color: $brand-primary;
        }
      }
    }
  }
}
