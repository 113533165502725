.alternative-product {
  &.top-message {
    min-height: 10px;
  }
  span {
    position: relative;
    display: block;
    color: $brand-primary;
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: uppercase;
    a {
      color: $brand-primary;
      text-transform: lowercase;
      text-decoration: underline;
    }
    &:before {
      content: '\f057';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: 'FontAwesome';
      font-size: 22px;
      font-weight: normal;
      line-height: 18px;
      vertical-align: middle;
      display: inline-block;
      margin-top: -3px;
      speak: none;
      width: 20px;
    }
  }
}
