//
//  Shopping cart
//  ---------------------------------------------
.cart-summary, .opc-block-summary {
    .summary-segment-heading {
        text-transform: uppercase;
        color: $pt-black;
        font-size: 16px;
        font-weight: bold;
        text-align: left;
        font-family: 'effra';
    }

    .shipping-method {
        margin-bottom: 6px;
        line-height: 1;
        text-align: left;
    }

    td.amount {
        text-align: right;
    }

    .totals.sub.incl, .totals.shipping.incl, .totals.grand.incl {
        .amount {
            padding-top: 0;
        }
    }

    .totals.shipping .amount:first-child, .totals-tax .amount:first-child, .sub .amount:first-child, .totals.discount .amount:first-child {
        border-top: none !important;
    }

    .totals.discount, .totals-tax {
        .price {
            font-size: 18px;
            font-weight: 700;
        }
    }

    .excl, .incl, .totals-tax, .totals.discount {
        .amount {
            padding: 0;
            padding-right: 30px;
            padding-left: 30px;
        }

        .price {
            &:before {
                font-size: 10px;
                font-weight: 100;
                color: #333;
                position: relative;
                top: 5px;
                left: 5px;
            }
        }

        .data-labels {
            font-size: 10px;
            color: #333;
            font-weight: 100;
        }
    }

    .excl {
        .amount {
            padding-top: 14px;
        }

        .price {
            font-size: 20px;
            font-weight: 600;
            color: #333;
        }
    }

    .incl {
        .amount {
            padding-bottom: 14px;
        }

        .price {
            font-size: 14px;
            font-weight: 100;
            color: #333;
        }
    }

    .totals-tax, .totals.discount {
        .amount {
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }

    .grand.totals {
        background: $pt-gray-01;
        padding-left: 20px;
        padding-right: 20px;
        border-right: 1px solid #f1f2f4;

        .summary-segment-heading {
            margin-bottom: 10px;
            color: #000;
        }

        &.excl .amount {
            position: relative;
            padding-top: 20px;
            /*border-top: 1px solid #eaeaea !important;*/
            &:after {
                content: '';
                position: absolute;
                bottom: -1px;
                left: 30px;
                right: 0;
                width: calc(100% - 60px);
                height: 1px;
                background: #333;
            }

            .price {
                font-size: 30px;
                font-family: 'museo-slab';
                font-weight: bold;
            }
        }

        &.incl .amount {
            padding-top: 5px;
            padding-bottom: 15px;
            border-top: 0;
            border-bottom: 1px solid #eaeaea;

            .price {
                font-size: 16px;
            }
        }
    }

    .table {
        margin-bottom: 0;
    }

    .totals.sub.incl, .totals.discount {
        .amount {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 30px;
                display: block;
                width: calc(100% - 60px);
                height: 1px;
                background-color: #eaeaea;
            }
        }
    }

    .block.items-in-cart {
        padding: 0 30px;
    }
}

//  Summary block
.cart-summary {
    background: white;
    border: 1px solid #eaeaea;
    margin-bottom: $indent-m;
    padding: 10px 0;
    border-radius: 20px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);

    & > .title {
        display: block;
        color: $pt-black;
        font-size: 30px;
        font-weight: bold;
        text-transform: capitalize;
        letter-spacing: -1px;
        margin: 15px 30px 0;
        border-bottom: 1px solid #eaeaea;
        font-family: 'effra';
    }

    .actions-toolbar {
        display: block;
        overflow: hidden;
    }

    .table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
        border-top: none !important;
    }

    #shipping-zip-form legend,
    #shipping-zip-form legend + br {
        display: none;
    }

    .totals .mark {
        background: transparent;
    }

    .sub .mark {
        border-top: none !important;
    }

    #block-discount {
        .content {
            display: none;
        }
    }

    .block {
        margin-bottom: 0;

        .title {
            position: relative;
            right: 3px;
            padding: 15px 18px 15px 0;
            color: $pt-black;
            font-weight: bold;
            text-transform: uppercase;
            font-family: 'effra';

            &::after {
                content: $fa-var-chevron-down;
                @include fa-icon();
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                display: inline-block;
                vertical-align: middle;
                font-size: 12px;
                line-height: 12px;
                color: inherit;
                font-family: 'FontAwesome';
                cursor: pointer;
                font-weight: normal;
                text-align: center;
                overflow: hidden;
                -webkit-font-smoothing: antialiased;
            }

            &:focus {
                outline: 0;
            }
        }

        &.active {
            .title {
                &::after {
                    content: $fa-var-chevron-up;
                }
            }
        }

        &.block.shipping {
            position: relative;
            padding: 0 30px;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 30px;
                display: block;
                width: calc(100% - 60px);
                height: 1px;
                background-color: #eaeaea;
            }

            .content {
                form {
                    .item-title {
                        font-size: 14px;
                        font-family: 'effra';
                        font-weight: 700;
                        color: $pt-black;
                    }
                }
            }
        }

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            .field {
                margin: 0 0 $indent-s;
                float: left;
                width: 100%;

                &.note {
                    font-size: $font-size-base;
                }

                .control {
                    input[type="text"] {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }

        #co-shipping-method-form {
            .field.note {
                padding-bottom: 10px;
            }
        }
    }
}

.cart-container {
    .table-caption {
        display: none;
    }
}

.item-info {
    .col.price {
        display: none;
    }
}

input[type="number"] {
    -moz-appearance: textfield;
}

.cart.table-wrapper .col.msrp {
    white-space: normal
}

.cart.table-wrapper .item .col.item {
    padding-bottom: 0
}

.cart.table-wrapper tbody > tr > td:last-child {
    border: 0
}

.checkout-cart-index .cart.table-wrapper .item .col.qty {
    width: 160px;
}

.cart-summary .field input[type="radio"] {
    display: inline-block;
    vertical-align: middle;
    color: #000;
    margin-top: -2px;
    margin-right: 3px;
}

.cart-summary .label {
    color: #000;
    font-size: 14px;
    font-family: 'museo-slab';
    font-weight: 500;
    vertical-align: middle;
    line-height: normal;
    padding-left: 0;
    margin-bottom: 4px;
}

.cart-summary .block .fieldset .methods .field > .label {
    display: inline;
    font-weight: 400;
    line-height: 17px;
    padding-top: 9px;
    margin: 0;
    color: $pt-gray-09;
    white-space: normal;

    .price-including-tax {
        order: 2;
        display: inline;

        span {
            &.price {
                font-size: 14px;
                line-height: 20px;
                span{
                    &.price {
                        &:after {
                            content: " inc VAT";
                            margin-top: -10px;
                            font-size: 9px;
                        }
                    }
                }
            }
        }
    }

    .price-excluding-tax {
        order: 1;
        @media (min-width: 768px) and (max-width: 991px), (max-width: 580px) {
            margin-bottom: 5px;
        }

        span {
            &.price {
                font-size: 14px;
                line-height: 20px;
                span{
                    &.price {
                        &:after {
                            content: " ex VAT";
                            margin-top: -10px;
                            font-size: 9px;
                        }
                    }
                }
            }
        }
    }
}

.cart-summary {
    padding-bottom: 20px;

    .totals.shipping {
        .shipping-method-title {
            display: block;
            margin-bottom: 5px;
            text-align: left;
            font-size: 85%;
        }

        .price {
            &:before {
                content: attr(data-th);
                float: right;
            }
        }
    }

    border-bottom: 1px solid #eaeaea;
}

.cart-summary {
    position: relative;

    .loading-mask {
        position: absolute;
        border-radius: 20px;

        .loader {
            img {
                width: 35px;
                height: auto;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

//  Totals block
.cart-totals {
    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
}

.product-item-details .item-options dd,
.product-item-details .item-options dt {
    display: inline-block;

}

.product-item-details .item-options dd {
    width: 70%;
}

.product-item-details .item-options dt {
    clear: left;
    width: 25%;
}

.col.rowactions {
    font-size: 20px;
    line-height: 115px;

    .action-delete {
        margin-left: 10px;
        color: #e9e9d9;
    }
}

.col.price .price,
.col.subtotal {
    .subtotal-wrapper {
        display: flex;
        flex-direction: column;

        .price-excluding-tax {
            order: 0;
            -ms-flex-order: 0;
            -webkit-order: 0;
            position: relative;
            width: 100%;
            clear: both;
            display: inline-block;
            line-height: 16px;
            text-align: right;

            &:after {
                content: attr(data-label);
                font-size: 10px;
            }

            .price {
                font-size: 22px;
                color: #333;
                font-weight: 600;
            }
        }

        .price-including-tax {
            order: 1;
            -ms-flex-order: 1;
            -webkit-order: 1;
            position: relative;
            width: 100%;
            clear: both;
            display: inline-block;
            line-height: 16px;
            text-align: right;

            &:after {
                content: attr(data-label);
                font-size: 10px;
            }

            .price {
                font-size: 16px;
                color: #333;
            }
        }
    }

    @include media-query(767px) {
        width: 125px;
    }
}

.item-actions .actions-toolbar {
    .action-delete span, .action-towishlist span {
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        color: #333;
        font-size: 10px;
        font-weight: 600;
        padding-top: 3px;
        padding-left: 25px;

        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            display: block;
            width: 20px;
            height: 20px;
            background-color: $brand-secondary;
            border-radius: 4px;
            font-family: 'icomoon';
            font-size: 11px;
            color: #fff;
            font-weight: 100;
            text-align: center;
            line-height: 21px;
        }

        &:hover, &:active, &:focus {
            &:before {
                background: $brand-secondary;
            }

            color: $brand-secondary;
        }
    }

    .action-delete span {
        &:before {
            content: "\e903";
        }
    }

    .action-towishlist span {
        &:before {
            content: "\e916";
        }
    }
}

//  Products table
.cart.table-wrapper {
    .table {
        > thead > tr > th {
            border-bottom: 1px solid $brand-secondary !important;
        }

        > tbody + tbody {
            border-top: none;
        }
    }

    table {
        margin-bottom: 0;
    }

    thead {
        border-bottom: none !important;

        th {
            color: $brand-primary;
            font-size: 18px;
            text-transform: uppercase;
        }
    }

    .cart.item {
        border-bottom: 1px solid $pt-gray-04;
    }

    .item-actions {
        .qty-mobile {
            display: none;
        }
    }

    .col {
        padding-top: 15px;

        &.qty {
            .label {
                display: none;
            }

            .input-text {
                max-width: 50px;
                text-align: center;
                margin: 0 auto;
            }
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent-m;
            white-space: normal;
        }

        .col {
            padding: $indent-m 8px $indent-s 8px;

            &.item {
                min-height: 75px;
                position: relative;
            }

            &.qty {
                width: 130px;
            }
        }
    }

    .actions-toolbar {
        & > .action {
            margin-right: $indent-s;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action {
        &.help.map {
            font-weight: 500;
        }
    }

    .product {
        &-item-photo, &-item-details {
            display: inline-block;
            vertical-align: top;
        }

        &-item-photo {
            width: 90px;
            margin-right: 15px;
            @media (min-width: 768px) and (max-width: 991px), (max-width: 580px) {
                width: 50px;
            }
        }

        &-item-details {
            white-space: normal;
            width: calc(100% - 110px);
            @media (min-width: 768px) and (max-width: 991px), (max-width: 580px) {
                width: calc(100% - 70px);
            }
        }

        &-item-name {
            display: block;
            font-weight: 600;
            margin-top: -6px;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        font-size: $font-size-small;
        margin-bottom: 0;
    }

    .product-item-name + .item-options {
        margin-top: $indent-s;
    }

    .product-item-name {
        font-size: 16px;
        font-weight: 400;
        font-family: 'museo-slab';

        a {
            color: $brand-primary;

            &:hover, &:active, &:focus {
                text-decoration: underline;
            }
        }
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent-base;
    }

    .table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
        border-top: 0 !important;
    }

    .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
        border-bottom: 0 !important;
    }
}

.cart-container {
    .checkout-methods-items {
        margin-top: $indent-base;
        text-align: center;
        list-style: none;
        padding: 0 30px;
        margin-bottom: 0;

        .action.primary {
            width: 100%;
            font-size: 18px;
        }

        .item {
            & + .item {
                margin-top: $indent-base;
            }
        }
    }
}

.cart-product-attributes {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    color: $pt-gray-09;

    strong {
        color: $pt-black;
        font-weight: bold;
        font-size: 13px;
        font-family: 'museo-slab';
    }
}

.cart-product-hazardous {
    clear: both;
    display: inline-block;
    width: 100%;
    color: #333;
    padding: 10px 0;

    h4 {
        position: relative;
        color: $pt-black;
        font-size: 13px;
        line-height: 1;
        font-family: 'museo-slab';
        font-weight: 900;
        padding-left: 28px;

        img {
            position: absolute;
            left: 0;
            top: -1px;
            width: 20px;
            height: 20px;
        }
    }
}

#block-discount {
    margin-top: 20px;
    display: block;
    width: 100% !important;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    padding: 30px 0;

    .title {
        font-size: 14px;
        color: $pt-black;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: inline-block;
        font-family: 'effra';
    }

    .fieldset.coupon {
        .field {
            .control {
                float: left;
                display: inline-block;
                width: 280px;
                margin-right: 5px;

                input[name="coupon_code"] {
                    background: #ffffff;
                    border-radius: 4px;
                    padding: 5px 10px;
                    color: $pt-black;
                    border: 1px solid $pt-gray-04;
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 15px;

                    &:active {
                        border-color: $pt-black;
                        -webkit-box-shadow: 0px 0px 4px 4px rgba(45, 41, 38, 0.25);
                        -moz-box-shadow: 0px 0px 4px 4px rgba(45, 41, 38, 0.25);
                        box-shadow: 0px 0px 4px 4px rgba(45, 41, 38, 0.25);
                    }

                    &:focus {
                        border-color: $pt-black;
                        -webkit-box-shadow: 0px 0px 4px 4px rgba(45, 41, 38, 0.25);
                        -moz-box-shadow: 0px 0px 4px 4px rgba(45, 41, 38, 0.25);
                        box-shadow: 0px 0px 4px 4px rgba(45, 41, 38, 0.25);
                    }

                    &::-webkit-input-placeholder {
                        color: $pt-gray-04;
                        text-transform: capitalize;
                        font-family: 'effra';
                        font-size: 12px;
                        font-weight: 400;
                        @include media-query(919px) {
                            font-size: 10px;
                        }
                    }

                    &::-moz-placeholder {
                        color: $pt-gray-04;
                        text-transform: capitalize;
                        font-family: 'effra';
                        font-size: 12px;
                        font-weight: 400;
                        @include media-query(919px) {
                            font-size: 10px;
                        }
                    }

                    &:-ms-input-placeholder {
                        color: $pt-gray-04;
                        text-transform: capitalize;
                        font-family: 'effra';
                        font-size: 12px;
                        font-weight: 400;
                        @include media-query(919px) {
                            font-size: 10px;
                        }
                    }

                    &:-moz-placeholder {
                        color: $pt-gray-04;
                        text-transform: capitalize;
                        font-family: 'effra';
                        font-size: 12px;
                        font-weight: 400;
                        @include media-query(919px) {
                            font-size: 10px;
                        }
                    }
                }
            }

            .actions-toolbar {
                float: left;
                display: inline-block;

                button {
                    height: 40px;
                    line-height: 40px;
                    padding-top: 0;
                    padding-bottom: 0;
                    font-size: 14px;
                    background: $brand-secondary;
                    transition: all 0.15s ease-out;

                    &:hover, &:focus, &:active {
                        background: $brand-secondary !important;
                        color: white;
                        outline: 2px solid #fff;
                        box-shadow: 0 0 0 4px $brand-secondary;
                    }
                }
            }
        }
    }

    @include media-query(768px) {
        .fieldset.coupon .field .control {
            width: calc(100% - 129px);
        }
    }
}

.cart.main.actions {
    width: 100%;
    display: inline-block;
    padding-top: 20px;

    a.continue, #empty_cart_button {
        display: none;
    }

    button {
        &[name="update_cart_action"] {
            float: right;
            background: $brand-secondary !important;
            color: white;
            font-family: inherit;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 8px;
            padding-bottom: 8px;
            border-radius: 5px;
            border: none;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            transition: all 0.15s ease-out;

            &:hover, &:focus, &:active {
                background: $brand-secondary !important;
                color: white;
                outline: 2px solid #fff;
                box-shadow: 0 0 0 4px $brand-secondary;
            }
        }
    }
}

.payment-logos-containter {
    clear: both;
    float: left;
    display: block;
    width: 100%;
    text-align: left;
    margin-bottom: 30px;

    h3 {
        color: $brand-primary;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: -1px;
    }
}

.field.qty {
    border: 1px solid $pt-gray-04;
    background: #fff;
    width: 110px;
    position: relative;
    margin: 0 auto;
    display: inline-block;
    border-radius: 4px;
    padding: 5px;
    box-sizing: border-box;
    z-index: 9;

    + .mage-error {
        max-width: 240px;
    }

    @include media-query(991px, 768px) {
        width: 100px;
    }

    .control {
        display: flex;
        justify-content: center;
    }

    input {
        background: #fff;
        text-align: center;
        border: none;
        width: 50px;
        height: 20px;
        margin-left: 24px;
        box-shadow: none;
        pointer-events: none;
        margin-top: 5px;
        margin-bottom: 5px;
        @include media-query(1199px, 992px) {
            width: 70%;
        }
    }

    button {
        margin: 0;
        padding: 0;
        width: 20px;
        height: 20px;
        background: $brand-primary;
        font-family: inherit;
        display: inline-block;
        line-height: 20px;
        border: none;

        &.decrement {
            position: absolute;
            left: 10px;
            top: 50%;
            margin-top: -10px;
            color: white;
            font-size: 20px;
            background: $brand-secondary;
            transition: 0.15s linear;

            &:hover {
                background: $pt-gray-09;
            }
        }

        &.increment {
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -10px;
            color: white;
            font-size: 16px;
            background: $brand-secondary;
            transition: 0.15s linear;

            &:hover {
                background: $pt-gray-09;
            }
        }

        &:hover {
            outline: none;
            background: #143690;
            color: white;
        }

        &:active, &:focus {
            outline: none;
        }
    }

    @include media-query(1199px, 992px) {
        width: 100%;
    }
}

.checkout-cart-index {
    .breadcrumbs-container {
        .container {
            @include media-query(991px, 768px) {
                width: 100%;
            }
        }
    }

    #maincontent {
        margin-top: 70px;
        margin-bottom: 70px;
        padding-right: 0;
        padding-left: 0;
        @include media-query(991px, 768px) {
            width: 100%;
        }
        @include media-query(767px) {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    .table-wrapper {
        margin: 0;
    }

    #shopping-cart-table {
        thead {
            tr {
                th {
                    font-family: 'museo-slab';
                    font-weight: bold;

                    &:first-child {
                        font-family: 'museo-slab';
                        font-weight: bold;
                    }
                }
            }
        }

        tr {
            th, td {
                &:first-child {
                    padding-left: 0;
                    font-family: 'effra';
                    text-transform: capitalize;
                    color: $pt-black;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .col.subtotal {
            text-align: right !important;
        }

        .product-image-photo {
            border: 1px solid #eaeaea;
        }

        td.col.subtotal {
            font-family: 'museo-slab';

            .price-container {
                display: flex;
                flex-direction: column;

                .price-including-tax, .price-excluding-tax {
                    position: relative;
                    left: -46px;

                    span {
                        &.price {
                            color: #333333;
                            font-weight: 900;

                            &:after {
                                position: absolute;
                                top: 50%;
                                left: 100%;
                                width: 50px;
                                font-size: 11px;
                                font-weight: 500;
                                text-align: left;
                                padding-left: 5px;
                            }
                        }
                    }

                    //@media (min-width: 768px) and (max-width: 1199px), (max-width: 580px) {
                    @include media-query(1199px) {
                        left: inherit;
                        span {
                            &.price:after {
                                position: static;
                                display: block;
                                width: 100%;
                                line-height: 1;
                                text-align: right;
                                margin-top: 0 !important;
                                padding-left: 0;
                            }
                        }
                    }
                }

                .price-including-tax {
                    order: 2;
                    display: block;

                    span {
                        &.price {
                            font-size: 14px;
                            line-height: 20px;

                            &:after {
                                content: "inc VAT";
                                margin-top: -10px;
                            }
                        }
                    }
                }

                .price-excluding-tax {
                    order: 1;
                    @media (min-width: 768px) and (max-width: 991px), (max-width: 580px) {
                        margin-bottom: 5px;
                    }

                    span {
                        &.price {
                            font-size: 19px;
                            line-height: 13px;

                            &:after {
                                content: "ex VAT";
                                margin-top: -8px;
                            }
                        }
                    }
                }
            }
        }
    }

    .secure-payment {
        margin-top: 35px;

        h3 {
            color: $pt-black;
            font-size: 22px;
            font-weight: 700;
            text-transform: uppercase;
            font-family: 'effra';
        }

        .payments-icons {
            font-size: 0;
            margin-left: -4px;
            margin-right: -4px;

            img {
                display: inline-block;
                vertical-align: middle;
                width: 60px;
                height: auto;
                margin: 0 4px;
            }

            @include media-query(460px) {
                img {
                    width: calc(16.6666667% - 8px);
                }
            }
        }
    }

    .bottom-checkout {
        display: none;
        border-top: 1px solid #eaeaea;
        margin-top: 5%;
        padding: 30px 34px 0;
        text-align: right;

        button {
            width: 100%;
            max-width: 297px;
            font-size: 18px;
            text-align: center;
        }

        @include media-query(767px) {
            display: block;
            text-align: center;
            button {
                max-width: 100%;
            }
        }
    }
}

.checkout-cart-index .stock-availability-container {
    clear: both;
    width: 100%;
    display: block;
    padding: 20px 0;

    .product-availability {
        display: inline-block;
        float: left;
        padding-right: 5px;
        padding-left: 26px;
        text-transform: uppercase;
        font-size: 12px;

        &.in-stock {
            color: #4bb441;

            &:before {
                font-family: 'FontAwesome';
                position: absolute;
                left: 0;
                top: -6px;
                color: #4bb441;
                content: "\f058";
                font-size: 22px;
                font-weight: 600;
            }
        }

        &.out-of-stock {
            color: #4bb441;
            padding-left: 30px;

            &:before {
                font-family: 'icomoon';
                position: absolute;
                left: 0;
                top: -1px;
                color: #333333;
                content: "\e914";
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .product-stock-count {
        display: inline;
        font-size: 12px;
        font-family: inherit;
        font-weight: 400;
        color: #333;
        text-transform: none;
    }
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    & + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.msrp {
        text-align: center;
    }
}

//
//  Mobile
//  _____________________________________________

@media (max-width: 768px) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            thead {
                .col {
                    &:not(.item) {
                        span {
                            display: none;
                        }
                    }
                }
            }

            .col {
                &.msrp {
                    white-space: normal;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@media (min-width: 768px) {
    .cart-summary {
        position: relative;
        margin-top: 48px;

        .actions-toolbar {
            .column.main & {
                & > .secondary {
                    float: none;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;

                tr {
                    .col {
                        font-family: 'effra';
                        text-transform: capitalize;
                        color: $pt-black;
                    }
                }
            }

            .item-actions {
                .actions-toolbar {
                    text-align: right;
                }
            }
        }
    }
}

@include media-query(580px) {
    .cart.table-wrapper {
        table {
            table-layout: fixed;
        }

        .col.item {
            width: 65%;
        }

        .col.qty {
            display: none;
        }

        th.col.subtotal {
            display: table-cell;

            > span {
                display: none;
            }
        }

        .col.subtotal {
            width: auto;
            width: 35%;
        }

        .actions-toolbar-outer {
            text-align: right;

            .actions-toolbar {
                display: inline-block;
            }
        }

        .item-actions {
            td {
                padding-top: 20px;
                vertical-align: middle;
            }

            .qty-mobile {
                display: table-cell;

                .mage-error[generated] {
                    display: none;
                }
            }
        }
    }

    .checkout-cart-index .stock-availability-container {
        position: relative;

        .product-availability {
            position: absolute;
            width: 240px;
        }
    }
}

@include media-query(400px) {
    .cart.table-wrapper {
        .actions-toolbar-outer {
            .actions-toolbar {
                text-align: right;

                > .action {
                    display: inline-block;
                    margin-right: 0;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

@include media-query(360px) {
    .cart.table-wrapper .product-item {
        &-photo, &-details {
            width: 100%;
        }

        &-photo {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
}
