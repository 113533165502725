body {
	overflow-x: hidden;
	font-style: normal;
	color: $text-color!important;
}
input, button, select, textarea {
	font-family: 'effra';
}
button {
	transition: all 0.15s ease-out;
	border: none;
	background: transparent;
	width: 100%;
	&[type='button'] {
		background: $brand-primary;
		padding: 14px 12px;
		text-align: center;
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 600;
		border-radius: 4px;
		span {
			color: white;
			position: relative;
			width: 100%;
			display: block;
		}
	}
	&:hover {
		background: $brand-hover!important;
		color: white;
		outline: 2px solid #fff;
		box-shadow: 0 0 0 4px $brand-hover;
		span {
			color: #fff!important;
		}
	}
	&:active {
		box-shadow: none;

		outline: 0;
		text-decoration: none;
	}
	&:focus {
		box-shadow: none;
		outline: 0;
		text-decoration: none;
		background: $brand-primary;
	}
}
h2 {
	font-family: 'effra';
	font-weight: bold;
}
a {
	color: $brand-primary;
	&:hover {
		text-decoration: underline;
	}
	&:focus {
		outline-offset: 0px;
		text-decoration: underline;
	} 
}
.cms-noroute-index {
	a {
		color: $brand-primary;
	}
}
.magestore-slick-slider {
	display: none;
}
.magestore-slick-slider.slick-initialized {
	display: block;
}

// https://github.com/kenwheeler/slick/issues/826
// https://github.com/kenwheeler/slick/pull/3112

.slick-slider {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slick-slider .slick-slide {
	display: none;
    outline: 0;
}
.slick-slider .slick-slide:first-child {
	display: block;
}
.slick-slider.slick-initialized .slick-slide {
	display: block;
}

/* 404 */
.cms-no-route {
    .calltoaction-container {
      border-bottom: 2px solid #eaeaea;
    }
    #maincontent {
        margin-bottom: 45px;
        padding-right: 0;
        padding-left: 0;
        @include media-query(767px) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    .page-title-wrapper {
        &.page-title-wrapper-mobile {
            display: none;
        }
    }
}

#maincontent {
	margin-top: 55px;
	@include media-query(767px) {
		margin-top: 35px;
	}
	.page-title-wrapper {
		h1.page-title {
			margin: 0 0 10px;
			padding: 0 0 10px;
			text-transform: capitalize;
			color: $pt-black;
			font-weight: bold;
			letter-spacing: -1px;
			font-size: 36px;
			border-bottom: 4px solid $brand-secondary;
			font-family: 'effra';
			@include media-query(767px) {
				font-size: 34px;
                padding-bottom: 3px;
			}
            @include media-query(539px) {
                font-size: 28px;
            }
		}
	}
}

.clearfix {
    display: block;
    content: "";
    clear: both;
}

.alert-error {
    background-color: $alert-danger-bg;
    border-color: $alert-danger-border;
    color: $alert-danger-text;
}
.messages {
	.alert-success {
		span {
			a {
				color: $brand-primary;
			}
		}
	}
}
.label {
    color: $gray-dark;
    font-size: 14px;
    padding: 0;
    text-align: left;
    margin-bottom: 5px;
    display: block;
    margin-top: 10px;
    font-weight: normal;
}
.form-check-label {
    color: $gray-dark;
    font-size: 14px;
    padding: 0;
    text-align: left;
    font-weight: normal;
}

.field.required {
    .form-check-label {
        &:after {
            content: "*";
            vertical-align: top;
            color: #f33d2c;
            margin-left: 3px;
        }
    }
}

.control select,
.input-text{
	@extend .form-control;
}

label.amgdprcookie-label {
	margin-bottom: 0;
	max-width: inherit;
}

div.amgdprcookie-cookie-container .amgdprcookie-options {
	padding: 7px 38px 7px 15px;
	border-bottom-left-radius: 0;
	border-top-right-radius: 6px;
}

div.amgdprcookie-main-wrapper .amgdprcookie-btn {
	background-color: $brand-primary;
	@include transitions(400ms);
	&:hover, &:focus {
		background-color: $brand-secondary;
	}
}

img{
	max-width:100%;
}
.account,
.catalogsearch-result-index,
.catalog-product-view {
	.page-title-wrapper {
		h1.page-title {
			font-size: 34px;
			margin-bottom: 30px;
		}
	}
}
.calltoaction-container {
	background: white;
}
.page-header {
	background: white;
	margin-top:0;
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: 0;
	@media (max-width: 767px) {
		position: relative;
	}
	.container {
		padding: 0 15px 0 0;
			@media (min-width: 1024px) {
				display: flex;
				justify-content: space-between;
			}
		@media (max-width: 767px) {
			padding: 0;
		}
		@media screen and (min-width: 768px) and (max-width: 1023px) {
			position: relative;
			padding-right: 0;
		}
		&:before {
			content: '';
			background: url('..//images/ptuk-header-flash.png');
			height: 80px;
			position: absolute;
			left: 0;
			padding: 3px 70px;
			background-repeat: no-repeat;
			background-size: 127px;
			@media (max-width: 1023px) {
				content: none;
			}
			@media screen and (min-width: 1024px) and (max-width: 1079px) {
				left: -30px;
			}
			@media screen and (min-width: 1080px) and (max-width: 1199px) {
				left: -20px;
			}
			@media screen and (min-width: 1200px) and (max-width: 1299px) {
				left: -40px;
			}
		}
	}
	&:after {
		@media (max-width: 767px) {
			content: '';
			background: rgb(45,41,38);
			background: linear-gradient(90deg, rgba(45,41,38,1) 0%, rgba(194,196,196,1) 10%, rgba(224,225,225,1) 40%, rgba(45,41,38,1) 80%, rgba(224,225,225,1) 90%, rgba(83,86,90,1) 99%);
			display: block;
			height: 5px;
		}
	}
}
.page-wrapper {
	@include media-query(768px, 280px) {
		width: 100%;
	}
}
.page-wrapper {
	.breadcrumbs-container {
		.breadcrumb{
			background-color: transparent;
			margin-bottom:0;
			li {
				font-size: 14px;
				a {
					font-size: 14px;
					&:hover {
						text-decoration: underline;
					}
				}
			}
			
		}
	}
}

.top-links-wrapper {
    position: relative;
	padding-right: 0 !important;
	padding-left: 0 !important;
    width: auto;
	float: right;
    @include media-query(900px) {
        width: 345px;
    }
	@media screen and (min-width: 768px) and (max-width: 1023px) {
		position: unset;
	}
	@media (min-width: 1024px) {
		width: 110px;
	  }
}
.page-after-main  {
	clear:both;
	background: white;
}
#btn-minicart-close{
	&:before {
		-webkit-font-smoothing: antialiased;
		font-size: 16px;
		line-height: 16px;
		color: #8f8f8f;
		content: '\e9f9';
		font-family: 'icomoon';
		margin: 0;
		vertical-align: top;
		display: inline-block;
		font-weight: normal;
		overflow: hidden;
		speak: none;
		text-align: center;
	}
}
.showcart:hover,
.showcart:focus,
.showcart:active,
.showcart{
	text-decoration:none;
}
.message.info {
  margin: 0 0 10px;
  padding: 12px 20px 12px 25px;
  display: block;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 45px;
  position: relative;
  clear: both;
}
.message.info a {
  color: #1979c3;
}
.message.info a:hover {
  color: #006bb4;
}
.message.info a:active {
  color: #006bb4;
}
.message.info > *:first-child:before {
  -webkit-font-smoothing: antialiased;
  font-size: 24px;
  line-height: 24px;
  color: #c07600;
  content: '\f071';
  font-family: 'FontAwesome';
  margin: -12px 0 0;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  left: 0;
  top: 22px;
  width: 45px;
  position: absolute;
  text-align: center;
}
.message.error {
  margin: 0 0 10px;
  padding: 12px 20px 12px 25px;
  display: block;
  font-size: 1.3rem;
  background: #fae5e5;
  color: #e02b27;
  padding-left: 45px;
  position: relative;
}
.message.error a {
  color: #1979c3;
}
.message.error a:hover {
  color: #006bb4;
}
.message.error a:active {
  color: #006bb4;
}
.message.error > *:first-child:before {
  -webkit-font-smoothing: antialiased;
  font-size: 24px;
  line-height: 24px;
  color: #b30000;
  content: '\f071';
  font-family: 'FontAwesome';
  margin: -12px 0 0;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  left: 0;
  top: 22px;
  width: 45px;
  position: absolute;
  text-align: center;
}
.message.warning {
  margin: 0 0 10px;
  padding: 12px 20px 12px 25px;
  display: block;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 45px;
  position: relative;
}
.fieldset.estimate {
	.message.warning {
		float: left;
	}
}
.message.warning a {
  color: #1979c3;
}
.message.warning a:hover {
  color: #006bb4;
}
.message.warning a:active {
  color: #006bb4;
}
.message.warning > *:first-child:before {
  -webkit-font-smoothing: antialiased;
  font-size: 24px;
  line-height: 24px;
  color: #c07600;
  content: '\f071';
  font-family: 'FontAwesome';
  margin: -12px 0 0;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  left: 0;
  top: 22px;
  width: 45px;
  position: absolute;
  text-align: center;
}
.message.notice {
  margin: 0 0 10px;
  padding: 12px 20px 12px 25px;
  display: block;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 45px;
  position: relative;
}
.message.notice a {
  color: #1979c3;
}
.message.notice a:hover {
  color: #006bb4;
}
.message.notice a:active {
  color: #006bb4;
}
.message.notice > *:first-child:before {
  -webkit-font-smoothing: antialiased;
  font-size: 24px;
  line-height: 24px;
  color: #c07600;
  content: '\f0f3';
  font-family: 'FontAwesome';
  margin: -12px 0 0;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  left: 0;
  top: 22px;
  width: 45px;
  position: absolute;
  text-align: center;
}
.message.success {
  margin: 0 0 10px;
  padding: 12px 20px 12px 25px;
  display: block;
  font-size: 1.3rem;
  background: #e5efe5;
  color: #006400;
  padding-left: 45px;
  position: relative;
}
.message.success a {
  color: #1979c3;
}
.message.success a:hover {
  color: #006bb4;
}
.message.success a:active {
  color: #006bb4;
}
.message.success > *:first-child:before {
  -webkit-font-smoothing: antialiased;
  font-size: 24px;
  line-height: 24px;
  color: #006400;
  content: '\f058';
  font-family: 'FontAwesome';
  margin: -12px 0 0;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  speak: none;
  left: 0;
  top: 22px;
  width: 45px;
  position: absolute;
  text-align: center;
}

.top-links-wrapper .additional-header-links a {
    margin-right: 6px;
    font-size: 15px;
	&:hover{
		text-decoration: none;
	}
}

.top-links-wrapper .material-icons {
    font-size: 28px;
    vertical-align: middle;
}
.additional-header-links {
	ul.header-top-links {
    list-style: none;
    padding: 0;
    border: navajowhite;
    margin: 0;
		li {
			display: inline-block;
			position: relative;
			.counter {
			position: absolute;
			display: block;
			width: 20px;
			left: 0;
			right: 0;
			margin: auto;
			text-align: center;
			top: 47%;
			background-color: #ffff;
			height: 20px;
			line-height: 20px;
			border-radius: 100%;
			font-size: 11px;
			font-weight: bold;
			}
			
		}
		li.wishlist-icon,
		li.compare {
			.counter {
				line-height: 21px;
				top: 21px;
				right: 5px;
			}
		}
	}
}
.additonal-showas-icons {
	.material-icons {
		font-size: 50px;
		vertical-align: top;
		line-height: 63px;
	}
}
.social-media-outter {
	font-size: 0;
	margin-bottom: 0;
	display: flex;
	a.social-link {
		.fa-tiktok {
			&:before {
				content: "";
				background: url('../images/tiktok-white.svg') no-repeat;
				display: inline-block;
				height: 16px;
				width: 15px;
				border: none;
				border-radius: 0;
			}
		}
	}
}
.share-list {
	display: flex;
}

.social-media {
	width: 44px;
	height: 44px;
	margin-right: 10px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	background: $brand-secondary;
	border-radius: 30px;
	font: normal normal normal 14px/1 FontAwesome;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 23px;

	&:last-child {
		margin-right: 0;
	}
	&:hover, &:focus {
		background: $pt-gray-09;
	}
	&.twitter {
		&:before {
			content: "\f099";
		}
	}
	&.linkedin {
		&:before {
			content: "\f0e1";
		}
	}
	&.youtube {
		&:before {
			content: "\f099";
		}
	}
	&.facebook {
		&:before {
			content: "\f09a";
		}
	}
	&.instagram {
		&:before {
			content: "\f099";
		}
	}
    &.email {
		&:before {
			content: "\f0e0";
		}
    }
}

.showas-icons-text{
	i.fa {
		font-size: 23px;
		vertical-align: middle;
		margin-right: 5px;
	}
}
.showas-icons{
	.fa {
		font-size: 40px;
		vertical-align: middle;
		padding: 10px;
		border-radius: 100%;
		display: table;
		float: right;
	}
	.showcart {
		position: relative;
		width: auto;
		display: inline-block;
		.text{
			width: auto;
			display: table;
		}
	}
	.counter {
		width: auto;
		display: table;
		height: auto;
		.counter-number {
			position: absolute;
			display: block;
			width: 20px;
			left: 0;
			right: 0;
			margin: auto;
			text-align: center;
			top: 47%;
			background-color: #ffff;
			height: 20px;
			line-height: 20px;
			border-radius: 100%;
			font-size: 11px;
			font-weight: bold;
		}
	}
	#minicart-content-wrapper {
		position: absolute;
		right: 0;
		left: 0;
		margin-right: 15px;
		margin-left: 15px;
	}
}
#search_mini_form {
    position: relative;
	vertical-align: middle;
	margin-top: 20px;
    width: 100%;
	display: flex;
	@include media-query(768px) {
		margin-top: 0;
	}
	@media screen and (min-width: 768px) and (max-width: 1023px) {
		margin-top: 10px;
	}
	input[type="text"] {
		float: left;
		height: 48px;
		line-height: 40px;
		background: #ffffff;
		border: 1px solid $pt-gray-04;
		-moz-border-radius: 0;
		text-shadow: none;
		margin-bottom: 10px;
		color: $pt-black;
		border-radius: 5px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		font-family: 'effra';
		font-size: 16px;
		&:focus {
			border-color: $pt-black;
			-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
			-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
			box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
		}
		&::-webkit-input-placeholder {
			color: $pt-gray-04;
			text-transform: capitalize;
			font-family: 'effra';
			font-weight: 400;
			font-size: 16px;
			@include media-query(919px) {
				font-size: 10px;
			}
		}
		&::-moz-placeholder {
			color: $pt-gray-04;
			text-transform: capitalize;
			font-family: 'effra';
			font-size: 12px;
			font-weight: 400;
			@include media-query(919px) {
				font-size: 10px;
			}
		}
		&:-ms-input-placeholder {
			color: $pt-gray-04;
			text-transform: capitalize;
			font-family: 'effra';
			font-size: 12px;
			font-weight: 400;
			@include media-query(919px) {
				font-size: 10px;
			}
		}
		&:-moz-placeholder {
			color: $pt-gray-04;
			text-transform: capitalize;
			font-family: 'effra';
			font-size: 12px;
			font-weight: 400;
			@include media-query(919px) {
				font-size: 10px;
			}
		}
	}
	.btn-top-search {
		background-color: $brand-primary;
		padding-right: 6px;
		padding-left: 6px;
		display: inline-block;
		color: #fff;
		font-family: 'effra';
		text-transform: uppercase;
		font-size: 15px;
		margin-right: 0;
		font-weight: bold;
		border: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		width: 100%;
		height: 48px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		transition: all 0.15s ease-out;
		cursor: pointer;
		&:focus {
			background: $brand-hover!important;
			color: white;
			outline: 2px solid #fff;
			box-shadow: 0 0 0 4px $brand-hover;
		}
		&:hover {
			background: $brand-hover!important;
			color: white;
			outline: 2px solid #fff;
			box-shadow: 0 0 0 4px $brand-hover;
		}
		
		&:active {
			box-shadow: none;
			outline: none;
			border: none;
		}
		&:hover {
			background: $brand-hover;
			&:before {
				color: $brand-primary;
			}
		}
		@include media-query(768px) {
			top: -1px;
		}
	}
	.form-group {
		margin-bottom: 0;
		flex: 2;
	}
}

.top-headerbar {
	font-size: 13px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 20px;
	.toplinks-left{
		li{
			&:first-child{
				padding-left:0;
			}
		}
	}
	ul{
	padding: 0;
	margin: 0;
	list-style: none;
		li {
			display: inline-block;
			list-style: none;
			padding: 0 15px;
		}
	}
}

.calltoaction-container {
	position: relative;
	z-index: 1;
	text-transform: uppercase;
	font-size: 13px;
	padding: 0px;
	text-align: center;
	overflow-x: hidden;
	background: #fff;
	max-width: $container-large-desktop;
	margin: 0 auto;
	display: flex;
	@media (min-width: 768px) {
        display: none;
    }
	&:before {
		content: '';
		background: $pt-gray-01;
	}
	.additional-header-links {
		ul.header-top-links {
			display: flex;
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			@media (max-width: 767px) {
				justify-content: center;
			}
			&:before {
				content: '';
				background: $pt-gray-01;
				width: 100vw;
				position: absolute;
				left: 0;
				top: 0;
			}
			li {
				margin-left: 0;
				@media (max-width: 767px) {
					display: inline-block;
					width: auto;
					flex-grow: 1;
					a {
						display: inline-block;
						padding-top: 38px;
						font-size: 10px;
						text-transform: uppercase;
						&:before {
							position: absolute;
							top: 6px;
							color: #000;
						}
					}
					&.header-phone {
						a {
							&:before {
								content: "\e97c";
								margin-left: -16px;
								font-weight: bold;
							}
						}
					}
				}
			}
		}
	}
}

.pagination {
	> li.item {
		display: inline-block;
		margin-right: 4px;
		@include media-query(768px) {
			margin-right: 2px;
		}
		&:last-child {
			margin-right: 0;
		}
		> a, > span {
			border-radius: 6px;
			font-weight: 500;
			font-family: 'effra';
			text-transform: uppercase;
		}
		.link.next, .link.previous {
			color: $pagination-active-color;
			background-color: $pagination-active-bg;
			border-color: $brand-primary;
		}
		.link.next {
			@include media-query(870px) {
				span {
					display: none;
				}
				&:after {
					content: attr(data-mobile-text);
				}
			}
		}
		.link.previous {
			&:hover {
				background: $brand-primary;
				outline: 2px solid #fff;
				box-shadow: 0 0 0 4px $brand-primary;
			}
			&:focus {
				background: $brand-primary;
				outline: 2px solid #fff;
				box-shadow: 0 0 0 4px $brand-primary;
			}
			@include media-query(870px) {
				span {
					display: none;
				}
				&:before {
					content: attr(data-mobile-text);
				}
			}
		}
		
		&.hellip {
			min-height: 46px;
			> a {
				display: inline-block;
				padding: 10px 3px;
				background-color: transparent;
				color: $brand-primary;
				font-size: 28px;
				font-weight: 500;
				line-height: 18px;
				height: 100%;
				border: none!important;
                @include media-query(767px) {
                    font-size: 22px;
                    line-height: 8px;
                }
			}
		}
	}
	> li:first-child > a, > li:first-child > span,
	> li:last-child > a, > li:last-child > span,
	.pagination-lg > li > a, .pagination-lg > li > span {
		border-radius: 6px;
	}
    @include media-query(460px) {
        .pages-item-previous, .pages-item-next {
            display: none;
        }
    }
}
.pagination-lg > li > a, .pagination-lg > li > span {
	padding: 10px 15px;
	@include media-query(768px) {
		padding: 6px 13px;
	}
	@include media-query(520px) {
		font-size: 16px;
		padding: 3px 8px;
	}
}

div.fotorama--fullscreen {
	background: rgba(0,0,0,1);
}

div.fotorama__wrap:not(.fotorama__wrap--toggle-arrows) {
	.fotorama__fullscreen-icon,
	.fotorama__zoom-out,
	.fotorama__zoom-in {
		background-color: transparent;
	}
	.fotorama__fullscreen-icon {
		width: 60px;
		height: 60px;
		margin: 10px;
		transform: scale(0.85);
		background: url('../images/yoma-icon/close-zoom.svg') no-repeat center center;
		background-size: 30px 30px;
		outline: 0;
		opacity: 0.7;
		transition: opacity 0.5s ease-in-out, scale 0.5s ease-in-out;
		border-radius: 50%;
		&:focus:after {
			box-shadow: none;
		}
		&:hover {
			opacity: 1;
			transform: scale(1);
			border: 1px solid #ffffff;
			background-color: transparent;
		}
	}
}
div.fotorama__zoom-in, div.fotorama__zoom-out {
	display: none;
}

.navbar{
	border:0 !important;
}

input[type="text"] {
	float: left;
	height: 40px;
	line-height: 40px;
	background: #ffffff;
	border: 1px solid $pt-gray-04;
	-moz-border-radius: 0;
	text-shadow: none;
	margin-bottom: 10px;
	color: $pt-black;
	border-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	font-family: 'effra';
	transition: all 0.15s ease-out;
	&:focus {
		border-color: $pt-black;
		-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
		-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
		box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
	}
	&::-webkit-input-placeholder {
		color: $pt-gray-04;
		text-transform: capitalize;
		font-family: 'effra';
		font-size: 12px;
		font-weight: 400;
		@include media-query(919px) {
			font-size: 10px;
		}
	}
	&::-moz-placeholder {
		color: $pt-gray-04;
		text-transform: capitalize;
		font-family: 'effra';
		font-size: 12px;
		font-weight: 400;
		@include media-query(919px) {
			font-size: 10px;
		}
	}
	&:-ms-input-placeholder {
		color: $pt-gray-04;
		text-transform: capitalize;
		font-family: 'effra';
		font-size: 12px;
		font-weight: 400;
		@include media-query(919px) {
			font-size: 10px;
		}
	}
	&:-moz-placeholder {
		color: $pt-gray-04;
		text-transform: capitalize;
		font-family: 'effra';
		font-size: 12px;
		font-weight: 400;
		@include media-query(919px) {
			font-size: 10px;
		}
	}
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

.navbar-mainnavigation {
    background: #fff none repeat scroll 0 0;
    margin-top: 51px;
}
.iomegamenu .navbar-nav>li>.dropdown-menu {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.iomegamenu .navbar-nav > li:hover > .dropdown-menu {
	display:block;
}

.iomegamenu .navbar-megamenu .navbar-nav > li > a {
    font-size: 14px;
    font-weight: 700;
    padding: 14px 35px;
    text-transform: uppercase;
    color: #667380;
}
.iomegamenu .navbar-megamenu .navbar-nav > li{
	border-top: 1px solid #fff;
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
	margin-bottom: -2px;
	display: inline-table;
	float: none;
	position: unset;
}
.iomegamenu .navbar-megamenu .navbar-nav > li.dropdown:hover {
	border-top-color: rgba(0, 0, 0, 0.15);
	border-left-color: rgba(0, 0, 0, 0.15);
	border-right-color: rgba(0, 0, 0, 0.15);
}

.iomegamenu .navbar-megamenu .navbar-nav > li.dropdown:hover > a{
	border-bottom: 1px solid #fff;
	padding-left: 20px;
	z-index: 2000;
	background: #fff;
	position: relative;
}

.iomegamenu .navbar-megamenu .navbar-nav > li > a .glyphicon {
    line-height: normal;
    margin-left: 10px;
}



.iomegamenu .mega-dropdown {
    position: static !important;
}
.iomegamenu .nav > li > a:hover,
.iomegamenu .nav > li > a:focus,
.iomegamenu .nav .open > a:hover,
.iomegamenu .nav .open > a:focus,
.iomegamenu .nav .open > a{
    background-color: transparent;
    border-color: transparent;
}

.iomegamenu .mega-dropdown-menu {
    padding: 20px 0;
    width: auto;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.iomegamenu .navbar-nav > li > .dropdown-menu li{

}
.iomegamenu .mega-dropdown-menu > li > ul > li {

    display: block;
    float: none;
    text-align: left;
    font-size: 14px;
    margin: 0;
    line-height: normal;

}
.menu-static-top {
    margin-bottom: 10px;
}
.menu-static-bottom {
    margin-top: 10px;
}
.menu-static-right {
    margin-left: 10px;
}
.menu-static-left {
    margin-right: 10px;
}

@media (min-width: 768px) {
	.navbar-collapse.collapse {
		display: table;
		margin: 0 auto !important;
		text-align: center;
	}
	.iomegamenu .navbar-megamenu > .navbar-collapse {

	}
    .iomegamenu .navbar-megamenu .navbar-nav > li:first-of-type > a {
        padding-left: 20px;
    }
    .level1 > ul > li > a {
        padding: 5px 0;
        font-weight: normal !important;
        text-transform: none !important;
    }
	.level2 > a{
		padding: 0 !important;
	}
    .menuheading {
        padding-left: 15px;
        padding-right: 15px;
        text-align: left;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
        color: #333333;
    }
    .megamenu-viewall{
        text-align: center;
    }
	.navbar-megamenu{
		margin-bottom:0;
	}
}

@media (max-width: 678px) {
    .iomegamenu .mega-dropdown{
        width:100%;
    }
}

.iomegamenu .mega-dropdown-menu > li > ul {
    padding: 15px;
    margin: 0;
}

.iomegamenu .mega-dropdown-menu > li > ul > li {
    list-style: none;
}

.iomegamenu .mega-dropdown-menu > li > ul > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #999;
    white-space: normal;
}

.iomegamenu .mega-dropdown-menu > li ul > li > a:hover,
.iomegamenu .mega-dropdown-menu > li ul > li > a:focus {
    text-decoration: none;
    color: #444;
    background-color: #f5f5f5;
}

.iomegamenu .mega-dropdown-menu .dropdown-header {
    color: #428bca;
    font-size: 18px;
    font-weight: bold;
}

.iomegamenu .mega-dropdown-menu form {
    margin: 3px 20px;
}

.iomegamenu .mega-dropdown-menu .form-group {
    margin-bottom: 3px;
}

.password-strength-meter {
  background-color: #f4f4f4;
  height: 32px;
  line-height: 32px;
  padding: 0 9px;
  position: relative;
  z-index: 1;
}
.password-strength-meter:before {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}
.password-strength-meter-0 .password-strength-meter:before {
  background-color: #f4f4f4;
  width: 100%;
}
.password-strength-meter-1 .password-strength-meter:before {
  background-color: #ffafae;
  width: 25%;
}
.password-strength-meter-2 .password-strength-meter:before {
  background-color: #ffd6b3;
  width: 50%;
}
.password-strength-meter-3 .password-strength-meter:before {
  background-color: #c5eeac;
  width: 75%;
}
.password-strength-meter-4 .password-strength-meter:before {
  background-color: #81b562;
  width: 100%;
}
.captcha-reload {
	background: transparent;
	border: transparent;
	span{
		display:none;
	}
}
#captcha-container-user_forgotpassword {
    margin-bottom: 30px;
    margin-top: 20px;
}
.captcha-reload::before {
	font: normal normal normal 14px/1 FontAwesome;
	content: "\f021";
	font-size: 26px;
	vertical-align: bottom;
	display: inline-block;
	&:hover{
		@extend .fa-spin;
	}
}
.required, ._required {
    > .label > span::after {
        content: "*";
        vertical-align: top;
        color: $brand-secondary;
        margin-left: 3px;
    }
}
input.mage-error,
select.mage-error,
textarea.mage-error {
  border-color: #ed8380;
}
div.mage-error[generated] {
  color: $brand-primary;
  font-size: 1.2rem;
  margin-top: 3px;
  clear: both;
}
.page.messages {
    display: block;
    clear: both;
    margin: 0 15px;
}

button, select, input, textarea {
	&:hover, &:focus, &:active {
		box-shadow: none;
		outline: 0;
		text-decoration: none;
	}
}

button.primary,
.button-primary {
	transition: all 0.15s ease-out;
	background: $brand-primary;
	width: auto;
	&:hover {
		background: $brand-hover;
		outline: 2px solid #fff;
		box-shadow: 0 0 0 4px $brand-hover;
	}
	&:focus {
		background: $brand-hover;
		outline: 2px solid #fff;
		box-shadow: 0 0 0 4px $brand-hover;
	}
}

button.primary {
	&:hover {
		background: $brand-hover;
		background-color: $brand-hover;
	}

}

button.secondary,
.button-secondary {
    font-weight: 600;
	@include transitions(400ms);
	background: $brand-secondary;
}

input:focus, select:focus, textarea:focus, button:focus {
	outline: none;
}

a {
	&:hover {
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		outline: 0;
		text-decoration: none;
	}
	&:active {
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		outline: 0;
		text-decoration: none;
	}
	&:focus {
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		outline: 0;
		text-decoration: none;
	}
}

.scrollToTop {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	padding: 36px 10px 10px 10px;
	text-align: center;
	background: $brand-secondary;
	font-weight: bold;
	color: white;
	font-size: 12px;
	text-transform: uppercase;
	line-height: 12px;
	text-decoration: none;
	position: fixed;
	top: 75px;
	right: 40px;
	display: none;
	z-index: 999;
	&:before {
		position: absolute;
		left: 50%;
		top: 14px;
		margin-left: -8px;
		font-size: 34px;
		content: "\e96f";
		font-family: 'icomoon';
		transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
	}
	&:hover {
		text-decoration:none;
		color: white;
	}
	&:active {
		text-decoration:none;
		color: white;
	}
	&:focus {
		text-decoration:none;
		color: white;
	}
	@include media-query(768px) {
		top: inherit !important;
		bottom: 105px;
		right: 25px;
		width: 60px;
		height: 60px;
		color: $brand-secondary;
		font-size: 0;
		&:before {
			top: 18px;
			right: 29px;
			margin-left: 0;
			color: white !important;
			font-size: 22px;
		}
		&:hover {
			text-decoration:none;
			color: $brand-secondary;
		}
		&:active {
			text-decoration:none;
			color: $brand-secondary;
		}
		&:focus {
			text-decoration:none;
			color: $brand-secondary;
		}
	}
}

.call-action-block {
	@include media-query(1199px) {
		.text {
			font-size: 10px;
		}
		font-size: 10px;
	}
}

html.lity-active body {
	overflow: hidden;
}
.lity-container {
	width: calc(100% - 240px);
}

.lity-image img {
	margin: 0 auto;
}

.lity-close {
	width: 60px;
	height: 60px;
	margin: 10px;
	font-size: 0;
	transform: scale(0.85);
	background: url('../images/yoma-icon/close-zoom.svg') no-repeat center center;
	background-size: 30px 30px;
	outline: 0;
	opacity: 0.7;
	transition: opacity 0.5s ease-in-out, scale 0.5s ease-in-out;
	border-radius: 50%;
	&:focus:after {
		box-shadow: none;
	}
	&:hover {
		background: url('../images/yoma-icon/close-zoom.svg') no-repeat center center;
		background-size: 30px 30px;
		font-size: 0;
		opacity: 1;
		transform: scale(1);
		border: 1px solid #ffffff;
		background-color: transparent;
	}
}
.search-block-outter {
	position: relative;
}
.search-block {
	background: $brand-primary;
	.search-block-content {
		padding: 10px 20px 20px;
		box-sizing: border-box;
		.search-tooltip {
			display: block;
			position: absolute;
			right: 20px;
			top: 30px;
			background: #ffffff;
			width: 25px;
			height: 25px;
			text-align: center;
			padding-top: 0;
			border-radius: 4px;
			cursor: pointer;
			z-index: 11;
			&:before {
				content: "\f128";
				font-family: "FontAwesome";
				font-size: 18px;
				color: $brand-primary;
			}
		}
		.search-tooltip-content {
			display: none;
			position: absolute;
			left: 20%;
			bottom: 25px;
			width: 310px;
			height: auto;
			overflow-y: auto;
			background: white;
			border: 1px solid $pt-gray-04;
			border-radius: 5px;
			padding: 10px;
			box-sizing: border-box;
			text-align: center;
			p {
				margin: 0;
				padding: 0;
				font-size: 12px;
				font-family: inherit;
			}
			&:after {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 0;
				height: 0;
				border: 0 solid white;
				border-left-width: 49px;
				border-right-width: 49px;
				border-top: 85px solid #3052b4;
			}
			&.shown {
				transition: opacity 2s ease-in;
				opacity: 1;
				display: block;
			}
		}

		h3 {
			width: calc(100% - 35px);
			position: relative;
			display: block;
			color: #ffffff;
			font-size: 20px;
			font-weight: bold;
			z-index: 10;
			font-family: 'effra';
		}

		form {
			width: 100%;
			margin: 0;
			padding-top: 15px;

			input[type='text'] {
				box-shadow: none;
				border-radius: 5px;
				margin-right: 0;
				padding-right: 45px;
				width: 100%;
				float: left;
				height: 40px;
				line-height: 40px;
				background: #ffffff;
				border: 1px solid $pt-gray-04;
				-moz-border-radius: 0;
				text-shadow: none;
				margin-bottom: 10px;
				color: $pt-black;
				&:focus {
					border-color: $pt-black;
					-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
					-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
					box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				}

			}
            select {
              display: block;
              color: #373737;
			  margin-bottom: 10px;
              padding: 10px 35px 10px 10px;
              width: 100%;
              max-width: 100%;
			  border: 1px solid $pt-gray-04;
              box-shadow: none;
              border-radius: 5px;
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              background-color: #ffffff;
              background-image: url('../images/arrow-down-blue.svg');
              background-repeat: no-repeat;
              background-position: calc(100% - 10px) center;
              background-size: 20px 10px;
			  &:focus {
				border-color: $pt-black;
				-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
			  }
            }
            select::-ms-expand {
              display: none;
            }
            select:focus {
              outline: none;
            }
            select option {
              font-weight: normal;
            }

			

			button {
				border: none;
				background: transparent;
				width: 100%;
				&[type='button'] {
					transition: all 0.15s ease-out;
					background: $brand-primary;
					padding: 10px 12px;
					text-align: celeftnter;
					font-size: 18px;
					text-transform: uppercase;
					font-weight: 600;
					border-radius: 4px;
					span {
						font-family: 'effra';
						font-weight: bold;
						&:before {
							position: absolute;
							font-family: 'icomoon' !important;
							content: "\e912";
							right: -3px;
							top: 50%;
							width: 22px;
							height: 22px;
							margin-top: -15px;
							font-size: 22px;
						}
					}
				}
				transition: 0.3s;
				&:hover {
					background: $brand-hover!important;
					color: white;
					outline: 2px solid #fff;
					box-shadow: 0 0 0 4px $brand-hover;
					span {
						color: #fff!important;
					}
				}
				&:active {
					box-shadow: none;

					outline: 0;
					text-decoration: none;
				}
				&:focus {
					background: $brand-hover!important;
					color: white;
					outline: 2px solid #fff;
					box-shadow: 0 0 0 4px $brand-hover;
					span {
						color: #fff!important;
					}
				}
			}
		}
	}
}

.marketing-block {
	margin-bottom: 45px;
	img {
		display: block;
		width: 100%;
		max-width: 320px;
		margin: 0 auto;
		height: auto;
	}
}

.loading-mask {
	position: fixed;
	top: 0;
	left: 0;
	display: table;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,0.9);
	z-index: 999;
	.loader {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		img {
			display: block;
			width: 218px;
			height: 149px;
			margin: 0 auto;
		}
		p {
			margin-top: -40px;
		}
	}
}
.responsive-container {
	margin-right: auto;
	margin-left: auto;
	padding-right: 15px;
	padding-left: 15px;
	@media (min-width: $screen-sm-min) {
		width: $container-sm - 30px;
	}
	@media (min-width: $screen-md-min) {
		width: $container-md - 30px;
	}
	@media (min-width: $screen-lg-min) {
		width: $container-lg - 30px;
	}
}

.product-items {
	.slick-arrow {
		&.slick-prev, &.slick-next {
			width: 34px;
			height: 65px;
			@include media-query(992px, 541px) {
				width: 20px;
				height: 35px;
				top: inherit;
				bottom: -90px;
			}
			@include media-query(540px) {
				width: 20px;
				height: 35px;
			}
			&:before {
				position: absolute;
				font-family: 'icomoon';
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 100%;
				height: 100%;
				font-size: 65px;
				color: $brand-primary;
				@include media-query(992px, 541px) {
					font-size: 35px;
				}
				@include media-query(540px) {
					font-size: 35px;
				}
			}
		}
		&.slick-prev {
			left: -70px;
			@include media-query(1300px) {
				left: 15px;
			}
			@include media-query(992px, 541px) {
				left: calc(50% - 38px);
			}
			&:before {
				content: "\e96f";
			}
		}
		&.slick-next {
			right: -70px;
			@include media-query(1300px) {
				right: 15px;
			}
			@include media-query(992px, 541px) {
				right: calc(50% - 38px);
			}
			&:before {
				content: "\e970";
			}
		}
	}
}

@media (min-width: 798px) {
	.responsive-container {
		padding-right: 0;
		padding-left: 0;
	}
}

@include media-query(539px) {
	.col-xxs {
		float: none;
		width: 100%;
	}
	.col-xxs-4 {
		width: 33.33333%;
	}
	.col-xxs-8 {
		width: 66.66667%;
	}
}

.message {
    &.global {
        &.cookie {
            padding: 12px 20px 12px 25px;
            display: block;
            font-size: 1.3rem;
            background: #0051b2;
            border-color: white;
            color: white;
            margin: 0;
            bottom: 0;
            left: 0;
            position: fixed;
            right: 0;
            z-index: 99999999;
            &.actions {
                margin-top: 10px;
            }
            a {
                color: white;
            }
        }
    }
}

.stock-availability-container .product-availability {
    &.out-of-stock, & > span.product-stock-count {
        &.orange-alert {
            color: $pt-orange;
        }
    }
}

.stock-availability-container{
    .product-availability {
        position: relative;
        display: inline-block;
        padding-right: 5px;
        font-size: 13px;
        font-weight: 700;
        line-height: inherit!important;
        .product-stock-count {
            display: flex!important;
            gap: 0.5rem;
            align-items: center;
            font-family: inherit;
        }
    }
    .orange-alert{
        line-height: 22px;
        text-transform: uppercase!important;
    }
    .search-tooltip {
        cursor: pointer;
        z-index: 11;
        &:before {
            content: "\f29c";
            font-family: "FontAwesome";
            font-size: 14px;
            color: $brand-secondary;
            padding: 1.5px;
        }
    }
}
