.shopbycategory-index-index {
  #maincontent {
    padding-right: 0;
    padding-left: 0;
  }
}

.shop-by-contents {
  width: 100%;
  display: inline-block;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 3px solid #e9e9e9;
}

ul.shop-by-categories {
  margin: 0;
  padding: 0;
  list-style-type: none;
  .categories-holder {
    display: flex;
    flex-wrap: wrap;
  }
  .item {
   margin-bottom: 30px;
   .category-inner {
     border: 1px solid $pt-gray-04;
     padding: 0 15px 20px;
     box-sizing: border-box;
     & > * {
      width: 100%;
      flex-basis: 100%;
     }

     .category-image {
      margin: 0 -15px 10px;
      border-bottom: 1px solid $pt-gray-04;
      width: auto;
      flex-basis: auto;
      &.category-image-placeholder {
        padding: 0;
      }
      img {
        width: 100%;
      }
     }

     a.category-name {
       display: table;
       color: $pt-black;
       border-bottom: 2px solid $pt-black;
       margin-bottom: 15px;
       font-size: 18px;
       width: 100%;
       text-transform: uppercase;
       font-weight: bold;
       padding: 0 0 6px 0;
       font-family: 'effra';
       span {
        display: table-cell;
        vertical-align: bottom;
       }
     }

     ul {
       position: relative;
       width: calc(100% + 30px);
       margin: 0 -15px 15px;
       padding: 0;
       list-style-type: none;
       li {
         padding: 10px 0;
         margin: 0 15px;
         border-bottom: 1px solid $pt-gray-04;
         a {
          color: $pt-gray-09;
          @include transitions(400ms);
          &:hover {
            text-decoration: underline;
            color: $brand-primary;
          }
         }
         &:last-child {
           border-bottom: none;
         }
       }

     }

   }

   .hidden-category-items {
    display: none;
     &.reveal {
      display: block;
      position: absolute;
      left: -1px;
      width: calc(100% + 2px);
      padding-bottom: 10px;
      background: #fff;
      border: 1px solid $pt-gray-04;
      border-top: none;
      z-index: 999;
      @include media-query(767px) {
        position: static;
      }
     }
   }
   
   button[name="reveal-items"] {
     border: none;
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     background: transparent;
     color: $brand-primary;
     font-weight: 400;
     padding: 0;
     position: relative;
     margin: 10px 15px 0 15px;
     text-transform: capitalize;
     text-align: left;
     font-size: 14px;
     font-family: 'effra';
     &:before {
       position: absolute;
       right: -20px;
       top: 50%;
       transform: translateY(-50%);
       font-size: 12px;
       color: $brand-primary;
       font-family: 'FontAwesome';
       content: "";
     }
     &:hover {
       background: none!important;
       color: $brand-hover;
       text-decoration: underline;
     }
     &.hide-cat {
       &:before {
         content: "\f077";
       }
     }
   }

   .inner-shop-now {
    align-self: flex-end;
    margin-top: 15px;
    a {
     padding: 10px;
     display: inline-block;
     background: $brand-secondary;
     text-decoration: none;
     color: #fff;
     height: 32px;
     text-align: center;
     line-height: 16px;
     text-transform: uppercase;
     font-size: 14px;
     line-height: 14px;
     font-weight: 600;
     border-radius: 5px;
     font-family: 'effra';
     transition: all 0.15s ease-out;
      &:focus {
        background: $pt-black!important;
        color: white;
        outline: 2px solid #fff;
        box-shadow: 0 0 0 4px $pt-black;
      }
      &:hover {
        background: $pt-black!important;
        color: white;
        outline: 2px solid #fff;
        box-shadow: 0 0 0 4px $pt-black;
      }
      
      &:active {
        box-shadow: none;
        outline: none;
        border: none;
      }
      &:hover {
        background: $brand-hover;
        &:before {
          color: $brand-primary;
        }
      }
    }
   }
  }
}