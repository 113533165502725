.block-minicart .items-total,
.block-minicart .subtotal {
    display: inline-block;
    vertical-align: top;
    margin-top: 15px;
    width: calc(50% - 10px);
    margin-right: -4px;

    /* fix spacing display inline-block */
    span {
        font-size: 14px;
    }
}

.block-minicart .items-total {
    margin-left: 10px;
    text-align: left;
}

.block-minicart .items-total .count {
    font-weight: 700;
}

.block-minicart .subtotal {
    margin-right: 10px;
    text-align: right;
}

.block-minicart .amount .price-wrapper {
    &:first-child .price {
        font-size: 18px;
        font-weight: 700;
    }
}

.block-minicart .subtitle {
    display: none;
}

.block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: 30px 0 20px;
    text-align: center;
}

.block-minicart .text.empty {
    text-align: center;
}

.block-minicart .block-content>.actions {
    margin-top: 12px;
}

.block-minicart .block-content>.actions>.secondary {
    text-align: center;

    a {
        font-size: 14px;
        color: #333333;
        text-decoration: underline;
        cursor: pointer;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

.block-minicart .block-content>.actions>.primary {
    margin: 0 10px 15px;
}

.block-minicart .block-content>.actions>.primary .action.primary {
    display: block;
    width: 100%;
    font-size: 16px;
    text-align: center;
}

.block-minicart .block-content>.actions .paypal-logo {
    margin-top: 15px;
    text-align: center;
}

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
    margin: 15px 0 0;
    text-align: center;
}

.minicart-wrapper {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;

    @include media-query(767px) {
        right: -2px;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        right: 16px
    }
}

.minicart-wrapper:before,
.minicart-wrapper:after {
    content: '';
    display: table;
}

.minicart-wrapper:after {
    clear: both;
}

.minicart-wrapper .action.showcart {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    float: right;
}

.minicart-wrapper .action.showcart.active {
    display: inline-block;
    text-decoration: none;
}

.minicart-wrapper .block-minicart {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}

.minicart-wrapper .block-minicart li {
    margin: 0;
}

.minicart-wrapper .block-minicart li:hover {
    cursor: pointer;
}

.minicart-wrapper .block-minicart:before,
.minicart-wrapper .block-minicart:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}

.minicart-wrapper .block-minicart:before {
    border: 6px solid;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99;
}

.minicart-wrapper .block-minicart:after {
    border: 7px solid;
    border-color: transparent transparent #bbbbbb transparent;
    z-index: 98;
}

.minicart-wrapper .block-minicart:before {
    left: 10px;
    top: -12px;
}

.minicart-wrapper .block-minicart:after {
    left: 9px;
    top: -14px;
}

.minicart-wrapper.active .block-minicart {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    border-radius: 0 0 10px 10px;
}

.minicart-wrapper .block-minicart {
    padding: 25px 20px 12px;
    right: 0;
    width: 100vw;
    max-width: 320px;
}

.minicart-wrapper .block-minicart .block-title {
    display: none;
}

.minicart-wrapper .block-minicart:after {
    left: auto;
    right: 25px;
}

.minicart-wrapper .block-minicart:before {
    left: auto;
    right: 26px;
}

.minicart-wrapper .product .actions {
    position: absolute;
    right: 0;
    margin: -28px 0 0;
}

.minicart-wrapper .product .actions>.primary,
.minicart-wrapper .product .actions>.secondary {
    display: inline;
}

.minicart-wrapper .product .actions>.primary:not(:last-child),
.minicart-wrapper .product .actions>.secondary:not(:last-child) {
    margin-right: 4px;
}

.minicart-wrapper #btn-minicart-close.action.close {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-shadow: none;
    font-weight: 400;
    display: inline-block;
    text-decoration: none;
    opacity: 1;
}

.minicart-wrapper #btn-minicart-close.action.close:focus,
.minicart-wrapper #btn-minicart-close.action.close:active,
.minicart-wrapper #btn-minicart-close.action.close:hover {
    background: none;
    border: none;
    opacity: 0.5;
}

.minicart-wrapper #btn-minicart-close.action.close.disabled,
.minicart-wrapper #btn-minicart-close.action.close[disabled],
fieldset[disabled] .minicart-wrapper #btn-minicart-close.action.close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

.minicart-wrapper #btn-minicart-close.action.close>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.minicart-wrapper #btn-minicart-close.action.close:before {
    content: '';
    position: absolute;
    top: 12px;
    right: 10px;
    display: block;
    width: 12px;
    height: 12px;
    background: url(../images/close-icon-minicart.svg) no-repeat center center;
    background-size: contain;
    overflow: hidden;
    speak: none;
    text-align: center;
}

.minicart-wrapper #btn-minicart-close.action.close:hover:before {
    color: inherit;
}

.minicart-wrapper #btn-minicart-close.action.close:active:before {
    color: inherit;
}

.minicart-wrapper .action.showcart {
    white-space: nowrap;
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        height: 80px;
    }
}

.minicart-wrapper .action.showcart .counter.qty .loader>img {
    margin-top: -13px;
    margin-left: -2px;
    max-width: 24px;
    height: auto;
}

.minicart-items-wrapper {
    border: 1px solid #cccccc;
    margin: 0 -20px;
    border-left: 0;
    border-right: 0;
    overflow-x: auto;
    padding: 15px 30px;
}

.minicart-items {
    margin: 0;
    padding: 0;
    list-style: none none;
}

.minicart-items .product-item {
    padding: 20px 0;
}

.minicart-items .product-item:not(:first-child) {
    border-top: 1px solid #cccccc;
}

.minicart-items .product-item:first-child {
    padding-top: 0;
}

.minicart-items .product-item-pricing .label {
    display: inline-block;
    width: 4.5rem;
    color: #000;
    text-align: left;
    font-weight: normal;
    font-size: 12px;
    padding: 0;
    line-height: 23px;
}

.minicart-items .price-minicart {
    margin-bottom: 5px;
}

.minicart-items .product-item-name {
    font-weight: 400;
    margin: 0 0 10px;
}

.minicart-items .product-item-name a {
    color: #333333;
}

.minicart-items .product-item-details {
    position: relative;
    padding-left: 63px;
    padding-right: 80px;
    text-align: left;
    line-height: normal;
}

.minicart-items .cart-product-attributes {
    margin-top: 10px;
    font-size: 13px;

    .cart-item-info {
        margin-bottom: 3px;

        strong {
            font-size: 13px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.minicart-items .price-container {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 80px;
    text-align: right;
}

.minicart-items {
    .field.qty {
        position: relative;
        width: 96px;

        input {
            width: 35px;
        }

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="number"] {
            -moz-appearance: textfield;
        }

        &:after {
            opacity: 0;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 0;
            height: 100%;
            background: rgba(255, 255, 255, 0.75) url('../images/loader.gif') no-repeat center center;
            background-size: 22px 22px;
        }

        &.qty-loading {
            cursor: not-allowed;

            &:after {
                width: 100%;
                opacity: 1;
                pointer-events: none;
            }

            & > * {
                pointer-events: none;
            }
        }
    }
}

.minicart-items .product-item-details {
    .price {
        font-size: 12px;
        font-weight: 700;
    }

    .price-including-tax,
    .price-excluding-tax {
        display: inline-block;
        line-height: 1;
        margin-bottom: 5px;
    }

    .price-excluding-tax {
        .price {
            font-size: 19px;
        }
    }
}

.minicart-items .price-wrapper {

    &.price-including-tax,
    &.price-excluding-tax {
        position: relative;

        &:after {
            content: attr(data-label);
            display: block;
            font-size: 11px;
        }
    }

    .price-excluding-tax {
        font-size: 17px;
    }

    .price-including-tax {
        font-size: 14px;
    }
}

.minicart-items .product-item-details .weee[data-label] {
    font-size: 1.1rem;
}

.minicart-items .product-item-details .details-qty {
    margin-top: 10px;
}

.minicart-items .product>.product-item-photo,
.minicart-items .product>.product-image-container {
    width: 54px;
    text-align: center;
    float: left;
    border: 1px solid #ddd;
}

.minicart-items .product .toggle {
    border: 0;
    padding: 0 40px 5px 0;
}

.minicart-items .product .toggle:after {
    color: #8f8f8f;
    margin: 0 0 0 5px;
    position: static;
}

.minicart-items .product .active>.toggle:after {
    content: '\e621';
}

.minicart-items .product.pricing {
    margin-top: 3px;
}

.minicart-items .product.options .toggle.tooltip {
    display: inline-block;
    text-decoration: none;
}

.minicart-items .product.options .toggle.tooltip>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.minicart-items .product.options .toggle.tooltip:after {
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    line-height: 12px;
    color: inherit;
    content: '\e622';
    font-family: 'luma-icons';
    margin: -3px 0 0 7px;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}

.minicart-items .product.options .details {
    display: none;
}

.minicart-items .item-qty {
    margin-right: 10px;
    text-align: center;
    width: 40px;
}

.minicart-items .update-cart-item {
    font-size: 1.1rem;
    vertical-align: top;
}

.minicart-items .subtitle {
    display: none;
}

.minicart-items .action.edit,
.minicart-items .action.delete {
    display: inline-block;
    text-decoration: none;
}

.minicart-items .action.edit>span,
.minicart-items .action.delete>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.minicart-items .action.edit,
.minicart-items .action.delete {
    width: 26px;
    height: 26px;
    transition: opacity 0.15s ease-in-out;
    
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        speak: none;
        text-align: center;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }

    &:hover,
    &:focus {
        opacity: 0.5;
    }
}

.minicart-items .action.edit:before {
    background-image: url(../images/edit-icon.svg);
}

.minicart-items .action.delete:before {
    background-image: url(../images/delete-icon.svg);
}

.minicart-items .action.edit:hover:before,
.minicart-items .action.delete:hover:before {
    color: #333333;
}

.minicart-items .action.edit:active:before,
.minicart-items .action.delete:active:before {
    color: #8f8f8f;
}