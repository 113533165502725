
.minicart-wrapper {
  .block-minicart {
    margin-top: 0 !important;
    border-top: 6px solid $brand-secondary;
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }

    .subtotal {
      color: #333333;
      .label {
        text-align: right;
        margin-bottom: 0;
        margin-top: 0;
        color: #333333;
        span {
          color: #333333;
        }
      }
      .price-container {
        margin-top: 12px;
        line-height: 11px;
        .price-including-tax {
          position: relative;
          display: block;
          left: -46px;
          span {
            &.price {
              color: #333333;
              font-size: 12px;
              font-weight: bold;
              line-height: 20px;
              &:after {
                position: absolute;
                top: 50%;
                left: 100%;
                content: "inc VAT";
                width: 55px;
                margin-top: -10px;
                font-size: 12px;
                font-weight: 500;
                text-align: left;
                padding-left: 5px;
              }
            }
          }
        }
        .price-excluding-tax {
          position: relative;
          left: -46px;
          span {
            &.price {
              font-weight: bold;
              color: #333333;
              font-size: 19px;
              line-height: 13px;
              &:after {
                position: absolute;
                top: 50%;
                left: 100%;
                content: "ex VAT";
                width: 50px;
                margin-top: -8px;
                font-size: 12px;
                font-weight: 500;
                text-align: left;
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
  @include media-query(768px, 711px) {
    width: 89px;
    .action.showcart {
      width: 100%;
      text-align: center;
    }
  }
  @include media-query(767px) {
    position: absolute;
  }
  @media (max-width: 767px) {
    top: -71px
  }
}
