.cms-quick-order {
    #maincontent {
        padding: 0;
    }
}

.table.bss-fastorder-multiple-form {
    margin-bottom: 60px;
    > thead, > tbody, > tfoot {
        > tr > th,  > tr > td {
            padding: 18px 16px;
            vertical-align: middle;
        }
        > tr > th {
            padding: 6px 16px;
            font-size: 16px;
        }
    }
    > thead > tr > th {
        border-bottom-color: $brand-primary;
    }
    > tbody {
        .button {
            font-weight: 700;
            transition: all 0.3s ease-in-out;
            &:hover, &:focus {
                color: $brand-primary;
                background-color: #dee6ee;
            }
        }
    }
    > tfoot {
        > tr > td {
            background-color: #f1f2f4;
            border-top: 0;
            padding: 26px 16px;
            &:last-child {
                padding-right: 26px;
                text-align: right;
                .tocart {
                    margin-left: 16px;
                }
            }
        }
        > tr .td-example {
            display: none;
        }
        > tr .td-add {
            button, .button {
                margin-left: 18px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        button, .button {
            display: inline-block !important;
            padding: 10px 15px;
            font-weight: 700;
            text-align: center;
            border-radius: 5px; 
            text-transform: uppercase;
            color: #fff;
        }
    }
    th {
        text-transform: uppercase;
    }
    tr {
        > th, > td {
            &:first-child {
                padding-left: 0;
            }
        }
        > th {
            color: $brand-primary;
        }
    }
    td {
        button {
            background-color: $brand-primary;
            color: #fff;
            border: none;
            border-radius: 4px;
            text-transform: uppercase;
            font-size: 14px;
        }
    }
    .input-text {
        height: 40px;
        border-radius: 5px;
    }
    .bss-fastorder-img {
        img {
            border: 1px solid #eaeaea;
            display: block;
            width: 80px;
            max-width: 100%;
            height: auto;
        }
    }
    .bss-fastorder-row-name {
        .product.name {
            color: #333;
            font-size: 13px;
        }
        ul {
            padding: 0;
            li {
                list-style: none;
            }
        }
        .product-item-attributes {
            font-size: 12px;
            line-height: 1.2;
            > div:first-child {
                margin-top: 10px;
            }
            strong {
                display: block;
                color: $brand-primary;
            }
        }
    }
    .bss-fastorder-row-qty {
        width: 70px;
        text-align: center;
        input {
            text-align: center;
            -webkit-appearance: none;
            &[readonly] {
                opacity: 0.75;
                cursor: not-allowed;
            }
        }
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            margin: 0;
            -webkit-appearance: none;
        }
    }
    .bss-fastorder-row-price {
        p {
            margin-bottom: 0;
            strong {
                font-size: 18px;
            }
        }
    }
    td.bss-fastorder-row-price {
        font-size: 12px;
    }
    .bss-fastorder-row-edit {
        width: 110px;
        text-align: center;
        .button {
           font-size: 12px;
           line-height: 20px; 
        }
    }
    .bss-fastorder-row-action {
        width: 40px;
        text-align: right;
        padding-right: 0;
        .btn-ok {
            width: 24px;
            height: 24px;
            line-height: 28px;
            vertical-align: middle;
            padding: 0;
            background: url('../images/close-icon-blue.svg') no-repeat center center;
            background-size: contain;
            transition: opacity 0.2s linear;
            &:hover, &:focus {
                opacity: 0.75;
            }
        }
    }
    .bss-fastorder-hidden {
        display: none;
    }
    .bss-file-upload {
        display: inline-block;
        vertical-align: middle;
        padding: 0 18px 0 26px;
        #uploadCSV {
            display: none;
        }
        .button {
            display: inline-block;
            background-color: $brand-secondary;
            cursor: pointer;
            &:hover, &:focus {
                background-color: $brand-primary;
            }
        }
        .customInput {
            margin-bottom: 0;
            height: 39px;
        }
        .customInputName {
            display: block;
            margin-top: 5px;
            font-weight: 400;
            text-align: center;
            font-size: 12px;
        }
    }
    .bss-sample-csv {
        color: #333333;
        text-decoration: underline;
        &:hover, &:focus {
            text-decoration: none;
        }
    }
    .bss-addline {
        &:hover, &:focus {
            color: $brand-primary;
            background-color: #dee6ee;
        }
    }
    .bss-height-tr {
        position: relative;
        .bss-fastorder-autocomplete:not([style="display: none;"]) {
            position: absolute;
            top: 40px;
            left: 0;
            display: block;
            width: 100%;
            min-width: 200px;
            max-width: 320px;
            max-height: 320px;
            background-color: #fff;
            border-width: 0 1px 1px 1px;
            border-style: solid;
            border-color: #eaeaea;
            z-index: 99;
            overflow-y: auto;
            ul {
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    padding: 10px;
                    &:nth-child(even) {
                        background-color: #f9f9f9;
                    }
                    &:hover, &:focus {
                        background-color: #f1f2f4;
                    }
                    .bss-product-info {
                        font-size: 0;
                        .bss-product-image, .bss-product-text {
                            display: inline-block;
                            vertical-align: top;
                            font-size: 14px;
                        }
                        .bss-product-image {
                            width: 50px;
                            margin-right: 10px;
                            border: 1px solid #eaeaea;
                        }
                        .bss-product-text {
                            color: #333;
                            font-size: 12px;
                            width: calc(100% - 60px);
                        }
                        .bss-product-name, .bss-product-sku {
                            margin-bottom: 3px;
                        }
                        .bss-product-price {
                            p {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.table-responsive-vertical {
    @include media-query(767px) {
        .table.bss-fastorder-multiple-form {
            thead, tbody, tfoot {
                > tr > th, > tr > td {
                    border-top: none;
                    width: 100% !important;
                }
                > tr > td {
                    display: block;
                    text-align: left;
                }
            }
            thead, tbody {
                > tr > th, > tr > td {
                    margin-bottom: 20px;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
            thead {
                display: none;
            }
            tbody {
                tr {
                    position: relative;
                    display: block;
                    margin-bottom: 10px;
                    padding: 60px 10px 10px;
                    border: 1px solid #ddd;
                }
                td {
                    position: relative;
                    padding-left: 125px;
                    &:before {
                        content: attr(data-th);
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        width: 110px;
                        color: $brand-primary;
                        font-size: 13px;
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                    &.center-label:before {
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .input-text.qty {
                        width: 60px;
                    }
                    &.bss-fastorder-row-ref {
                        .input-box {
                            max-width: 320px;
                        }
                    }
                    &.bss-fastorder-row-price {
                        .price {
                            line-height: 1.1;
                            p {
                                margin-bottom: 3px;
                            }
                            strong {
                                display: block;
                            }
                        }
                    }
                    &.bss-fastorder-row-action {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        width: auto !important;
                        padding: 0;
                        &:before {
                            content: none;
                        }
                    }
                    &.bss-fastorder-hidden {
                        height: 0;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
            tfoot {
                display: block;
                > tr {
                    display: block;
                    padding: 20px 16px;
                    background-color: #f1f2f4;
                    font-size: 0;
                    > td {
                        display: inline-block;
                        vertical-align: middle;
                        padding: 0;
                        width: auto !important;
                        > * {
                            font-size: 14px;
                        }
                    }
                    .td-upload {
                        width: 135px !important;
                        .bss-sample-csv {
                            display: none;
                        }
                    }
                    .td-add {
                        width: calc(100% - 135px) !important;
                        text-align: right;
                        button {
                            vertical-align: middle;
                            font-size: 0;
                            span {
                                font-size: 14px;
                            }
                        }
                    }
                    .td-example {
                        display: block;
                        margin-top: 15px;
                    }
                }
            }
            .bss-file-upload {
                padding-left: 0;
            }
        }
    }
    @include media-query(500px) {
        .table.bss-fastorder-multiple-form {
            tfoot {
                button, .button {
                    line-height: 1;
                }
                .bss-file-upload .customInput {
                    height: 48px;
                }
                > tr {
                    .td-upload {
                        width: 110px !important;
                    }
                    .td-add {
                        width: calc(100% - 110px) !important;
                        button, .button {
                            margin-left: 10px;
                        }
                    }
                }
                .bss-action-add {
                    width: 70px;
                }
                .tocart {
                    width: 106px;
                }
            }
        }
    }
    @include media-query(440px) {
        .table.bss-fastorder-multiple-form {
            tbody {
                td {
                    padding-left: 85px;
                    &:before {
                        width: 70px;
                    }
                }
            }
            tfoot {
                > tr {
                    .td-add {
                        button, .button {
                            margin-left: 8px;
                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @include media-query(380px) {
        .table.bss-fastorder-multiple-form {
            tfoot {
                > tr {
                    .td-upload, .td-add {
                        width: 100% !important;
                    }
                    .td-add {
                        text-align: left;
                        button, .button {
                            margin-left: 10px;
                        }
                    }
                }
                .bss-action-add {
                    width: calc(40% - 5px);
                }
                .tocart {
                    width: calc(60% - 5px);
                }
            }
        }
    }
}