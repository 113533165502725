.not-empty-name {
    display: none;
}

/*Popup*/
.bssfancybox-wrap {
    background-color: $body-bg;
    border-radius: 6px;
    max-width: 285px;
    outline: none;
    z-index: 9999;
    .bssfancybox-skin {
        padding: 15px 15px 30px !important;
    }
    .action.primary {
        position: static !important;
    }
    #close {
        text-align: right;
        a {     
            position: relative;
            display: inline-block;
            width: 24px;
            height: 24px;
            background-color: $brand-primary;
            border-radius: 4px;
            @include transitions(400ms);
            &:hover {
                background-color: $brand-secondary;
            }
            &:before, &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 14px;
                height: 2px;
                background-color: #fff;
            }
            &:before {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
            &:after {
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
    }
    h2 {
        color: $brand-primary;
        border-bottom: 4px solid $brand-primary;
        padding-bottom: 5px;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 15px;
    }
    p {
        font-size: 13px;
    }
    #list-wishlist {
        padding-left: 0;
        li {
            list-style: none;
        }
        label {
            font-weight: 400;
            input {
                margin-right: 5px;
            }
        }
    }
    .create-wishlist {
        border-bottom: 1px solid #eaeaea;
        text-align: right;
        .content {
            border-top: 1px solid #eaeaea;
            padding-top: 15px;
            margin-bottom: 10px;
            text-align: left;
            label {
                color: $brand-primary;
                text-transform: uppercase;
            }
            input {
                border-radius: 5px;
            }
        }
        #wishlist_create {
            margin-bottom: 20px;
        }
    }
    #loadingmask {
        font-weight: 600;
        text-align: center;
        margin-bottom: 5px;
        padding-bottom: 25px;
        border-bottom: 1px solid #eaeaea;
        img {
            display: block;
            margin: 20px auto 6px;
            width: 24px;
        }
    }
}

.bssfancybox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0,0,0,0.7);
    z-index: 999;
}

#loadingmask {
    display: none;
}

/*Customer Account*/
.wishlist-index-index {
    .action.back {
        display: inline-block;
        color: #555;
        font-size: 14px;
        text-decoration: underline;
        margin-top: 20px;
        &:hover {
            text-decoration: none;
        }
    }
    .product-item {
        margin-bottom: 20px;
        .box-tocart + .product-item-actions {
            margin-top: 15px;
            padding: 10px 5px 4px;
            background-color: #eaeaea;
            text-align: center;
            a {
                display: inline-block;
                background: $brand-primary;
                border-color: $brand-primary;
                color: #ffffff;
                padding: 4px 8px;
                border-radius: 5px;
                border: none;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                margin-left: 6px;
                margin-bottom: 6px;
                @include transition(400ms);
                &:first-child {
                    margin-left: 0;
                }
                &:hover {
                    background: $brand-secondary;
                    border-color: $brand-secondary;
                }
            }
        }
    }
}

#wishlist-form-validation {
    margin-bottom: 25px;
    table {
        width: 100%;
    }
    .content-create-wl, .create-wl {
        display: inline-block;
        vertical-align: middle;
    }
    .content-create-wl {
        max-width: 280px;
    }
    .create-wl {
        padding-top: 12px;
        padding-bottom: 11px;
    }
}

.form-wishlist-items {
    ol, ul {
        padding-left: 0;
    }
    .tabs {
        position: relative;
        margin-bottom: 40px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 4px;
            background-color: #fff;
        }
        @include media-query(540px) {
            border: 3px solid $brand-primary;
        }
        li {
            position: relative;
            float: left;
            display: inline-block;
            margin-bottom: 8px;
            background: $brand-primary;
            border: none;
            color: white;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            outline: 0;
            @include media-query(540px) {
                display: block;
                float: none;
                border: none;
                margin-bottom: 0;
            }
            &.selected {
                background: white;
                text-decoration: none;
                @include media-query(540px) {
                    border: none;
                }
                a {
                    color: $brand-primary;
                    &:before {
                        background-color: #0055af;
                    }
                    &:after {
                        content: none;
                    }
                }
            }
            a {
                position: relative;
                display: block;
                font-weight: 800;
                min-height: 45px;
                line-height: 32px;
                padding: 10px 26px 6px;
                color: white;
                text-decoration: none;
                font-size: 16px;
                @include media-query(991px) {
                    min-height: 40px;
                    padding: 7px 26px 3px;
                    font-size: 13px;
                }
                @include media-query(540px) {
                    padding: 5px 20px;
                }
                &:before, &:after {
                    content: '';
                    position: absolute;
                    display: block;
                }
                &:before {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    background-color: #fff;
                    @include media-query(540px) {
                        content: none;
                    }
                }
                &:after {
                    top: 50%;
                    /*right: 100%;*/
                    left: -1px;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 40px;
                    max-height: calc(100% - 20px);
                    background-color: #f1f2f4;
                    @include media-query(540px) {
                        top: -1px;
                        right: inherit;
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 100%;
                        height: 1px;
                    }
                }
            }
            @include media-query(540px) {
                &:first-of-type {
                    a:after {
                        content: none;
                    }
                }
            }
        }
    }

    .tabs-wishlist {
        .editwl_link {
            margin-bottom: 25px;
            padding-bottom: 20px;
            border-bottom: 1px solid #eaeaea;
            label {
                text-transform: uppercase;
            }
            .mwishlist_name {
                margin-bottom: 10px;
                max-width: 280px;
            }
        }
        .actions-toolbar {
            margin: 25px 0 0;
            padding-top: 20px;
            border-top: 1px solid #eaeaea;
            @include media-query(767px) {
                padding-right: 15px;
                padding-left: 15px;
            }
            @include media-query(440px) {
                .primary button {
                    display: block;
                    margin: 0px 0 8px 0;
                    width: 100%;
                }
            }
        }
    }
}

.wishlist-index-share {
    .legend {
        margin-bottom: 15px;
    }
    .field {
        textarea {
            width: 100%;
        }
    }
    .actions-toolbar {
        margin-top: 20px;
        .primary {
            text-align: right;
            float: right;
        }
        .action.back {
            display: inline-block;
            color: #555;
            font-size: 14px;
            text-decoration: underline;
            margin-top: 9px;
        }
        @include media-query(539px) {
            .primary, .secondary {
                text-align: center;
            }
            .primary {
                float: none;
                margin-bottom: 5px;
            }
        }
    }
}