.customer-account-forgotpassword, .customer-account-createpassword, .b2b-portal-login,
.customer-account-create, .b2b-portal-register, .account, .customer-account-logoutsuccess {
	#maincontent {
		margin-top: 70px;
		margin-bottom: 70px;
		@include media-query(767px) {
			margin-top: 40px;
			margin-bottom: 40px;
			.sidebar-main {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}

.customer-account-forgotpassword, .customer-account-createpassword, .b2b-portal-login, .customer-account-logoutsuccess {
	.page.messages {
		.alert {
			background-color: #fff;
			position: relative;
			padding-left: 19px;
			border: none;
			border-radius: 0;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 4px;
				height: 100%;
				background-color: $gray;
			}
			&.alert-error {
				&:before {
					background-color: $brand-secondary;
				}
			}
			&.alert-success {
				&:before {
					background-color: $brand-success;
				}
			}
		}
	}

	.login-block {
		padding: 30px 75px 50px;
		border-radius: 12px;
		box-shadow: 0 0 15px 0 rgba(0,0,0,0.35);
		@include media-query(1199px) {
			padding-left: 45px;
			padding-right: 45px;
		}
		@include media-query(991px) {
			padding-left: 35px;
			padding-right: 35px;
		}
		&.main {
			padding-left: 100px;
			padding-right: 100px;
			background-color: #fff;
			@include media-query(1199px) {
				padding-left: 70px;
				padding-right: 70px;
			}
			@include media-query(991px) {
				padding-left: 35px;
				padding-right: 35px;
			}
			@include media-query(767px) {
				margin-bottom: 25px;
			}
			h2 {
				color: $pt-black;
			}
			.actions-toolbar {
				.secondary {
					text-align: right;
					@include media-query(539px) {
						text-align: center;
					}
				}
			}
		}
		&.secondary {
			background-color: $brand-secondary;
			color: #fff;
			.actions-toolbar {
				.primary {
					.action {
						&:hover, :focus {
							background-color: $brand-hover !important;
							color: #fff !important;
						}
					}
				}
				.secondary {
					.action {
						background-color: #003875 !important;
						&:hover, :focus {
							background-color: $brand-hover !important;
							color: #003875 !important;
						}
					}
				}
			}
		}
		h2 {
			font-weight: bold;
			text-transform: uppercase;
			line-height: 0.9;
		}
		.actions-toolbar {
			margin-top: 15px;
			@include media-query(991px, 768px) {
				.col-md-6 + .col-md-6 {
					margin-top: 10px;
				}
			}
			@include media-query(539px) {
				.col-md-6 + .col-md-6 {
					margin-top: 10px;
				}
			}
			.primary, .secondary {
				text-align: center;
				.action {
					display: inline-block;
					width: 100%;
					font-size: 17px;
					@include media-query(1199px) {
						font-size: 16px;
					}
				}
			}
		}
		.label {
			font-size: 16px;
			color: $pt-black;
			font-family: 'effra';
		}
		.field {
			margin-bottom: 15px;
			.input-text {
				border-radius: 3px;
				background: #fff;
				border: 1px solid $pt-gray-04;
				&:focus {
					border-color: $pt-black;
					-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
					-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
					box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				}
			}
		}
		.required > .label > span:after {
			content: none;
		}
	}
}

.customer-account-create, .customer-account-forgotpassword, .customer-account-createpassword, .customer-account-logoutsuccess {
	.columns {
		margin-right: -15px;
		margin-left: -15px;
	}
}

.customer-account-create {
	.columns {
		margin-right: 0;
		margin-left: 0;
		> .column.main {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
}

.customer-account-forgotpassword, .customer-account-createpassword, .customer-account-logoutsuccess {
	.page-wrapper {
		@include blue-linear;
	}
	.login-block {
		.actions-toolbar {
			.secondary {
				.action.back {
					display: inline-block;
					margin-top: 10px;
					color: $brand-primary;
					font-size: 14px;
					text-decoration: none;
					&:hover, &:focus {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.customer-account-createpassword {
	.fieldset {
		.control {
			#password-strength-meter-container {
				float: left;
				margin-bottom: 20px;
			}
		}
		.field.confirmation {
			float: left;
			width: 100%;
		}
	}
}
.customer-account-edit {
	.form-group {
		float: left;
		width: 100%;
		.control {
			#password-strength-meter-container {
				float: left;
			}
		}
	}
}

.b2b-portal-login, .customer-account-create, .b2b-portal-register, .account {
	#maincontent {
		padding-right: 0;
		padding-left: 0;
	}
}

.b2b-portal-login {
	.page.messages {
		margin-left: 15px;
    	margin-right: 15px;
	}
	.row-equal {
		display: table;
		width: calc(100% + 30px);
		height: 500px;
		max-height: 100%;
		@include media-query(767px) {
			display: block;
			height: auto;
			max-height: inherit;
		}
		> [class*="col-"] {
			float: none;
			display: table-cell;
			vertical-align: top;
			height: 100%;
			@include media-query(767px) {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}

	.login-block {
		height: 100%;
		&.main {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;
			> .block {
				flex: 1;
			}
		}
		&.secondary {
			.customer {
				border-top: 1px solid $pt-gray-04;
				margin-top: 25px;
				padding-top: 5px;
			}
		}
        &.notice {
            border-radius: 0px;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			color: #aa4343;
			background-color: #fff;
			margin-bottom: 20px;
			padding: 18px 20px 28px;
			border-left: 8px solid $brand-secondary;
			.col-text, .col-button {
				margin-top: 10px;
				padding: 0 10px;
			}
			.col-text {
				flex: 1;
				.title {
					margin-top: 0;
					font-weight: 700;
					text-transform: uppercase;
				}
				p:last-of-type {
					margin-bottom: 0;
				}
			}
			.col-button {
				width: 140px;
				a {
					display: inline-block;
				}
			}
        }
		h2 {
			@include media-query(767px) {
				font-size: 24px;
			}
			&.block-title-login {
				position: relative;
				margin-left: 68px;
				margin-bottom: 20px;
				padding-left: 15px;
				border-left: 1px solid #e4e4e4;
				color: $pt-black;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: -68px;
					transform: translateY(-50%);
					display: block;
					width: 53px;
					height: 100%;
					background: url('../images/login-account-icon.svg') no-repeat left center;
					background-size: contain;
				}
			}
		}
		.actions-toolbar {
			.action.remind {
				display: inline-block;
				color: $brand-primary;
				font-size: 14px;
				text-decoration: none;
				margin-top: 9px;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.block.block-new-customer {
			p.big {
				font-size: 18px;
			}
		}
		.field.captcha {
			margin-top: 20px;
		}
	}
}

.account {
	form#wishlist-form-validation {
		table {
			td {
				.content-create-wl {
					margin-right: -3px;
					input {
						margin-bottom: 0;
					}
				}
				button.secondary {
					width: auto;
					background: $brand-primary;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					font-family: 'effra';
					&:hover {
						background: $brand-hover;
						outline: 2px solid #fff;
						box-shadow: 0 0 0 4px $brand-hover;
					}
					&:focus {
						background: $brand-hover;
						outline: 2px solid #fff;
						box-shadow: 0 0 0 4px $brand-hover;
					}
				}
			}
		}
	}
	&.wishlist-index-index {
		.product-item-info {
			.product-item-actions {
				.actions-primary {
					.field.qty {
						input {
							margin-bottom: 2px;
						}
					}
				}
				a {
					background: $brand-primary;
					font-family: 'effra';
					&:hover {
						background: $brand-hover!important;
						outline: 2px solid #fff;
						box-shadow: 0 0 0 4px $brand-hover;
					}
					&:focus {
						background: $brand-hover!important;
						outline: 2px solid #fff;
						box-shadow: 0 0 0 4px $brand-hover;
					}
				}
			}
		}
		.form-wishlist-items {
			.tabs {
				li {
					a {
						font-family: 'effra';
					}
					&.selected {
						a {
							&:before {
								content: none;
							}
						}
					}
				}
			}
		}
		.tabcontents {
			.tabs-wishlist {
				label {
					margin-left: 15px;
					margin-right: 5px;
				}
				button.secondary {
					width: auto;
					background: $brand-primary;
					&:first-child {
						margin-right: 5px;
					}
					&:hover {
						background: $brand-hover;
						outline: 2px solid #fff;
						box-shadow: 0 0 0 4px $brand-hover;
					}
					&:focus {
						background: $brand-hover;
						outline: 2px solid #fff;
						box-shadow: 0 0 0 4px $brand-hover;
					}
					&.action.update {
						margin-right: 0;
					}
					&.share_wishlist_button {
						margin: 0 5px;
					}
				}
			}
		}
	}
	.page-title-wrapper {
		&.page-title-wrapper-mobile {
			display: none;
		}
	}

	.page.messages {
		margin: 0 15px;
	}
	.sidebar {
		.sidebar-main {
			.account-nav {
				.account-nav-content {
					ul {
						li {
							a {
								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
				}
			}
		}
	}
	.column.main {
		.fieldset {
			@include media-query(767px) {
				margin-bottom: 30px;
			}
			.legend {
				color: $brand-primary;
				font-size: 2.2rem;
    			text-transform: uppercase;
				font-weight: 700;
				border-bottom: 1px solid #c6c6c6;
				margin-bottom: 25px;
				padding-bottom: 5px;
			}
		}
		.box-actions {
			a {
				display: inline-block;
				background-color: $brand-primary;
				color: #fff;
				margin-right: 4px;
				border-radius: 4px;
				font-family: 'museo-slab';
				font-weight: 500;
				transition: all 0.15s ease-out;
				padding: 4px 8px;
				&:hover, &:focus {
					background: $brand-hover!important;
					color: white;
					outline: 2px solid #fff;
					box-shadow: 0 0 0 4px $brand-hover;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.block:not(.widget) {
			margin-bottom: 30px;
			@include media-query(767px) {
				margin-bottom: 30px;
			}
			.block-title {
				border-bottom: 2px solid $brand-secondary;
				margin-bottom: 25px;
				padding-bottom: 5px;
				.action {
					margin-top: 5px;
					margin-left: 15px;
					float: right;
					@include media-query(560px) {
						display: block;
						float: none;
						margin: 5px 0;
					}
				}
				strong{
					font-family: 'effra';
					color: $pt-black;
					font-weight: bold;
					font-size: 24px;
					text-transform: capitalize;
				}
			}
			.box-title {
				margin: 0 0 10px;
				display: block;
				span {
					font-size: 1.6rem;
					font-weight: 900;
					color: $pt-black;
				}
			}
			.block-content {
				.box {
					@include media-query(767px) {
						margin-bottom: 20px;
					}
					p, address {
						font-size: 16px;
						a {
							color: $brand-primary;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
			address {
				margin-bottom: 10px;
			}
		}
	}

	#maincontent .sidebar-all {
		@include media-query(767px) {
			margin-bottom: 50px;
			.sidebar-main {
				padding: 0;
			}
		}
		.block-collapsible {
			.title {
				position: relative;
				padding-bottom: 10px;
				margin: 1.5em 0 0.5em;
				font-family: 'effra';
				color: $pt-black;
				font-weight: bold;
				font-size: 24px;
				text-transform: capitalize;
				border-bottom: 2px solid $brand-secondary;
				&.account-nav-title {
					display: none;
					@include media-query(767px) {
						display: block;
					}
				}
				@include media-query(767px) {
					padding-right: 40px;
					cursor: pointer;
				}
				&:focus {
					outline: none;
				}
				[class^="icon-"] {
				  position: absolute;
				  top: 50%;
				  right: 0;
				  transform: translateY(-50%);
				  display: none;
				  width: 40px;
				  height: 40px;
				  background-repeat: no-repeat;
				  background-position: right 9px;
				  background-size: 20px 12px;
				  @include media-query(767px) {
				    display: block;
				  }
				}
				.icon-close {
				  background-image: url('../images/arrow-down-blue.svg');
				}
				.icon-open {
				  background-image: url('../images/arrow-up-blue.svg');
				}
			}
			.content {
				@include media-query(767px) {
					display: none;
				}
			}
		}
	}

	.account-nav-content, .block-collapsible-nav-content {
		@include media-query(767px) {
			margin-bottom: 40px;
		}
		.item {
			display: block;
			border-bottom: 1px solid $pt-gray-04;
			padding: 0;
			text-transform: uppercase;
			&:last-child {
				border-bottom: none;
			}
			a {
				font-family: 'effra';
				text-transform: capitalize;
				color: #2D2926;
				font-size: 16px;
				font-weight: bold;
				&:hover {
					color: $pt-gray-09;
					text-decoration: none!important;
				}
			}
		}

		.item {
			border-bottom: 1px solid $pt-gray-04;
			a {
				font-family: 'effra';
				text-transform: capitalize;
				color: #2D2926;
				font-size: 16px;
				font-weight: bold;
				&:hover {
					color: $pt-gray-09;
					text-decoration: none!important;
				}
			}
			&.current {
				padding: 10px 15px;
				strong {
					font-family: 'effra';
					text-transform: capitalize;
					color: $brand-primary;
					font-size: 16px;
					font-weight: bold;
				}
			}
		}
	}
	div.content-header {
		table {
			width: 100%;
			tr {
				padding-bottom: 0;
				td {
					position: relative;
				}
				.view_all {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					margin-top: 0;
					float: none;
					padding: 0;
					margin: 0;
				}
			}
			h3 {
				float: none;
				padding-bottom: 12px;
				padding-right: 60px;
				font-family: 'effra';
				color: $pt-black;
				font-weight: bold;
				font-size: 24px;
				text-transform: capitalize;
				border-bottom: 2px solid $brand-secondary;
			}
			[class^="icon-"], [class*=" icon-"] {
				font-family: $font-family-base !important;
			}
		}
	}
	.boxed-content {
		.table-wrapper {
			margin: 5px 0;
			.input[type="text"] {
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}
		table {
			> thead {
				background-color: #fff;
				> tr {
					> th {
						color: $brand-primary;
						border-bottom: 1px solid $brand-primary;
					}
					> td {
						border-top: 1px solid $pt-gray-04;
					}
				}
			}
			thead, tbody {
				tr {
					td, th {
						&:first-child {
							padding-left: 0;
						}
						&:last-child {
							padding-right: 0;
						}
					}
					@include media-query(767px) {
						td, th {
							&:last-child {
								padding-right: 10px;
							}
						}
					}
				}
			}
			tbody {
				tr {
					td {
						&.empty-text {
							border-top: 0;
						}
					}
				}
			}
		}
	}
	.block-title {
		button {
			background: none;
			border: none;
			margin-top: 5px;
			padding: 0;
			color: $brand-primary;
		}
	}
	.block-content {
		select {
			display: inline-block;
			color: #373737;
			padding: 5px 35px 4px 14px;
			width: 100%;
			max-width: 100%;
			margin: 0 5px 0 0;
			border: 1px solid $pt-gray-04;
			box-shadow: none;
			border-radius: 5px;
			appearance: none;
			background-color: #ffffff;
			background-image: url('../images/arrow-down-black.svg');
			background-repeat: no-repeat;
			background-position: calc(100% - 10px) center;
			background-size: 12px 8px;
			&:focus {
				border-color: $pt-black;
				-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
			}
		}
	}
}

.customerconnect-dashboard-index, .customerconnect-account-index, .customer-account-index {
	.alert-error {
		margin: 0 0 10px;
	    padding: 12px 20px 12px 25px;
	    display: block;
	    font-size: 1.3rem;
	    background: #fae5e5;
	    color: #e02b27;
	    padding-left: 45px;
	    position: relative;
	    > *:first-child:before {
	    	-webkit-font-smoothing: antialiased;
    	    font-size: 24px;
    	    line-height: 24px;
    	    color: #b30000;
    	    content: '\f071';
    	    font-family: 'FontAwesome';
    	    margin: -12px 0 0;
    	    vertical-align: middle;
    	    display: inline-block;
    	    font-weight: normal;
    	    overflow: hidden;
    	    speak: none;
    	    left: 0;
    	    top: 22px;
    	    width: 45px;
    	    position: absolute;
    	    text-align: center;
	    }
	}
}

.customer-account-create, .b2b-portal-register, .customer-address-form, .form-address-edit {
	.form-create-account {
		.legend {
			color: $pt-black;
			border: none;
			text-transform: capitalize;
			font-size: 24px;
			font-weight: bold;
			font-family: 'effra';
			span {
				display: inline-block;
				margin-top: 10px;
			}
		}
		.input-text {
			border-radius: 5px;
			&#password {
				border-radius: 5px;
			}
		}

		select {
			display: inline-block;
			color: #373737;
			padding: 9px 35px 9px 14px;
			width: 100%;
			max-width: 100%;
			margin: 0 5px 0 0;
			border: 1px solid $pt-gray-04;
			box-shadow: none;
			border-radius: 5px;
			appearance: none;
			background-color: #ffffff;
			background-image: url('../images/arrow-down-black.svg');
			background-repeat: no-repeat;
			background-position: calc(100% - 10px) center;
			background-size: 12px 8px;
			&:focus {
				border-color: $pt-black;
				-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
			}
	    }
	    select::-ms-expand {
	      display: none;
	    }
	    select:hover {
	      border-color: #888;
	    }
	    select:focus {
	      outline: none;
	    }
	    select option {
	      font-weight: normal;
	    }

		.fieldset {
			@include media-query(767px) {
				margin-bottom: 25px;
			}
			.field {
				margin-bottom: 15px;
				padding-top: 1px;
				display: flex;
				flex-flow: column;
				.input-box {
					#truncated_message_company {
						font-family: 'effra';
					}
				}
			}
		}
		.actions-toolbar {
			margin-top: 20px;
			.secondary {
				@include media-query(539px) {
					text-align: center;
				}
			}
			.action.back {
				display: inline-block;
				color: #555;
				font-size: 14px;
				text-decoration: none;
				margin-top: 9px;
				&:hover {
					text-decoration: underline;
				}
			}
			.primary {
				text-align: right;
				float: right;
				@include media-query(539px) {
					float: none;
					text-align: center;
					margin-bottom: 5px;
				}
			}
			.action.submit {
				font-size: 18px;
			}
		}
	}
}
.block-customer-login {
	.fieldset {
		.field.password {
			display: flex;
			flex-flow: column;
			width: 100%;
		}
	}
}

.customer-account-create, .b2b-portal-register, .customer-account-edit, .customer-address-index, .customer-address-form, .newsletter-manage-index {
	.form-check {
		margin-top: 10px;
		.form-check-input {
			display: none;
		}
		.form-check-label {
			position: relative;
			padding-left: 32px;
			cursor: pointer;
			&:before {
				content: '';
				position: absolute;
				top: calc(50% - 1px);
				left: 0;
				transform: translateY(-50%);
				display: block;
				width: 22px;
				height: 22px;
				background-color: #efefef;
				border-radius: 3px;

				box-shadow: none;
				border-radius: 5px;
				margin-right: 0;
				float: left;
				background: #ffffff;
				border: 1px solid $pt-gray-04;
				-moz-border-radius: 0;
				text-shadow: none;
				margin-bottom: 10px;
				color: $pt-black;
			}
		}
		.form-check-input:checked + .form-check-label:before {
			background: #fff url('../images/blue-tick.svg') no-repeat center center;
			background-size: 14px 14px;
		}
	}
	.actions-toolbar {
		margin-top: 20px;
		.secondary {
			@include media-query(539px) {
				text-align: center;
			}
		}
		.action.back {
			display: inline-block;
			color: $brand-primary;
			font-size: 14px;
			text-decoration: none;
			margin-top: 9px;
			&:hover {
				text-decoration: underline;
			}
		}
		.primary {
			text-align: right;
			float: right;
			@include media-query(539px) {
				float: none;
				text-align: center;
				margin-bottom: 5px;
			}
		}
		.action.submit {
			font-size: 18px;
		}
	}
}

.customer-address-index {
	.block-addresses-list {
		.addresses {
			.row {
				margin-right: -10px;
				margin-left: -10px;
				> [class*="col-"] {
					padding-right: 10px;
					padding-left: 10px;
				}
			}
			.item {
				border: 1px solid #c6c6c6;
				padding: 12px;
				margin-bottom: 20px;
				address {
					div {
						line-height: 1.1;
						margin-bottom: 4px;
						a {
							color: $brand-primary;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
		.actions .action {
			margin-top: 10px;
			+ .action {
				margin-top: 5px;
			}
		}
	}
}

.customer-account-edit {
	.form-check {
		display: inline-block;
    	margin-right: 15px;
	}
	.actions-toolbar {
		.action.back {
			margin-top: 7px;
		}
	}
}

.account.customer-account-index, .customer-account-dashboard, .account.sales-order-history {
	.action-buttons {
		width: 105px;
		text-align: right;
		.action {
			text-align: center;
		}
		.box-actions a {
			display: block !important;
			width: 100%;
			margin-right: 0 !important;
			margin-bottom: 4px;
		}
	}
	.table-responsive {
		@include media-query(767px) {
			padding: 0 10px;
		}
	}
	.table.orders-history {
		margin-bottom: 0;
		> thead {
			> tr {
				> th {
					color: $brand-primary;
					border-bottom: 1px solid $brand-primary;
				}
			}
		}
		thead, tbody {
			tr {
				td, th {
					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						padding-right: 4px;
					}
				}
				@include media-query(767px) {
					td, th {
						&:last-child {
							padding-right: 10px;
						}
					}
				}
			}
		}
	}
}

.account.sales-order-history {
	.toolbar {
		.pager {
			margin: 0;
		}
		&.top .pages, &.top .toolbar-amount, &.bottom .limiter {
			display: none;
		}
		&.top {
			padding: 10px 20px;
			background: #f1f2f4;
			margin-bottom: 25px;
			.pager {
				text-align: right;
			}
			.limiter {
				.limiter-label {
					color: #0051b2;
					font-weight: 700;
					text-transform: uppercase;
					font-size: 13px;
					margin-right: 10px;
					margin-bottom: 0;
				}
			}
			select {
				display: inline-block;
				color: #373737;
				padding: 5px 35px 4px 14px;
				width: 100%;
				max-width: 100%;
				margin: 0 5px 0 0;
				border: 1px solid $pt-gray-04;
				box-shadow: none;
				border-radius: 5px;
				appearance: none;
				background-color: #ffffff;
				background-image: url('../images/arrow-down-black.svg');
				background-repeat: no-repeat;
				background-position: calc(100% - 10px) center;
				background-size: 12px 8px;
				&:focus {
					border-color: $pt-black;
					-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
					-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
					box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				}
		    }
		    select::-ms-expand {
		      display: none;
		    }
		    select:focus {
		      outline: none;
		    }
		    select option {
		      font-weight: normal;
		    }
		}
		&.bottom {
			font-size: 0;
			margin-top: 25px;
			padding-top: 30px;
			background-color: transparent;
			border-top: 4px solid #eaeaea;
			.toolbar-amount, .pages {
				display: inline-block;
				vertical-align: middle;
			}
			.toolbar-amount {
				font-size: 14px;
				margin-right: 20px;
				margin-bottom: 0;
				max-width: 140px;
				text-align: left;
			}
			.pages {
				max-width: calc(100% - 160px);
				width: 100%;
				text-align: right;
				.pagination {
					li {
						margin-right: 8px;
						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}

.sales-order-view {
	.order-status {
		display: inline-block;
		margin-bottom: 15px;
		padding: 8px 20px;
		border-radius: 18px;
		background-color: $brand-primary;
		color: #fff;
		text-transform: uppercase;
	}
	.order-date {
		.label {
			display: inline-block;
			color: $brand-primary;
			font-weight: 700;
		}
	}
	.order-actions-toolbar {
		background-color: #f1f2f4;
		margin: 10px 0 25px;
		padding: 10px 15px;
		text-align: right;
		a {
			display: inline-block;
			vertical-align: top;
			background-color: $brand-primary;
			color: #fff;
			border-radius: 4px;
			padding: 7px 16px;
			margin-right: 5px;
			transition: background-color 400ms linear;
			&:hover {
				background-color: $brand-secondary;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.column.main .table-wrapper .block {
		margin-bottom: 0;
		.block-title {
			margin-bottom: 20px;
		}
	}
	.table-wrapper {
		margin: 0;
		.table {
			margin-bottom: 15px;
			> thead {
				> tr {
					> th {
						color: $brand-primary;
						border-bottom: 1px solid $brand-primary;
					}
				}
			}
			td, th {
				&.col.subtotal, &.a-right.mark {
					text-align: right;
				}
			}
			tfoot {
				> tr:not(.subtotal) > td, > tr:not(.subtotal) > th {
					border-top: none;
				}
			}
			.mark {
				background-color: #f1f2f4;
			}
			.items-qty {
				padding-left: 0;
				margin-bottom: 0;
				li {
					list-style: none;
				}
			}
		}
	}
	.actions-toolbar {
		margin-bottom: 40px;
		.action.back {
			color: $brand-primary;
			font-size: 14px;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.block-order-details-view {
		margin-bottom: 0;
		.box {
			margin-bottom: 20px;
			.box-title {
				text-transform: uppercase;
			}
			dt {
				font-weight: 400;
			}
		}
	}
}

.customerconnect-account-index, .customerconnect-orders-index, .customerconnect-invoices-index, .customerconnect-payments-index,
.customerconnect-shipments-index, .customerconnect-rmas-index, .customerconnect-servicecalls-index, .customerconnect-rfqs-index, .customerconnect-rfqs-new, .customerconnect-skus-index {
	.column.main {
		> p {
			margin-bottom: 15px;
			a {
				display: inline-block;
				margin-left: 5px;
				font-weight: 700;
				color: $brand-primary;
				&:hover, &:focus {
					border-bottom: 2px solid $brand-primary;
				}
			}
		}
	}
	.table-wrapper {
		margin: 5px 0;
		overflow-x: auto;
	}
	.admin__data-grid-toolbar {
	    display: flex;
	    flex-wrap: wrap;
	    align-items: center;
	    justify-content: space-between;
		margin: 0 0 15px;
	}
	.admin__data-grid-header-row {
		float: none;
		@include media-query(420px) {
			flex-grow: 1;
    		width: 100%;
    		margin: 5px 0;
		}
	}
	.admin__data-grid-pager-wrap, .admin__data-grid-pager {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		.admin__control-support-text {
			margin-left: 5px;
		}
	}
	.admin__data-grid-pager-wrap {
		width: 100%;
		flex-basis: 100%;
		margin-bottom: 10px;
		.admin__control-support-text:last-child {
			flex-grow: 1;
			text-align: right;
			@include media-query(991px, 768px) {
				margin-top: 15px;
				text-align: left;
			}
			@include media-query(420px) {
				margin-top: 15px;
				text-align: left;
			}
		}
	}
	[for="customer_account_contacts_listcustomer_account_contacts_list_page-limit"],
	[for="customer_account_shippingaddress_listcustomer_account_shippingaddress_list_page-limit"],
	[for="customerconnect_orderscustomerconnect_orders_page-limit"],
	[for="customerconnect_invoices_gridcustomerconnect_invoices_grid_page-limit"],
	[for="customerconnect_paymentscustomerconnect_payments_page-limit"],
	[for="customerconnect_shipmentscustomerconnect_shipments_page-limit"],
	[for="customerconnect_rmascustomerconnect_rmas_page-limit"],
	[for="customerconnect_servicecallscustomerconnect_servicecalls_page-limit"],
	[for="customerconnect_rfqscustomerconnect_rfqs_page-limit"],
	[for="customerconnect_skuscustomerconnect_skus_page-limit"] {
		margin-right: 10px;
	}
	.admin__data-grid-pager {
		.page-control-input {
			height: 31px;
			text-align: center;
			margin-right: 0;
			margin-left: 3px;
		}
		@include media-query(475px) {
			margin-top: 10px;
		}
	}
	.admin__data-grid-export {
		padding-right: 0;
	}
	.admin__control-support-text {
		padding-top: 0;
		margin-bottom: 0;
	}
	.admin__data-grid-header, .admin__data-grid-export {
		select {
			display: inline-block;
			color: #373737;
			padding: 5px 35px 4px 14px;
			width: 100%;
			max-width: 100%;
			margin: 0 5px 0 0;
			border: 1px solid $pt-gray-04;
			box-shadow: none;
			border-radius: 5px;
			appearance: none;
			background-color: #ffffff;
			background-image: url('../images/arrow-down-black.svg');
			background-repeat: no-repeat;
			background-position: calc(100% - 10px) center;
			background-size: 12px 8px;
			&:focus {
				border-color: $pt-black;
				-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
			}
		}
		button.action-default {
			margin-top: 10px;
		}
	}
	.admin__data-grid-header.admin__data-grid-toolbar {
		label.admin__control-support-text {
			padding-top: 0;
		}
		button {
			background: $brand-secondary;
			border: 1px solid $brand-secondary;
			border-radius: 4px;
			-moz-border-radius: 4px;
			-webkit-border-radius: 4px;
			text-transform: uppercase;
			color: #fff;
			font-family: 'effra';
			font-weight: 600;
			transition: all 0.15s ease-out;
			min-width: 150px;
			&:hover {
				outline: 2px solid #fff;
				box-shadow: 0 0 0 4px $brand-secondary;
			}
			&:focus {
				outline: 2px solid #fff;
				box-shadow: 0 0 0 4px $brand-secondary;
			}

			&:last-child {
				margin-right: 0;
			}
			&.action-previous, &.action-next {
				position: relative;
				min-width: unset;
				&:before {
					content: '';
					position: absolute;
					top: calc(50% - 5px);
					left: 50%;
					display: block;
					background: url('../images/arrow-down-white.svg') no-repeat center center;
					background-size: contain;
					width: 15px;
					height: 9px;
				}
			}
			&.action-previous {
				margin-right: 10px;
				&:before {
					left: calc(50% - 2px);
					transform: translate(-50%) rotate(90deg);
				}
			}
			&.action-next {
				margin-left: 10px;
				&:before {
					transform: translate(-50%) rotate(-90deg);
				}
			}
		}
		.admin__data-grid-pager {
			padding: 0 15px;
			label {
				padding: 0 3px;
				margin-right: 2px;
			}
			button {
				margin: 0 2px;
				&:hover {
					background: $brand-hover;
					outline: 2px solid #fff;
					box-shadow: 0 0 0 4px $brand-hover;
				}
				&:focus {
					background: $brand-hover;
					outline: 2px solid #fff;
					box-shadow: 0 0 0 4px $brand-hover;
				}
			}
		}
	}
}



.customerconnect-orders-index, .customerconnect-invoices-index, .customerconnect-payments-index, .customerconnect-shipments-index,
.customerconnect-rmas-index, .customerconnect-servicecalls-index, .customerconnect-rfqs-index, .customerconnect-rfqs-new, .customerconnect-skus-index {
	.admin__data-grid-export {
		padding-right: 0;
	}
	#customerconnect_orders_table, #customerconnect_invoices_grid_table, #customerconnect_payments_table, #customerconnect_shipments_table,
	#customerconnect_rmas_table, #customerconnect_servicecalls_table, #customerconnect_rfqs_table, #rfq_lines_table, #customerconnect_skus_table {
		margin-top: 40px;
		.no-display {
			display: none !important;
		}
		.col-order_number {
			min-width: 80px;
			a {
				color: $brand-primary;
				&:hover {
					text-decoration: underline;;
				}
			}
		}
		.col-packing_slip {
			a {
				color: $brand-primary;
				&:hover {
					text-decoration: underline;;
				}
			}
		}
		&.data-grid thead {
			background: #fff;
			th {
				color: $pt-black;
				border-bottom: 1px solid $pt-gray-09;
			}
			.data-grid-filters {
				input {
					margin-bottom: 5px;
					padding: 0 6px;
					font-size: 12px;
					border-radius: 5px;
				}
				select.admin__control-select {
					display: inline-block;
					color: #373737;
					padding: 5px 35px 4px 14px;
					width: 100%;
					max-width: 100%;
					margin: 0 5px 0 0;
					border: 1px solid $pt-gray-04;
					box-shadow: none;
					border-radius: 5px;
					appearance: none;
					background-color: #ffffff;
					background-image: url('../images/arrow-down-black.svg');
					background-repeat: no-repeat;
					background-position: calc(100% - 10px) center;
					background-size: 12px 8px;
					&:focus {
						border-color: $pt-black;
						-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
						-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
						box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
					}
				}
			}
		}
		&.data-grid tbody {
			tr {
				td {
					.col-our_order_number, .col-customer_reference {
						color: $brand-primary;
					}
				}
			}
		}
	}
}

.customerconnect-account-index, .customerconnect-rfqs-new {
	.content-header {
		.form-buttons {
			padding-top: 2px;
			button {
				background: none;
				border: none;
				width: 141px;
				padding: 0;
				color: $brand-primary;
				text-align: right;
			}
		}
	}
}

.customer-account-create {
	.form-create-account select {
		padding: 11px 35px 9px 14px;
	}
	.field.street {
		.nested {
			margin-top: 8px;
			.field:first-child {
				margin-bottom: 8px;
			}
		}
	}
	.field {
		&.password {
			.control {
				#password-strength-meter-container {
					float: left;
				}
			}
		}
	}
}

.customerconnect-account-index {
	.info-list {
		p {
			margin-bottom: 0;
		}
		.label {
			margin-top: 2px;
			white-space: normal;
		}
	}
	.col-1.box-info.box-account {
		padding-right: 10px;
	}
	.block-dashboard-info, .block-dashboard-info + .box-info.box-account {
		float: none !important;
		width: 100% !important;
		clear: both;
	}
	table.data-grid .a-right {
		text-align: left;
	}
	&.account div.content-header table h3 {
		padding-bottom: 9px;
	}
	#customer_account_shippingaddress_list_table, #customer_account_contacts_list_table {
		tbody > tr {
			cursor: pointer;
		}
	}
	.block-dashboard-info {
		.block-title {
			position: relative;
			padding-right: 35px;
			a {
				position: absolute;
				bottom: 50%;
				right: 0;
				transform: translateY(50%);
			}
		}
	}
}

.customerconnect-orders-details {
	.expand-table {
		width: calc(100% - 40px);
		margin-left: 40px;
		margin-bottom: 10px;
		thead tr.headings {
			background: none;
		}
		thead, tbody {
			th, td {
				padding: 8px;
			}
		}
	}
}

.customerconnect-rfqs-index {
	.page-actions, .button {
		button {
			background-color: $brand-primary;
			color: #fff;
			border: none;
			border-radius: 4px;
			padding: 8px 14px;
			@include transitions(400ms);
			&.action-next, &.action-previous {
				width: auto;
				height: auto;
			}
			&:hover {
				background-color: $brand-secondary;
			}
		}
	}
	.page-actions {
		margin-bottom: 20px;
	}
}

.customerconnect-rfqs-new {
	.column.main > form#rfq_update .box-info.box-account div.page-actions {
		float: none;
		button {
			background-color: $brand-primary;
			color: #fff;
			border: none;
			border-radius: 4px;
			padding: 5px 10px 4px;
			@include transitions(400ms);
			&:hover {
				background-color: $brand-secondary;
			}
		}
	}
	.file-name-size {
		button {
			background-color: $brand-primary;
			color: #fff;
			border: none;
			border-radius: 4px;
			margin-left: 10px;
			padding: 5px 10px 4px;
			@include transitions(400ms);
			&:hover {
				background-color: $brand-secondary;
			}
		}
	}
	#line-add {
		.la_row {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;
			height: auto;
			border-top: none;
			border-bottom: 1px solid #eaeaea;
			margin-bottom: 5px;
			padding-bottom: 10px;
		}
		.la_field {
			float: none;
			text-align: left;
			width: auto;
			margin-right: 10px;
			&:last-child {
				margin-right: 0;
				flex-grow: 1;
				text-align: right;
			}
			label, input {
				display: block;
				width: 100%;
			}
			label {
				margin-bottom: 3px;
			}
			input.input-text {
				width: 100%;
			}
			.la_delete {
				background-color: $brand-primary;
				font-size: 13px;
				color: #fff;
				border-radius: 4px;
				padding: 3px 10px;
				@include transitions(400ms);
				&:hover {
					background-color: $brand-secondary;
				}
			}
		}
	}
	#lineadd-add, #lineadd-submit {
		background-color: $brand-primary;
		color: #fff;
		border: none;
		border-radius: 4px;
		padding: 5px 10px 4px;
		@include transitions(400ms);
		&:hover {
			background-color: $brand-secondary;
		}
	}
	#lineadd-add {
		margin-bottom: 5px;
	}
	#lineadd-submit {
		margin-left: 10px;
	}

	#rfq_lines_table {
		margin-top: 0;
	}
}

.account {
	.boxed-content {
		table > thead > tr > th {
			color: $pt-black;
			font-weight: 900;
			border-bottom: 1px solid $brand-secondary;
			input {
				border-radius: 5px;
			}
		}
	}
	#button_bar {
		top: -70px;
		&.rfq_buttons {
			top: -70px;
		}
		button {
			background-color: $brand-primary;
			color: #fff;
			border: none;
			border-radius: 4px;
			padding: 7px 16px;
			@include transitions(400ms);
			&:hover {
				background-color: $brand-secondary;
			}
		}
	}
	.modal-popup {
		&._inner-scroll {
			overflow-y: auto;
		}
		.modal-header, .modal-content, .modal-footer {
			padding-right: 0;
			padding-left: 0;
		}
		select {
			display: inline-block;
			color: #373737;
			padding: 9px 35px 9px 14px;
			width: 100%;
			max-width: 100%;
			margin: 0 5px 0 0;
			border: 1px solid $pt-gray-04;
			box-shadow: none;
			border-radius: 5px;
			appearance: none;
			background-color: #ffffff;
			background-image: url('../images/arrow-down-black.svg');
			background-repeat: no-repeat;
			background-position: calc(100% - 10px) center;
			background-size: 12px 8px;
			&:focus {
				border-color: $pt-black;
				-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
			}
		}
		button:not(.action-close) {
			display: inline-block;
			background-color: $brand-primary;
			border: none;
			color: #fff;
			margin-right: 4px;
			padding: 10px 18px;
			border-radius: 4px;
			@include transitions(400ms);
			&:last-child {
				margin-right: 0;
			}
		}
		#field_shipping_address1, #field_shipping_address2 {
			margin-bottom: 5px;
		}
	}

	.box-info .info-list {
		span.label {
			margin: 0;
			vertical-align: middle;
		}
	}
	.box-recent {
		.block-title {
			.action.edit {
				color: $brand-primary;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.action-buttons {
			.box-actions {
				margin-right: 5px;
				a {
					transition: all 0.15s ease-out;
					padding: 10px 10px;
					&:first-child {
						margin-bottom: 8px;
					}
				}
			}
		}
	}
	.block-dashboard-addresses {
		.action.edit {
			color: $brand-primary;
			&:hover {
				text-decoration: underline;
			}
		}
		.box-content {
			address {
				a {
					color: $brand-primary;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	.data-grid.table {
		.plus-minus {
			display: inline-block;
			width: 24px;
			padding: 2px 7px;
			background-color: $brand-primary;
			color: #fff;
			border-radius: 5px;
			font-weight: 700;
			line-height: 20px;
			text-align: center;
		}
		.totals span.price {
			font-weight: 700;
		}
	}
	.sidebar-main {
		.account-nav-content {
			.item {
				border-bottom: 1px solid $pt-gray-04;
				a {
					font-family: 'effra';
					text-transform: capitalize;
					color: #2D2926;
					font-size: 16px;
					font-weight: bold;
					&:hover {
						color: $pt-gray-09;
						text-decoration: none!important;
					}
				}
			}
		}
	}
}



#update-billing-address-form, #update-shipping-address-form, #update-contact-form {
	overflow-x: hidden;
}

.customer-account-logoutsuccess {
	.login-block.main {
		padding-right: 80px;
		padding-left: 80px;
		text-align: center;
		@include media-query(560px) {
			padding-right: 35px;
			padding-left: 35px;
		}
		p {
			margin-bottom: 0;
		}
	}
}

.b2b-portal-register {
	.trade-account-intro {
		margin-bottom: 40px;
		font-size: 16px;
	}

	/*before been able to override name.phtml*/
	.field-name-firstname, .field-name-lastname {
		position: relative;
	    min-height: 1px;
	    padding-right: 15px;
	    padding-left: 15px;
		float: left;
		width: 50%;
		@include media-query(767px){
			float: none;
			width: 100%;
		}
	}

	.label {
		color: $gray-dark;
		font-size: 14px;
		font-family: 'effra';
	}
	.label.label-pwd-reveal {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		.pwd-reveal {
			text-align: right;
			span {
				display: inline-block;
				vertical-align: middle;
				color: $brand-primary;
				&:hover {
					text-decoration: underline;
				}
			}
			&:focus {
				color: $brand-primary;
			}
			.icon-eye {
				display: inline-block;
				width: 16px;
				height: 16px;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;
			}
			.icon-eye-open {
			    background-image: url('../images/icon-eye-open-red.svg');
			}
			.icon-eye-close {
			    background-image: url('../images/icon-eye-close-red.svg');
			}
		}
	}

	.field-note {
		font-size: 13px;
	}

	.trade-account-conclusion {
		margin: 35px auto;
		max-width: 320px;
		.form-check .form-check-label {
			font-weight: 600;
			span {
				a {
					color: $brand-primary;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		.terms-note {
			font-size: 13px;
			padding-left: 32px;
		}
	}

	.trade-account-ending {
		margin-top: 25px;
		text-align: center;
		strong {
			color: $brand-primary!important;
		}
	}

	.form-create-account .actions-toolbar {
		text-align: center;
		.primary {
			text-align: center;
			float: none;
		}
	}
}

.admin__data-grid-header-row {
	.admin__filter-actions {
		display: flex;
		button {
			flex: 1;
			margin: 0 5px;
			padding: 5px 10px;
			&:hover {
				background: $brand-hover;
				outline: 2px solid #fff;
				box-shadow: 0 0 0 4px $brand-hover;
			}
			&:focus {
				background: $brand-hover;
				outline: 2px solid #fff;
				box-shadow: 0 0 0 4px $brand-hover;
			}
		}
	}
}
.admin__data-grid-pager {
	.page-control-input {
		margin-bottom: 0;
	}
}

.form-address-edit {
	.fieldset {
		.field {
			display: flex;
			flex-flow: column;
			.control {
				input {
					border-radius: 5px;
				}
			}
		}
	}

}


@include media-query(991px) {
	div.box-info div.col-count-3 {
		column-count: 2;
	}
}


//@include media-query(510px) {
@media (min-width: 768px) and (max-width: 991px), (max-width: 510px){
	.customerconnect-account-index, .customerconnect-orders-index, .customerconnect-invoices-index, .customerconnect-payments-index,
	.customerconnect-shipments-index, .customerconnect-rmas-index, .customerconnect-servicecalls-index, .customerconnect-rfqs-index, .customerconnect-rfqs-new, .customerconnect-skus-index {
		.admin__data-grid-header.admin__data-grid-toolbar {
			.admin__data-grid-pager {
				flex-basis: 100%;
				padding-left: 0;
				margin: 10px 0;
			}
		}
	}
}

@include media-query(480px) {
	div.box-info div.col-count-3 {
		column-count: 1;
	}
	.customerconnect-account-index, .customerconnect-orders-index, .customerconnect-invoices-index, .customerconnect-payments-index,
	.customerconnect-shipments-index, .customerconnect-rmas-index, .customerconnect-servicecalls-index, .customerconnect-rfqs-index, .customerconnect-rfqs-new, .customerconnect-skus-index {
		.admin__data-grid-pager {
			.page-control-input {
				min-width: 33px;
			}
		}
	}
}

.customerconnect-account-index {
	.box-account {
		.block-content {
			fieldset#erp_custom_address_allowed {
				margin-bottom: 15px;
				select {
					padding: 9px 35px 9px 14px
				}
			}
		}
	}
}

.admin__data-grid-export button {
    padding: 6px 4px;
    font-size: 14px;
}
