.block-recent-posts {
  .block-content {
    > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .item {
      position: relative;
      padding-left: 70px;
      min-height: 70px;
      width: 100%;
      flex-basis: 100%;
      @include media-query(767px) {
        display: none;
        &:nth-child(-n+3){
          display: block;
        }
      }
      @include media-query(767px, 580px) {
        width: 33.33%;
        margin-top: 15px;
        flex-basis: 33.33%;
        padding-right: 15px;
        .block-content .item-title {
          font-size: 14px;
          line-height: 17px;
        }
      }

      &:first-child {
        margin-top: 15px;
      }



      // Apply border to all elements except last one
      &:not(:last-child) {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $pt-gray-04;
      }

      .post-date {
        position: absolute;
        top: 0;
        left: 0;
        
        .inner {
          border-radius: 50%;
          text-align: center;
          width: 60px;
          height: 60px;
          padding: 5px;
          color: #fff;
          background-color: $brand-secondary;
          border: 3px solid #fff;
          font-family: 'effra';
          font-weight: bold;
        }

        .month, 
        .year {
          font-size: 10px;
          text-transform: uppercase;
        }

        .day {
          font-size: 22px;
          font-weight: 700;
          line-height: 0.85;
        }
      }

      &-title {
        display: block;
        margin: 0 0 10px 0;
        font-size: 16px;
        font-weight: 500;
        color: $pt-black;
      }

      .button {
        // Extend the button styles from the blog posts
        @extend %read-more-button;
        text-align: center;
        background: $brand-primary;
        font-family: 'effra';
        transition: all 0.15s ease-out;
        &:hover {
          background: $brand-hover!important;
          color: white;
          outline: 2px solid #fff;
          box-shadow: 0 0 0 4px $brand-hover;
          span {
              color: #fff!important;
          }
        }
        &:focus {
          background: $brand-hover!important;
          color: white;
          outline: 2px solid #fff;
          box-shadow: 0 0 0 4px $brand-hover;
          span {
              color: #fff!important;
          }
        }
      }
    }
  }
}
