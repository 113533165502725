/*IE11*/
.no-cssfilters-magic .catalog-product-view .mz-thumb:hover,
.no-cssfilters-magic .catalog-product-view .mz-thumb.mz-thumb-selected {
    background: #fff;
}

.catalog-product-view {
	#maincontent {
		padding-right: 0;
		padding-left: 0;
		margin-bottom: 60px;
		.page-title-wrapper {
			h1.page-title {
				font-size: 36px;
				margin-bottom: 50px;
				padding-bottom: 10px;
				border-bottom-width: 4px;
				@include media-query(540px) {
					font-size: 28px;
				}
			}
            @media screen and (max-width: 767px) {
                display: none;
            }
		}

        #product_details_viewed {
            .block-viewed-products-grid {
                .product-item {
                    .product-item-info {
                        strong {
                            color: $pt-black;
                        }
                    }
                }
            }
        }

	}
	.amshopby-option-link {
		display: none;
	}

	.MagicZoom, .mt-thumb-switcher {
		display: inline-block;
		margin: 0;
		padding: 0;
		height: 100%;
	}

	#mtImageContainer .mz-hint {
		bottom: 18px;
	}

	.MagicToolboxContainer {
		position: relative;
	}

	.mt-thumb-switcher {
		padding: 15% 10%;
		border: 1px solid $pt-gray-04;
		img {
			margin: 0 auto;
		}
	}

	div.MagicToolboxContainer.selectorsBottom .MagicToolboxSelectorsContainer {
		position: relative;
		padding-top: 0;
		z-index: 999;
		@include media-query(991px) {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			display: block;
			width: 100%;
			height: 40px;
			min-height: 40px;
		}
	}

	a.mt-thumb-switcher.active-selector,
	a.mt-thumb-switcher.mz-thumb:hover:not(.active-selector),
	a.mt-thumb-switcher.m360-selector:hover:not(.active-selector),
	a.mt-thumb-switcher.video-selector:hover:not(.active-selector) {
		img {
			filter: none !important;
		}
	}

	a.mt-thumb-switcher.active-selector {
		position: relative;
		border: 2px solid $brand-secondary;
		&:before, &:after {
			content: '';
			position: absolute;
			top: -2px;
			display: block;
			width: 52%;
			height: 18%;
			background-color: #fff;
			border-top: 2px solid $brand-secondary;
		}
		&:before {
			transform: rotate(-15deg);
			transform-origin: top left;
			left: 0;
		}
		&:after {
			transform: rotate(15deg);
			transform-origin: top right;
			right: 0;
		}
	}

	#mt360Container, #mtImageContainer {
		border: 1px solid $pt-gray-04;
		.Magic360, .Magic360-container {
			border: none;
		}
		.MagicToolboxMessage {
			color: $pt-black;
			padding-bottom: 15px;
		}
	}

	.MagicToolboxSelectorsContainer {
		background: url('../images/loader.gif') no-repeat center center;
		background-size: 32px 32px;
		min-height: 60px;
		.arrow {
			display: none;
		}
		&.initialized {
			background: none;
			.arrow {
				display: block;
			}
		}
		button {
			width: 22px;
			height: 40px;
			margin-top: -10px;
			@include media-query(991px) {
				margin-top: 0;
			}
			&:before {
				position: absolute;
				font-family: 'icomoon';
				left: 0;
				top: 50%;
				margin-top: -20px;
				width: 22px;
				height: 40px;
				font-size: 40px;
				color: $brand-primary;
			}
			&.slick-prev {
				left: -36px;
				@include media-query(991px) {
					left: 14px;
				}
				&:before {
					content: "\e96f";
				}
			}
			&.slick-next {
				right: -36px;
				@include media-query(991px) {
					right: 14px;
				}
				&:before {
					content: "\e970";
				}
			}
		}
	}

	.MagicToolboxSelectorsContainer {
		width: calc(100% - 82px);
		margin: 0 auto;
	}

	.magic-box-slider {
		display: none;

		width: calc(100% + 6px);
		margin-left: -4px;
		padding: 0 2px;
		overflow: hidden;
		transform: translate3d(0,-21px,0);
		&.slick-initialized {
			display: block;
		}
		@include media-query(991px) {
			&.slick-initialized {
				display: none;
			}
		}
		.slick-list {
			overflow: visible;
		}
		.slick-track {
			display: flex;
			align-items: stretch;
			justify-content: center;
			.slick-slide {
				height: auto;
			}
		}
		.slick-slide {
			margin-top: 20px;
			> div {
				height: 100%;
			}
		}
		.slick-slide + .slick-slide {
			.mt-thumb-switcher:not(.active-selector) {
				border-left: none;
			}
		}
	}

	.row-part {
		.product.media {
			width: 38.3333%;
			@include media-query(991px) {
				width: 100%;
				margin-bottom: 30px;
			}
		}
		.product-info-main {
			width: 28.3333%;
			@include media-query(991px) {
				width: 50%;
			}
			@include media-query(640px) {
				width: 100%;
				margin-bottom: 20px;
			}
		}
	}


	.product-info-main {
		.product-info-main-inner {
			background: #f1f2f4;
			padding: 30px 35px 16px;
			.listing {
				margin-bottom: 0;
				.discount {
					margin-bottom: 20px;
				}
			}
			.product.pricing {
				background-color: #fff;
				border: 1px solid #dadada;
				border-radius: 4px;
				margin-bottom: 20px;
				padding: 15px;
			}
			p.availability {
				display: none;
			}
			.responsive-br {
				display: none;
			}
			@include media-query(1199px) {
				padding: 30px 20px 16px;
			}
		}
	}

	.product-social-links {
		clear: both;
		margin: 15px 0;
		.product-addto-links {
			clear: both;
			width: 100%;
			display: inline;
			a {
				width: 100%;
				display: inline-block;
				padding: 10px;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				text-align: center;
				i {
					vertical-align: middle;
				}
				&.towishlist {
					background: $brand-secondary;
					border: 1px solid $brand-secondary;
					border-radius: 4px;
					-moz-border-radius: 4px;
					-webkit-border-radius: 4px;
					text-transform: uppercase;
					color: #fff;
					font-family: 'effra';
					font-weight: 600;
					transition: all 0.15s ease-out;
					i {
						padding-right: 8px;
						margin-top: -2px;
						color: #fff;
						font-size: 18px;
					}
					&:hover {
						outline: 2px solid #fff;
						box-shadow: 0 0 0 4px $brand-secondary;
					}
					&:focus {
						outline: 2px solid #fff;
						box-shadow: 0 0 0 4px $brand-secondary;
					}
				}
			}
		}
		.action.tocompare, .action.tocompare + .action.towishlist, .action.mailto.friend {
			display: none;
		}
	}
	.product-social-links + .availability {
		display: none;
	}
	.action-skip-wrapper {
		display: none;
	}
	.gallery-slider-thumb .slick-slide img {
		display: block;
		margin: auto;
	}
	.page-title-wrapper {
		text-align: left;
		h1.page-title {
			padding: 0 0 10px;
			text-transform: uppercase;
			color: #0051b2;
			font-weight: bold;
			letter-spacing: -1px;
			font-size: 36px;
			font-family: inherit;
			border-bottom: 4px solid $pt-black;
		}
	}
	.product-view-tabs {
		.panel-heading {
			background: none;
			border-radius: 0 !important;
			border-color: #999;
			padding: 15px;
		}
		.panel.panel-default {
			border: none;
			border-radius: 0;

		}
		.panel-default:first-child {
			border-top: 1px solid #ccc;
		}
		.panel-heading a {
			width: 100%;
			display: block;
			font-size: 15px;
			font-weight: 700;
			text-decoration: none;
			.rating {
				margin-left: 30px;
				font-size: 18px;
				vertical-align: middle;
			}
			&:focus {
				text-decoration: none;
			}
			&:hover {
				text-decoration: none;
			}
			&:active {
				text-decoration: none;
			}
		}
	}

	.product-center-column, .model-top-text {
		padding-right: 30px !important;
		@include media-query(991px) {
			width: 50%;
		}
		@include media-query(640px) {
			width: 100%;
		}
		.product-display-atrr, .display-data {
			clear: both;
			margin-top: 4px;
			&:first-child {
				margin-top: 0;
			}
			strong {
				font-size: 14px;
				color: $pt-black;
				font-family: inherit;
				font-weight: bold;
			}
			font-size: 14px;
			color: #333333;
		}

		.product-display-atrr {
			margin-top: 15px;
			strong {
				display: inline-block;
				margin-bottom: 4px;
				text-transform: capitalize;
			}
			.attr-list {
				.attr-label, .attr-value {
					display: inline-block;
				}
				.attr-label {
					font-weight: 700;
					padding-right: 20px;
					min-width: 62px;
				}
			}
			&.product-volumetrics {
				.attr-list {
					.attr-label {
						min-width: 62px;
					}
				}
			}
			&.product-hazardous {
				.attr-list {
					.attr-label {
						min-width: 114px;
					}
				}
			}
		}


		.stock-availability-container {
			margin-top: 15px;
			border-top: 1px solid $color-grey;
			border-bottom: 1px solid $color-grey;
			padding: 10px 0;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}

        .stock-availability-container .hide {
            display: none;
        }

		.product-delivery-info, .product-hazardous-container {
			clear: both;
			display: inline-block;
			width: 100%;
			color: #333;
			padding: 20px 0;
			border-bottom: 1px solid $color-grey;
			h4 {
				position: relative;
				color: $pt-black;
				font-size: 16px;
				font-family: inherit;
				font-weight: bold;
				margin-top: 0;
				padding-left: 40px;
			}
		}

		.product-delivery-info {
			h4 {
				&:before {
					content: "\e914";
					position: absolute;
					left: 0;
					top: 0;
					display: inline-block;
					font-family: 'icomoon';
					font-size: 18px;
					font-weight: normal;
					color: #333;
					padding-right: 10px;
				}
			}
			ul {
				margin: 0;
				padding: 0;
				list-style-type: none;
				li {
					width: 100%;
					clear: both;
					text-align: left;
					display: inline-block;
					position: relative;
					font-weight: 700;
					span {
						&.right-aligned {
							position: absolute;
							right: 0;
							top: 0;
							font-weight: 400;
						}
					}
				}
			}
		}

		.product-hazardous-container {
			h4 {
				img {
					position: absolute;
					left: 0;
					top: 0;
				}
			}
			ul {
				margin: 0;
				padding: 0;
				list-style-type: none;
				li {
					width: 100%;
					clear: both;
					text-align: left;
					display: inline-block;
					position: relative;
					font-weight: 400;
					font-size: 0;
					line-height: 14px;
					.list-label, .list-value {
						display: inline-block;
						vertical-align: top;
						font-size: 13px;
					}
					.list-label {
						width: 160px;
						font-weight: 700;
						margin-right: 20px;
					}
					.list-value {
						width: calc(100% - 180px);
					}
				}
			}
		}
	}

	.product-info-price {
		.price-box {
			.price-including-tax {
				clear: both;
				display: inline;
				float: left;
				position: relative;
				span {
					&.price {
						color: $pt-black;
						font-size: 20px;
						font-weight: 900;
						line-height: 25px;
						&:after {
							position: absolute;
							top: 50%;
							left: 100%;
							content: "inc VAT";
							width: 60px;
							margin-top: -10px;
							font-size: 12px;
							font-weight: 500;
							padding-left: 5px;
						}
					}
				}
			}
			.price-excluding-tax {
				clear: both;
				display: inline;
				float: left;
				position: relative;
				span {
					&.price {
						font-weight: 900;
						color: $pt-black;
						font-size: 34px;
						line-height: 25px;
						&:after {
							position: absolute;
							top: 50%;
							left: 100%;
							content: "ex VAT";
							width: 70px;
							margin-top: -8px;
							font-size: 15px;
							font-weight: 500;
							padding-left: 5px;
						}
					}
				}
			}
			.pricing-divider {
				float: left;
				display: block;
				margin: 2px 0;
				width: 100%;
				border-bottom: 2px solid $brand-secondary;
			}
		}
	}

	.prices-tier {
		background: white;
		border: 1px solid #dadada;
		border-radius: 4px;
		margin-top: 15px;
		padding: 15px 10px;
		.prices-tier-title {
			margin-bottom: 8px;
			color: $pt-black;
			font-weight: 700;
			text-transform: uppercase;
		}
		.prices-tier-list {
			padding-left: 0;
			list-style: none;
			.price-detail {
				font-size: 12px;
			}
			.price-including-tax, .price-excluding-tax {
				span.price:after {
					padding-left: 5px;
				}
			}
			.price-including-tax {
				span.price:after {
					content: "inc VAT";
				}
			}
			.price-excluding-tax {
				span.price:after {
					content: "ex VAT";
				}
			}
			.pricing-divider {
				display: inline-block;
				&:after {
					content: '/';
				}
			}
		}
	}

	.delivery-counter {
		background: white;
		border: 1px solid $pt-gray-04;
		border-radius: 4px;
		margin: 10px 0 12px;
		padding: 15px;
		text-align: center;
		p {
			margin-bottom: 7px;
			line-height: 1.1;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.intro, .outro {
			font-size: 12px;
		}
		#counterLoader {
			position: relative;
			min-height: 15px;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				backface-visibility: hidden;
				display: block;
				width: 25px;
				height: 25px;
				background: url('../images/fc_loader.gif') no-repeat center center;
				background-size: 100% 100%;
			}
		}
		.counter {
			color: $brand-primary;
			text-transform: uppercase;
			font-size: 12px;
			text-align: center;
			.count {
				font-size: 14px;
			}
		}
		.outro {
			text-align: center;
		}
	}

	.product-add-form {
		clear: both;
		display: block;
		padding-top: 20px;
	}

	.product.attribute.sku strong,
	.product.attribute.sku > div {
		display: inline-block;
		vertical-align: middle;
		font-size: 12px;
		font-weight: normal;
	}
	.product-info-stock-sku {
		margin-bottom: 10px;
	}
	.rating-summary {
		font-size: 18px;
		margin-bottom: 10px;
	}
	.swatch-attribute-selected-option {
		display: none;
	}
	.swatch-attribute-label, .swatch-attribute-options {
		display: inline-block;
		vertical-align: middle;
		min-width: 80px;
		margin-top: 0;
		font-weight: normal;
		font-size: 12px;
		line-height: normal;
		min-height: 40px;
	}
	.swatch-attribute-label {
		line-height: 40px;
	}
	.swatch-option {
		height: 40px;
		width: 40px;
		margin-top: 0;
		margin-bottom: 0;
	}
	#product-addtocart-button, #product-updatecart-button {
		border: 0;
		border-radius: 5px;
		font-weight: 700;
		background: $brand-primary;
		transition: all 0.15s ease-out;
		&:hover {
			background: $brand-hover!important;
			color: white;
			outline: 2px solid #fff;
			box-shadow: 0 0 0 4px $brand-hover;
			span {
				color: #fff!important;
			}
		}
		&:focus {
			background: $brand-hover!important;
			color: white;
			outline: 2px solid #fff;
			box-shadow: 0 0 0 4px $brand-hover;
			span {
				color: #fff!important;
			}
		}
	}

	.box-tocart {
		position: relative;
		clear: both;
		.field.qty {
			position: relative;
			border: 1px solid #e6e6e6;
			background: white;
			margin: 0;
			width: 110px;
			float: left;
			display: inline-block;
			border-radius: 4px;
			padding: 8px;
			box-sizing: border-box;
		}
		label {
			padding-right: 10px;
			margin-top: 12px;
			margin-bottom: 0;
			float: left;
			display: inline-block;
			color: $pt-black;
			text-transform: capitalize;
			font-size: 14px;
			font-weight: 600;
		}
		input {
			background-color: #fff;
			text-align: center;
			border: none;
			width: 50px;
			margin-left: 0;
		}
		button {
			margin: 0;
			padding: 0;
			width: 20px;
			height: 20px;
			background: $brand-secondary;
			font-family: inherit;
			display: inline-block;
			line-height: 20px;
			border: none;
			&.decrement {
				position: absolute;
				left: 10px;
				top: 50%;
				margin-top: -10px;
				color: white;
				font-size: 20px;
			}
			&.increment {
				position: absolute;
				right: 10px;
				top: 50%;
				margin-top: -10px;
				color: white;
				font-size: 16px;
			}

			&:hover {
				outline: none;
				background: $pt-gray-09;
				color: white;
			}
			&:active, &:focus {
				outline: none;
			}
		}
		.mage-error[generated] {
			padding-top: 6px;
		}
		.actions {
			width: 100%;
			clear: both;
			display: inline-block;
			margin-top: 20px;
			padding-top: 20px;
			border-top: 1px solid #cccccc;
			#product-addtocart-button, #product-updatecart-button {
				padding: 0 10px;
				width: 100%;
				height: 43px;
				line-height: 43px;
				font-size: 22px;
				&[disabled], &.disabled {
					&:hover, &:focus {
						cursor: not-allowed;
						* {
							cursor: not-allowed;
						}
					}
				}
			}
		}
	}

	.product-item-name .grid-wish-compare {
		display: inline-block;
		float: right;
		font-size: 24px;
		line-height: 1;
		vertical-align: middle;
		.towishlist:hover {
			i {
				color: #FF0000 !important;
			}
		}
		.tocompare:hover {
			i {
				color: #000033 !important;
			}
		}
	}
	.products-grid {
		.product-item-photo {
			display: block;
			text-align: center;
			overflow: hidden;
		}
		.price-box {
			display: block;
			font-weight: 600;
			margin-top: 5px;
		}
		.swatch-option {
			width: 20px;
			min-width: auto;
			margin: 0 5px 5px 0;
		}
	}
	#product-related-cross-tabs {
		.active {
			a {
				border-radius: 0;
			}
		}
		.tab-content {
			padding-top: 30px;
		}
		.price-box {
			font-size: 14px;
		}
	}
	.review-control-vote input[type="radio"] {
		display: none;
	}
	.review-control-vote label {
		display: inline-block;
		vertical-align: top;
		font-size: 17px;
	}
	.legend.review-legend {
		display: none;
	}

	.catalog-product-compare-index {
		.productReviews,
		.product-item-name,
		.price-box {
			margin-top: 10px;
			display: block;
			margin-bottom: 10px;

		}
		.material-icons {
			vertical-align: bottom;
		}
		td.info {
			background: #fff !important;
		}
		.cell {
			border: none !important;
			margin: 0;
		}
		.attribute.label {
			vertical-align: top;
			padding: 0;
			margin: 0;
			line-height: normal;
			height: auto;
			display: block;
		}
	}

	.product.info.detailed {
		display: block;
		float: left;
		clear: both;
		width: 100%;
		padding-top: 4px;
		margin-top: 40px;
		+ .product.info.detailed {
			.product.data.items {
				border-left: none;
				border-right: none;
				border-bottom: none;
				background: $pt-gray-04;
				border-top-right-radius: 8px;
				border-top-left-radius: 8px;
				.data.item.title {
					width: 33.33%;
					@include media-query(420px) {
						a {
							padding: 5px 8px;
							font-size: 12px;
							background: $pt-gray-04;

						}
					}
				}
				> .data.item.title.active:first-child {
					border-left: 1px solid #dddddd;
				}
				> .data.item.title.active:nth-child(5) {
					border-right: 1px solid #dddddd;
				}
			}
		}
		.data.item.content[aria-hidden="true"] {
			position: absolute;
			display: block !important;
			height: 0;
			margin: 0 !important;
			padding: 0 !important;
			overflow: hidden;
		}
		.data.item.content[aria-hidden="false"] {
			position: static;
			display: block !important;
			height: auto;
		}
		.product.data.items {
			position: relative;
			margin-left: 0;
			margin-right: 0;
			border-left: 1px solid #dddddd;
			border-right: 1px solid #dddddd;
			border-bottom: 1px solid #dddddd;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;
			background: $pt-gray-04;
			@include media-query(640px) {
				background: transparent;
			}
			&:before {
				content: "";
				display: table;
			}
			&:after {
				content: "";
				display: table;
				clear: both;
			}
			.data {
				&.title {
					float: left;
					width: auto;
					background: #ffffff;
					color: #333333;
					display: table;
					width: 25%;
					@include media-query(640px) {
						width: 33.3%;
					}
					&:first-child, &.active {
						a:after {
							content: none;
						}
					}
					&:first-child {
						&.active {
							a {
								border-top-left-radius: 8px;
							}
						}
						a {
							border-top-left-radius: 8px;
						}
					}
					a {
						position: relative;
						display: table-cell;
						vertical-align: middle;
						height: 70px;
						line-height: 1;
						font-size: 16px;
						text-align: center;
						text-transform: uppercase;
						color: $pt-black;
						text-decoration: none;
						background: $pt-gray-04;
						padding: 5px 30px;
						border: none;
						font-weight: bold;
						font-family: 'effra';
						@include media-query(640px) {
							padding: 5px 15px;
							font-size: 14px;
						}
						&:hover {
							background: $pt-gray-09;
							color: #fff;
						}
						&:after {
							content: '';
							position: absolute;
							top: 50%;
							right: 99.9%;
							transform: translateY(-50%);
							display: block;
							height: 40px;
							max-height: calc(100% - 20px);
							width: 1px;
							background-color: #676D6D;
						}
					}
					&.active {
						a {
							background: $brand-secondary;
							color: #fff;
							&:hover {
								cursor: default;
							}
							&:before {
								content: '';
							}
						}
					}
					&:first-child {
						border-top-left-radius: 8px;
						@media screen and (max-width: 767px) {
							border-top-left-radius: 0;
						}
						a:before {
							left: -1px;
							width: calc(100% + 1px);
						}
					}
				}
				&.content {
					background: #ffffff;
					margin-top: 70px;
					padding: 35px 35px 35px 35px;
					box-sizing: border-box;
					float: right;
					margin-left: -100%;
					width: 100%;
					display: block;
					border: none;
					#product-faqs-container {
						p {
							a {
								color: $brand-primary;
								&:hover {
									text-decoration: underline;
								}
							}
						}
					}
				}
				&#product_details_related, &#product_details_associated, &#product_details_viewed {
					padding-left: 0;
					padding-right: 0;
					.product-items.row {
						.slick-list {
							&:before, &:after {
								content: '';
								position: absolute;
								top: 0;
								display: block;
								width: 15px;
								height: 100%;
								background-color: #fff;
								z-index: 99;
							}
							&:before {
								left: 0;
							}
							&:after {
								right: 0;
							}
						}
					}
				}
			}
		}
		.product-items-documents {
			margin-bottom: 0;
			border-bottom: none;
			.product-item-document {
				margin-bottom: 15px;
				@include media-query(540px) {
					padding: 15px;
				}
			}
			.product-item-name {
				margin-bottom: 0;
				a {
					color: #333;
				}
			}
			.document-section {
				text-align: right;
				@include media-query(540px) {
					margin-top: 5px;
					text-align: left;
				}
				.button-document-view {
					display: inline-block;
					text-align: center;
				}
			}
		}

		.product-items {
			.slick-arrow {
				&.slick-prev {
					left: -110px;
					@include media-query(1400px) {
						left: -40px;
					}
					@include media-query(1230px) {
						left: 10px;
					}
					@include media-query(992px, 541px) {
						left: calc(50% - 38px);
					}
				}
				&.slick-next {
					right: -110px;
					@include media-query(1400px) {
						right: -40px;
					}
					@include media-query(1230px) {
						right: 10px;
					}
					@include media-query(992px, 541px) {
						right: calc(50% - 38px);
					}
				}
			}
		}
		.block.related, .block.upsell, .block-viewed-products-grid {
			.product-items {
				@include media-query(1230px, 993px) {
					padding: 0 70px;
				}
				@include media-query(540px) {
					padding: 0 40px;
				}
			}
		}

	}

	.column.main {
		.product.info.detailed {
			@include media-query(767px) {
				border-bottom: 2px solid $brand-primary;
                padding-left: 0;
                padding-top: 0 !important;
				.product.data.items {
					background: transparent;
					border: none;
					.data {
						&.title {
							float: none;
							width: 100%;
							a {
								display: block;
								height: auto;
								padding: 10px 30px 10px 0;
								color: $brand-primary;
								background: #fff url('../images/arrow-down-blue.svg') no-repeat right center;
								background-size: 20px 10px;
								font-size: 15px;
								text-align: left;
								border-top: 2px solid $brand-primary;
								br {
									display: none;
								}
								&:after {
									content: none;
								}
							}
							&.active {
								a {
									background: #fff url('../images/arrow-up-blue.svg') no-repeat right center;
									background-size: 20px 10px;
                                    @media screen and (max-width: 767px) {
                                        background: #fff url('../images/arrow-up-blue.svg') no-repeat right top 10px;
                                        background-size: 20px 10px;
                                    }

									&:before {
										content: none;
									}
								}
							}
						}
						&.content {
							float: none;
							width: 100%;
							margin-top: 10px;
							margin-bottom: 20px;
							margin-left: 0;
							padding: 0;
						}
					}
				}
				+ .product.info.detailed {
					.product.data.items {
						> .data.item {
							&.title {
								width: 100%;
								&.active:first-child {
									border: none;
								}
							}
						}
					}
				}
			}
		}
	}

	.additional-attributes-wrapper {
		.attr-tr {
			&.even {
				background: #f1f2f4;
			}
			&.odd {
				background: white;
			}
			.attr-col {
				display: inline-block;
				vertical-align: middle;
				width: calc(50% - 2px);
				padding: 8px;
				font-size: 0;
				p {
					line-height: 1.42857;
					font-size: 16px;
					margin-bottom: 0;
					@include media-query(767px) {
						font-size: 14px;
					}
				}
			}
			.attr-label {
				color: $pt-black;
				font-weight: 900;
			}
		}
	}

	#product-faqs-container {
		.faqs-item {
			padding: 10px;
			clear: both;
			width: 100%;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			&.even {
				background: #f7f7f7;
			}
			&.odd {
				background: white;
			}
		}
	}

	#product-related-cross-tabs {
		clear: both;
		margin: 20px 0 0 0;
		display: inline-block;
	}

	.breadcrumbs-container {
		background: #ffffff;
		width: 100%;
		color: #2f2f31;
		text-transform: capitalize;
		font-size: 12px;
		.breadcrumbs {
			padding: 12px 0;
			margin-bottom: 0;
			list-style: none;
			background-color: transparent;
			ul.items {
				margin-top: 0;
				margin-bottom: 0;
				padding-left: 0;
				a {
					color: $brand-primary;
					text-decoration: none;
					font-size: 14px;
					&:hover {
						text-decoration: underline;
						outline: 0;
					}
					&:active {
						text-decoration: none;
						outline: 0;
					}
					&:focus {
						text-decoration: none;
						outline: 0;
					}
				}

				> li {
					display: inline-block;
					position: relative;
					margin-right: 30px;
					padding-left: 10px;
					padding-top: 2px;
					font-size: 14px;
					+ li:before {
						position: absolute;
						font-family: 'icomoon';
						left: -15px;
						top: 0;
						content: "/";
						color: $pt-black;
						font-size: 14px;
						font-weight: bold;
					}
				}

				> .active {
					color: $pt-black;
				}
			}
		}
	}

	#product-attribute-specs-table {
		tbody {
			tr {
				th, td {
					border-top: none;
					vertical-align: middle;
					font-size: 16px;
				}
				th {
					font-weight: bold;
				}
				&.even {
					background: #f7f7f7;
				}
				&.odd {
					background: white;
				}
			}
		}
	}

	.social-items-container {
		border-top: 1px solid #cccccc;
		margin-top: 20px;
		padding-top: 20px;
		p {
			float: left;
			display: inline;
			color: $pt-black;
			text-transform: capitalize;
			font-size: 14px;
			font-weight: bold;
			padding-right: 15px;
			padding-top: 6px;
			font-family: 'effra';
		}
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			li {
				display: inline-block;
				padding: 5px;
				border-radius: 100%;
				-moz-border-radius: 100%;
				-webkit-border-radius: 100%;
				width: 28px;
				height: 28px;
				position: relative;
				opacity: 1;
				a {
					display: block;
					text-decoration: none;
					position: absolute;
					left: 50%;
					top: 3px;
					font-size: 16px;
					margin-left: -6px;
					color: white;
				}
				&.facebook {
					background: $brand-secondary;
					a {
						margin-left: -5px;
					}
				}
				&.twitter {
					background: $brand-secondary;
					a {
						margin-left: -7px;
					}
				}
				&.linkedin {
					background: $brand-secondary;
					a {
						margin-left: -7px;
					}
				}
				&.pinterest {
					background: $brand-secondary;
					a {
						margin-left: -6px;
					}
				}
				&.whatsapp {
					background: $brand-secondary;
					a {
						margin-left: -8px;
					}
				}
				&.email {
					background: $brand-secondary;
					a {
						margin-left: -8px;
					}
				}
				&:hover {
					background: $pt-gray-09;
				}
				&:active {
					opacity: 0.5;
				}
				&:focus {
					opacity: 0.5;
				}
			}
		}
	}

	.slider-related, .slider-upsell, .slider-viewed {
		.slick-track {
			margin-left: 0;
		}
	}

	.block.related-products {
		clear:both;
		display: block;
		float:left;
		margin-top:40px;
	}
	&.catalog-product-view-model {
        #maincontent {

            .part-category{
                .chosen-choices {
                    li.search-field {
                        input[type="text"]{
                            font-family: proxima-nova, sans-serif;
                            color: #373737 !important;
                        }
                    }
                }
            }

            .main {
                @media screen and (min-width: 992px) and (max-width: 1199px) {
                    padding-left: 50px;
                }
            }

            .chosen-drop {
                background-color: #f1f2f4;
                border: 1px solid #aaa;
                border-radius: 5px;
                width: calc(100% + 20px);

                .highlighted {
                    background-color: $brand-primary;
                    background-image: none;
                }
            }

            .page-title-wrapper {
                padding-right: 0;

                h1 {
                    &.page-title {
                        padding-bottom: 1px;
                        border-bottom-width: 6px;
                        margin-bottom: 40px;

                        .base {
                            font-size: 45px;
                        }
                    }
                }
            }

            .filter_wrapper {
                margin-top: -20px;

                .header {
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 24px;
                    padding-bottom: 0;
                    color: #0052b2;

                    h2 {
                        font-size: 24px;
                        font-weight: bold;
						padding-bottom: 8px;
						margin: 0 0 20px;
						border-bottom: 2px solid $brand-secondary;
						color: $pt-black;
						font-size: 24px;
						font-weight: 700;
						text-transform: capitalize;
						font-family: 'effra';
						font-weight: bold;
                    }
                }

                .equipment-filter {
                    margin-top: 22px;
                    margin-left: 0;

                    h4 {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 14px;
                        color: #0d5ab5;
                    }
                }

                li.search-field {
                    position: relative;
                    width: 100%;
                    background-color: #fff;
                    margin-top: 5px;
                    border-radius: 5px;
                    padding: 3px 10px;
					border: 1px solid $pt-gray-04;
					box-shadow: none;
					border-radius: 5px;
					margin-right: 0;
					padding-right: 45px;
					width: 100%;
					float: left;
					height: 40px;
					line-height: 40px;
					background: #ffffff;
					-moz-border-radius: 0;
					text-shadow: none;
					margin-bottom: 10px;
					color: $pt-black;
					&:focus {
						border-color: $pt-black;
						-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
						-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
						box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
					}
                }

                .chosen-choices {
                    background: none;
                    border: none;

                    li {
                        &.search-choice {
                            display: inline-block;
                            margin-top: 0;
                            padding: 4px 8px 4px 24px;
                            background-color: $brand-primary;
                            background-image: none;
                            color: #fff;
                            border: 1px solid $brand-primary;
                            border-radius: 4px;
                            box-shadow: none;
                            text-transform: uppercase;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 1;
                            transition: background-color 400ms linear;

                            .search-choice-close {
                                position: absolute;
                                top: 45%;
                                left: 6px;
                                transform: translateY(-50%);
                                background: none;
                                background-image: none !important;
                                color: #fff;
                                width: calc(100% - 6px);

                                &::before {
                                    content: "\f057";
                                    font-family: "FontAwesome";
                                    font-size: 14px;
                                    font-weight: 400;
                                    transition: background-color 400ms linear;
                                }
                            }
                        }
                    }

                    .search-field {
                        &:before {
                            position: absolute;
                            font-family: 'icomoon';
                            content: "\e912";
                            width: 20px;
                            height: 20px;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);
                            font-size: 20px;
                            line-height: 20px;
                            color: #373737;
                        }
                    }
                }

                .part-category {
                    margin-top: 27px;
                    margin-left: 0;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #eaecea;
                    margin-bottom: 25px;

                    h4 {
                        text-transform: capitalize;
                        font-weight: 600;
                        font-size: 16px;
                        color: $pt-black;
						font-family: 'effra';
                    }
                }

                .chosen-container-active .chosen-choices {
                    box-shadow: none;
                }

                .wrapper-parts {
                    min-height: 60px;
                    background: #f1f2f4;



                    #part_category_chosen,
                    #equipment_type_chosen {
                        width: 265px !important;
                        border: none;
                        padding-right: 35px;
                        color: #373737;
                        padding: 10px 38px 9px 10px;
                        border-radius: 5px;
                        box-shadow: none;
                        background-color: #ffffff;
                        background-image: url(../images/arrow-down-black.svg);
                        background-repeat: no-repeat;
                        background-position: calc(100% - 10px) 18px;
                        background-size: 20px 10px;
                        padding-right: 35px;

                        @media screen and (max-width: 767px) {
                            width: 100% !important;
                        }

                        .chosen-single {
                            height: 35px;
                            background: white;
                            border-color: gainsboro;
                            box-shadow: none;
                            color: black;
                            padding-top: 5px;
                            margin-top: 5px;
                            margin-left: 1px;
                            padding-left: 11px;

                            span {
                                font-size: 14px;
                            }

                            &:before {
                                position: absolute;
                                font-family: 'icomoon';
                                content: "\e912";
                                width: 20px;
                                height: 20px;
                                top: 50%;
                                right: 10px;
                                transform: translateY(-50%);
                                font-size: 20px;
                                line-height: 20px;
                                color: #373737;
                            }

                            b {
                                display: none;
                            }
                        }
                    }

                    #part_category_chosen {
                        @media screen and (max-width: 768px) {
                            background-position: calc(100% - 10px) 28px;
                        }
                    }
                }


            }


            .search-block-outter {
                .search-block {
                    @media screen and (max-width: 767px) {
                        margin-top: 0;
                        margin-bottom: 15px;
                    }
                }
                .search-tooltip {
                    @media screen and (max-width: 767px) {
                        top: 30px;
                    }
                }
            }

            .equipment-filter {
                margin-top: 22px;
                margin-left: 0;
                padding-bottom: 25px;
                border-bottom: 1px solid #eaecea;

                .wrapper-parts {
                    height: 45px;
                    min-height: 45px;
                    border: 1px solid #f1f2f4;
                    border-radius: 5px;

                    #equipment_type_chosen {
                        background-position: calc(100% - 10px) 18px;
                        padding: 0;

                        .chosen-single {
                            background: none;
                            border: none;

                            &:before {
                                content: '';
                                display: none;
                            }
                        }
                    }
                }
            }

            .row-model {

                @media screen and (max-width: 767px) {
                    display: block;
                    margin: 0 auto;
                }

                .model-top-text {
                    padding-left: 10px;

                    @media screen and (min-width: 992px) and (max-width: 1199px) {
                        width: 43%;
                    }
                }

                .row-bottom {
                    margin-left: 0;
                    background: none;
                    margin-top: 0;
                    padding: 0;

                    @media screen and (max-width: 767px) {
                        margin-top: 10px;
                    }
                    .row-bottom-wrapper {
                        width: 93%;
                        margin-left: 5px;
                        margin-top: -5px;
                        padding-top: 30px;
                        padding-bottom: 25px;
                        background-color: #f1f2f4;

                        @media screen and (max-width: 767px) {
                            width: 100%;
                            margin-left: 0;
                        }

                        .button-wrapper {
                            padding-right: 10px;

                            @media screen and (min-width: 0px) and (max-width: 992px) {
                                padding-left: 0;
                                padding-right: 0;
                            }

                            .product-addto-links {
                                .action {
                                    &.towishlist {
                                        margin-left: -7px;
                                    }
                                }
                            }
                        }

                        .social-items-container {
                            display: flex;
                            padding-right: 0;
                            margin-top: 32px;
                            margin-bottom: 5px;

                            p {
                                margin-left: 10px;
                                padding-right: 20px;
                            }

                            ul {
                                margin-top: 2px;
                                margin-left: -1px;
                            }
                        }
                    }
                }
            }

            .product {
                &.info {
                    &.detailed {
                        padding-left: 15px;
                        margin-top: 33px;

                        @media screen and (max-width: 767px) {
                            padding-right: 0;
                            padding-left: 0;
                        }

                        .product {
                            &.data {
                                &.items {

                                    #tab-label-product_parts {
                                        .data {
                                            position: relative;
                                            width: 21%;
                                            text-align: center;
                                            padding-left: 0;
                                            padding-right: 65px;

                                            @media screen and (max-width: 767px) {
                                                padding-right: 15px;
                                            }
											&:hover {
												background: $pt-gray-06;
												color: $pt-black;
												.total-products {
													color: $pt-black;
												}
											}
                                            .total-products {
                                                position: absolute;
                                                top: 10px;
                                                right: 42%;
                                                border: none;
                                                height: auto;
                                                width: auto;
                                                padding-top: 15px;
                                                font-size: 16px;
                                                border-radius: 30px;
                                                background: none;
												color: $pt-black;
                                                float: right;
												&:before {
													content: '(';
												}
												&:after {
													content: ')';
												}
                                                @media screen and (min-width: 0px) and (max-width: 1024px) {
                                                    right: 30%;
                                                }

                                                @media screen and (max-width: 767px) {
                                                    display: none;
                                                }
                                            }

                                            &.switch {
                                                @media screen and (max-width: 767px) {
                                                    width: 100%;
                                                }
                                            }
                                        }
										&.active {
											.data {
												.total-products {
													color: #fff;
												}
											}
										}
                                    }
									#tab-label-relateddoc {
										border-top-right-radius: 8px;
										a.data {
											border-top-right-radius: 8px;
										}
									}

                                    .data {
                                        &.item {
                                            &.title {
                                                width: 50%;

                                                @media screen and (max-width: 767px) {
                                                    width: 100%;
                                                }

                                                a {
                                                    height: 65px;
                                                    @media screen and (max-width: 767px) {
                                                        height: auto;
                                                    }
                                                }
                                            }

                                            &.content {
                                                margin-top: 70px;

                                                @media screen and (max-width: 767px) {
                                                    margin-top: 10px;
                                                }

                                                .toolbar-products {
                                                    background: #f1f2f4;


                                                    .toolbar-sorter {
                                                        margin-right: 2px;
                                                    }

                                                    .select-field {
                                                        width: 36.8%;

                                                        @media screen and (min-width: 0px) and (max-width: 1199px) {
                                                            width: 70%;
                                                        }

                                                        @media screen and (max-width: 767px) {
                                                            width: 87%;
                                                        }
                                                    }

                                                    .display-mode {
                                                        padding-left: 21px;
                                                        margin-top: -5px;

                                                        @media screen and (max-width: 767px) {
                                                            display: none;
                                                        }

                                                        .modes {
                                                            .mode-grid {
                                                                background: url('../images/grid-icon.svg') no-repeat;
                                                                height: 18px;
                                                                width: 18px;
                                                                border: none;
                                                                border-radius: 0;

                                                                &.active {
                                                                    background: url('../images/grid-icon-active.svg') no-repeat;
                                                                }
                                                            }
                                                            .mode-list {
                                                                background: url('../images/list-icon.svg') no-repeat;
                                                                height: 18px;
                                                                width: 18px;
                                                                border: none;
                                                                border-radius: 0;
                                                                margin-left: 11px;

                                                                &.active {
                                                                    background: url('../images/list-icon-active.svg') no-repeat;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #product-relation-container {
                .pager {
                    display: flex;

                    .toolbar-amount {
                        text-align: left;
                        max-width: 120px;
                    }

                    .pages {
                        width: 100%;
                        position: relative;
                        top: -6px;
                        //left: 100px;
                        display: flex;
                        justify-content: flex-end;

                        @media screen and (min-width: 1199px) {
                            width: 100%;
                        }

                        @media screen and (min-width: 0px) and (max-width: 1024px) {
                            left: 0;
                        }
                    }

                    .limiter {
                        display: none;
                    }
                }

                .products {
                    &.list {
                        padding-top: 15px;

                        @media screen and (max-width: 767px) {
                            padding-left: 15px;
                            padding-right: 15px;
                        }

                        .product-item {
                            width: 100%;

                            @media screen and (max-width: 767px) {
                                margin: 0;
                                padding: 0;
                                max-width: none;
                            }

                            .product-item-info {
                                display: flex;

                                @media screen and (max-width: 767px) {
                                    flex-direction: column;
                                }

                                .product-image-container {
                                    max-width: 255px;
                                    max-height: 267px;
                                    height: 240px;
                                    margin-top: 10px;

                                    @media screen and (max-width: 767px) {
                                        max-width: none;
                                        max-height: none;
                                        height: auto;

                                    }
                                }

                                .product-item-details {
                                    width: 80%;
                                    margin-left: 30px;
                                    padding-right: 20px;

                                    @media screen and (max-width: 767px) {
                                        width: 100%;
                                        margin-left: 0;
                                        padding-right: 0;
                                    }

                                    .product-item-name {
                                        padding-bottom: 15px;
                                    }

                                    .product-item-attributes {
                                        margin-top: 0;
                                        border-bottom: none;

                                        .product-item-info {
                                            flex-direction: column;
                                        }
                                    }
                                }

                                .product-item-info-secondary {
                                    width: 72%;

                                    .stock-availability-container {
                                        display: block;
                                    }

                                    @media screen and (max-width: 767px) {
                                        width: 100%;

                                        padding-bottom: 30px;
                                        border-bottom: 1px solid #eee;
                                        margin-bottom: 10px;
                                    }

                                    .stock {
                                        margin-top: -2px;

                                        &.available {
                                            position: relative;

                                            &:before {
                                                content: '';
                                                width: 20px;
                                                height: 20px;
                                                border: 1px solid #49b440;
                                                display: inline-block;
                                                background: #49b440;
                                                border-radius: 10px;
                                            }

                                            &:after {
                                                content: '✔';
                                                position: absolute;
                                                left: 0;
                                                top: -1px;
                                                width: 20px;
                                                height: 20px;
                                                text-align: center;
                                                color: white;
                                                font-size: 17px;
                                            }

                                            span {
                                                color: #49b440;
                                                display: inline-table;
                                                font-weight: bold;
                                                text-transform: uppercase;
                                                position: relative;
                                                top: -6px;
                                                left: 5px;
                                            }

                                            .stock-qty {
                                                color: black;
                                                margin-left: 10px;
                                            }
                                        }

                                        &.unavailable {
                                            position: relative;

                                            &:before {
                                                content: '';
                                                width: 20px;
                                                height: 20px;
                                                border: 1px solid red;
                                                display: inline-block;
                                                background: red;
                                                border-radius: 10px;
                                            }

                                            &:after {
                                                content: 'x';
                                                position: absolute;
                                                left: 0;
                                                top: -4px;
                                                width: 20px;
                                                height: 20px;
                                                text-align: center;
                                                color: white;
                                                font-size: 18px;
                                                font-weight: bold;
                                            }

                                            span {
                                                color: red;
                                                display: inline-table;
                                                font-weight: bold;
                                                text-transform: uppercase;
                                                position: relative;
                                                top: -6px;
                                                left: 5px;
                                            }
                                        }
                                    }

                                    .price-box {
                                        margin-top: 28px;
                                    }

                                    .product-item-actions {
                                        /*display: flex;*/

                                        form {
                                            display: flex;
											flex-wrap: wrap;
                                            .qty-selector {
                                                max-width: 110px;
                                                display: flex;
                                                padding-left: 10px;
                                                padding-right: 10px;
                                                background: #f1f2f4;
                                                border: 1px solid #e6e6e6;
                                                border-radius: 5px;
                                                height: 30px;

                                                .update-cart-item-qty-minus {
                                                    position: relative;
                                                    background: #0052b2;
                                                    border: none;
                                                    color: white;
                                                    font-weight: bold;
                                                    height: 20px;
                                                    width: 20px;
                                                    margin-top: 4px;

                                                    span {
                                                        position: relative;
                                                        top: -6px;
                                                    }
                                                }

                                                .input-text {
                                                    &.qty {
                                                        text-align: center;
                                                        margin-right: 0;
                                                        padding-right: 0;
                                                        position: relative;
                                                        left: 3px;
                                                        height: 30px;
                                                    }
                                                }

                                                .update-cart-item-qty-plus {
                                                    position: relative;
                                                    background: #0052b2;
                                                    border: none;
                                                    color: white;
                                                    font-weight: bold;
                                                    height: 20px;
                                                    width: 20px;
                                                    margin-top: 4px;
                                                }
                                            }

                                            .actions-primary {
                                                margin-left: 13px;
                                                height: 20px;

                                                button {
                                                    height: 33px;
                                                    width: 135px;
                                                    padding-left: 0;
                                                    padding-right: 0;
                                                }
												.towishlist {
													flex: 0 0 auto;
													padding-top: 8px;
													padding-bottom: 8px;
												}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    /** It displays the adds the actions
                    &.grid {
                        &.product-items {
                            .stock {
                                display: none;
                            }
                            .product-item-actions {
                                display: none;
                            }
                        }
                    }
                    */
                }
            }

            .recently-viewed {
                display: flex;
                position: relative;
                width: 100%;
                top: -62px;
                padding-left: 14px;
                float: none;

                @media screen and (max-width: 767px) {
                    top: 0;
                    padding-left: 0;
                }

                .recently-viewed-heading {
                    display: block;
                    background: $brand-secondary;
                    width: 100%;
					border-radius: 5px 5px 0 0;
                    h1 {
                        text-align: center;
                        color: white;
                        font-size: 19px;
                        font-weight: bold;
						font-family: 'effra';
                        height: 44px;
                        padding-right: 1px;
                        padding-top: 21px;
						border-bottom: none;
                    }



                    .recently-viewed-heading__wrapper {
                        background: white;
                        padding-top: 20px;

                        @media screen and (max-width: 767px) {
                            padding: 20px 15px 0 15px;
                        }

                        .slick-slider {
                            .slick-arrow {

                                &.slick-prev {
                                    @media screen and (max-width: 767px) {
                                        left: -26px;
                                    }
                                }

                                &.slick-next {
                                    @media screen and (max-width: 767px) {
                                        right: -26px;
                                    }
                                }



                            }
                        }

                        .slick-track {
                            width: 100% !important;
                        }

                        .slick-slide {
                            margin-right: 0px;
                            width: 31.8% !important;

                            @media screen and (max-width: 767px) {
                                margin-right: 0;
                            }

                            &:nth-child(4n) {
                                margin-right: 0;
                            }
                        }

                        .product-item {

                            .product-item-info {

                                strong {
                                    color:  $pt-black;
                                }

                                .product-item-photo {
                                    border: 1px solid #eaeaea;

                                    .product-image-container {
                                        position: relative;
                                        overflow: hidden;
                                        width: calc(100% - 2px);

                                        .product-image-photo {
                                            position: relative;
                                            display: inline-block;
                                            width: auto;
                                            height: 100%;
                                            max-width: 100%;
                                            max-height: 244px;
                                            -o-object-fit: contain;
                                            object-fit: contain;
                                            font-family: 'object-fit: contain;';
                                            margin: 0 auto;
                                            z-index: 4;
                                        }
                                    }
                                }

                                .product-item-details {
                                    display: flex;
                                    flex-direction: column;

                                    .product-item-name {
                                        text-transform: uppercase;
                                        color: #333333;
                                        font-size: 20px;
                                        line-height: 1.15;
                                        font-family: inherit;
                                        font-weight: 100;
                                        display: block;
                                        padding: 6px 0;
										min-height: 70px;
                                        a {
                                            letter-spacing: 0px;
                                        }
                                    }

                                    .label {
                                        display: block;
                                        color: #0051b2;
                                        font-weight: bold;
                                        margin: 0;
                                        font-size: 14px;
                                    }

                                    .product-item-manufacturer {
                                        margin-top: 10px;
                                        font-size: 14px;
                                        line-height: 14px;
                                        margin-bottom: 1px;
                                    }

                                    .product-item-part-number {
                                        font-size: 14px;
                                        line-height: 11px;
                                        margin-bottom: 2px;
                                    }

                                    .product-item-sku {
                                        font-size: 14px;
                                        line-height: 11px;
                                        margin-bottom: 15px;
                                        border-bottom: 1px solid #f0f0f0;
                                        padding-bottom: 16px;
                                    }

                                    .product-item-stock {
                                        display: flex;
                                        .label {
                                            display: flex;
                                            color: #4bb441;
                                            font-size: 14px;

                                            .icon {
                                                position: relative;
                                                width: 15px;
                                                height: 12px;
                                                top: -2px;
                                                margin-right: 4px;

                                                &:before {
                                                    font-family: 'FontAwesome';
                                                    position: absolute;
                                                    left: 1px;
                                                    top: -3px;
                                                    color: #4bb441;
                                                    content: "\f058";
                                                    font-size: 16px;
                                                    font-weight: 600;
                                                    width: 15px;
                                                }

                                                &.stocked {
                                                    &:before {
                                                        font-family: 'icomoon';
                                                        content: "\e914";
                                                        font-size: 11px;
                                                        font-weight: 600;
                                                        color: #333333;
                                                        left: 0;
                                                        top: -1px;
                                                    }

                                                }
                                            }

                                            .label__label {
                                                margin-top: -4px;
                                            }
                                        }

                                        .value {
                                            position: relative;
                                            top: -7px;
                                            left: 5px;
                                            font-size: 14px;
                                            font-weight: 700;
                                            display: inline;
                                            font-family: inherit;
                                        }
                                    }

                                    .price-box {
                                        padding-top: 16px;
                                        margin-top: 0;

                                        .price-container {
                                            display: flex;
                                            flex-direction: column;

                                            .price-wrapper {
                                                line-height: normal;

                                                &.ex-vat {
                                                    font-size: 30px;
                                                    border-bottom: 1px solid black;
                                                    height: 32px;

                                                    .price {
                                                        position: relative;
                                                        color: #333333;

                                                        &:after {
                                                            content: "ex VAT";
                                                            position: absolute;
                                                            left: 104%;
                                                            top: 12px;
                                                            font-size: 15px;
                                                            width: 60px;
                                                            font-weight: 100;
                                                        }
                                                    }



                                                }

                                                &.inc-vat {
                                                    font-size: 15px;

                                                    .price {
                                                        position: relative;
                                                        color: #333333;

                                                        &:after {
                                                            content: "inc VAT";
                                                            position: absolute;
                                                            left: 107%;
                                                            top: 3px;
                                                            font-size: 12px;
                                                            width: 45px;
                                                            font-weight: 500;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .page-title-wrapper-mobile {
            display: none;
            @media screen and (max-width: 767px) {
                display: block !important;
            }
        }
        @include media-query(539px) {
            .page-title-wrapper-mobile {
                display: block;
            }
        }

        .row-top, .row-bottom {
			padding-left: 30px;
			@include media-query(539px) {
				padding-left: 0;
			}
		}
		.row-bottom {
			margin-top: 50px;
			margin-left: 30px;
			padding: 30px 0;
			background-color: #f1f2f4;
			&:after {
				content: '';
				display: table;
				clear: both;
			}
			.product-social-links {
				margin-top: 0;
			}
			@include media-query(539px) {
				margin-left: 0;
			}
            @media screen and (min-width: 768px) and (max-width: 1199px) {
                width: 50%;
                position: relative;
                right: -50px;
            }
		}
		.product-info-main {
			.product-info-main-inner {
				background: none;
				padding: 0;
			}
		}
		.product-center-column .product-display-atrr {
			margin-top: 25px;
		}
		.product-addto-links a.towishlist {
			margin-left: 20px;
			@include media-query(991px) {
				margin-left: 0;
				margin-bottom: 10px;
			}
		}
		.social-items-container {
			border: none;
			margin-top: 0;
			margin-top: 5px;
			padding-top: 0;
			padding-right: 20px;
			text-align: right;
			@include media-query(991px) {
				padding-right: 0;
				text-align: center;
			}
			p, ul {
				display: inline-block;
				vertical-align: middle;
			}
			p {
				float: none;
			}
		}
		.product.info.detailed {
			margin-top: 60px;
			.product.data.items {
				border-left: none;
				border-right: none;
				border-bottom: none;
				height: 65px;
				.data.item {
					padding-left: 0;
					padding-right: 0;
				}
				.data.title {
					width: 33.33%;
				}
                @media screen and (max-width: 767px) {
                    height: auto;
                }
			}
			.product-item-product {
                margin-bottom: 55px;
				padding-top: 20px;
				border-top: 1px solid #C2C4C4;
				@include media-query(539px) {
					margin: 0 auto 30px;
					max-width: 244px;
				}
			}
		}
	}

	#product_details_viewed {
		.product-item {
			.product-image-photo {
				border: 1px solid #eaeaea;
				width: calc(100% - 2px);
			}
			.product-item-name {
				height: auto;
				min-height: 70px;
			}
		}
	}

    &.catalog-product-view-model {

    }
}
.mobile-bottom-actions {
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
    }
}
@media screen and (max-width: 767px) {
    .search-desktop-container {
        display: none;
    }
	.catalog-product-view {
		.column.main {
			.product.info.detailed {
				border-bottom: 2px solid $pt-black;
				.product.data.items {
					.data.title {
						&:first-child {
							a {
								border-top-left-radius: 0;
							}
						}
						a {
							border-top-left-radius: 0;
							color: $pt-black;
							border-top: 2px solid $pt-black;
						}
						&.active {
							a {
								border-top-left-radius: 0;
							}
						}
					}
				}
			}
		}
	}
	.catalog-product-view .product.info.detailed + .product.info.detailed .product.data.items {
		background: #fff;
	}
	.catalog-product-view .product.info.detailed + .product.info.detailed .product.data.items .data.item.title a {
		padding: 10px 0;
		font-size: 15px;
		background: #fff;
	}
}

.view-page-slider-actions .product-item-actions {
    display: none;
}
a[data-zoom-id] img, .mz-thumb img {box-shadow: none!important;}
.mt-thumb-switcher.m360-selector img, .mt-thumb-switcher.video-selector img {box-shadow: none!important;}

.catalog-product-view-model {
	.page-wrapper {
		.colum.main {
			.product.info.detailed {
				.product.data.items {
					.data.item.title {
						&:last-child {
							border-top-right-radius: 8px;
						}
					}
				}
			}
		}
	}
}
