.checkout-container,
.checkout-onepage-success {
    .calltoaction-container {
        border-bottom: 2px solid #eaeaea;
    }
}

.checkout-container {
    margin: 0 0 20px;
    position: relative;

    #checkout-loader {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: table;
        width: 120%;
        height: 120%;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 99999;

        .loader {
            text-align: center;
        }

        .loader img {
            display: inline-block;
            width: 35px;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.checkout-onepage-success {
    #maincontent {
        padding-right: 0;
        padding-left: 0;
        margin-bottom: 45px;
    }

    .page-title-wrapper {
        padding: 0 15px !important;
        width: 100% !important;

        .print {
            display: inline-block;
            background: $brand-primary;
            border: none;
            color: #ffffff;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 8px;
            padding-bottom: 8px;
            border-radius: 5px;
            border: none;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            @include transitions(400ms);

            &:hover,
            &:focus {
                background-color: $brand-secondary;
            }
        }
    }

    .checkout-success {
        p:first-child {
            font-weight: 700;
        }

        .actions-toolbar {
            margin-top: 10px;

            .action.primary {
                display: inline-block;
                background: $brand-primary;
                border: none;
                color: #ffffff;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 8px;
                padding-bottom: 8px;
                border-radius: 5px;
                border: none;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                @include transitions(400ms);

                &:hover,
                &:focus {
                    background-color: $brand-secondary;
                }
            }
        }
    }

    #registration {
        margin-top: 30px;

        h2 {
            color: $brand-primary;
            font-weight: 700;
        }

        .action.secondary {
            font-weight: 700;
        }
    }
}

.checkout-index-index {
    button {
        width: auto;
    }
    input[type="text"] {
        float:none;
    }
    button[type='button']{
        text-transform:none;
        background: inherit;
        span {
            color:inherit
        }
    }
}

.checkout-index-index {
    &._has-modal-custom {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.65);
            z-index: 9;
        }
    }

    .modal-popup {
        &._inner-scroll {
            overflow-y: auto;
        }

        &._show .modal-inner-wrap {
            margin: 5rem 10px;
            padding: 40px 30px 10px;
            transform: translate(calc(-50% - 10px), 0);
            width: calc(100% - 20px);
        }
    }

    #maincontent {
        .columns {
            margin-right: -15px;
            margin-left: -15px;
        }
    }

    .page-header-after {
        border-bottom: 3px solid #eaeaea;
    }

    .loading-mask {
        position: fixed !important;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 120%;
        height: 120%;
        text-align: center;
        z-index: 99999;

        .loader {
            text-align: center;

            img {
                display: inline-block;
                width: 35px;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            p {
                display: none;
            }
        }
    }

    >.loading-mask {
        .loader {
            img {
                width: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .opc-payment-additional {
        margin-top: 0;
        border-bottom: 1px solid #ccc;
        .form-discount {
            .payment-option-inner {
                .input-text {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            .actions-toolbar {
                margin-top: 31px;
            }
        }
    }
}

.opc-wrapper {
    margin: 0 0 20px;
}

.opc-wrapper .agreements-modal {
    display: none;
}

.opc-wrapper .modal-slide,
.modal-popup {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
}

.opc-wrapper .step-content {
    margin: 15px 0 40px;
}

.opc-wrapper {
    .fieldset {
        div.field {
            position: relative;
            max-width: 470px;
            margin-bottom: 0;
            &.payment-method-title,
            &.payment-option-title {
                margin-bottom: 0;
                max-width: 100%;
            }

            textarea[name="ordercomment[comment]"]+.field-note {
                display: none;
            }
        }
    }
}

.checkout-payment-method+.shipping-information {
    display: none;

    @include media-query(767px) {
        display: block;
    }
}

div.field.ecc_customer_order_ref,
div.field.ecc_shipping_berore_field {
    top: inherit;
    margin-top: 40px;
    max-width: 470px;
}

div.ecc_customer_order_ref {
    .label {
        color: $pt-black;
        font-weight: 700;
        font-size: 18px;
    }
}

.field.street {
    legend {
        padding-top: 0;
        margin-bottom: 5px;
    }

    label.label:not(.main-label) {
        display: block;
    }

    div.field {
        margin-bottom: 10px;
    }
}

.opc-wrapper .opc-payment {
    .step-title {
        margin-bottom: 15px;
    }
}

#checkout-payment-method-load {
    .payment-methods {
        .payment-method {
            border-bottom: 1px solid #ddd;

            &:last-child {
                border-bottom: none;
            }

            .payment-method-title {
                padding: 20px 0;

                >.label {
                    font-family: 'effra';
                    color: $pt-black;
                    margin: 0 0 0 10px;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.checkout-index-index {

    .modal-custom .action-close:before,
    .modal-popup .action-close:before,
    .modal-slide .action-close:before {
        top: 15px;
        right: 15px;
        width: 18px;
        height: 18px;
    }

    .modal-title {
        margin: 0;
        padding-bottom: 10px;
        line-height: 1.42857;
        font-size: 22px;
        font-weight: 700;
        color: $brand-primary;
        text-transform: uppercase;
        border-bottom: 3px solid $brand-primary;
    }

    .modal-content {
        margin-top: 0;
        box-shadow: none;
        border: none;
    }

    .details-qty span {
        font-size: 11px;
        padding: 0;
    }

    .opc-block-summary .minicart-items {
        .product-item-details {
            padding-left: 68px;
            padding-right: 0;
        }
    }

    .opc-block-summary .product-item .product-item-inner {
        display: table;
        margin: 0 0 10px;
        width: 100%;
    }

    .minicart-items .product>.product-item-photo,
    .minicart-items .product>.product-image-container {
        float: left;
        border: none;
    }

    .opc-block-summary .product-item {
        margin: 0;
        padding-left: 0;
    }

    .minicart-items .product-item {
        padding: 20px 0;
    }

    .modal-header {
        padding-top: 0;
    }

    .modal-footer {
        button.secondary {
            font-weight: 700;
        }
    }

    .minicart-items {
        margin: 0;
        padding: 0;
        list-style: none none;
    }

    .opc-summary-wrapper {
        .opc-block-summary {
            position: relative;
            background: white;
            margin-top: 45px;
            margin-bottom: $indent-m;
            padding: 35px 0 0;

            span.title {
                color: $pt-black;
                font-size: 30px;
                font-family: 'effra';
                line-height: 32px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: -1px;
                margin: 0 30px;
                padding-bottom: 10px;
                text-align: center;
                border-bottom: 1px solid #eaeaea;
            }
        }
    }

    .items-additional-data {
        display: block;
        clear: both;
        padding-top: 10px;

        span {
            &.product-data-heading {
                color: $pt-black;
                font-size: 11px;
                font-weight: 900;
                display: inline-block;
                width: 100%;
            }

            &.product-data-value {
                color: #333;
                font-size: 12px;
                font-weight: bold;
                clear: both;
                width: 100%;
            }
        }
    }
}

.opc-wrapper .form-control,
.control select,
.input-text,
.admin__data-grid-pager .page-control-input {
    float: left;
    height: 40px;
    line-height: 40px;
    background: #ffffff;
    border: 1px solid $pt-gray-04;
    -moz-border-radius: 0;
    text-shadow: none;
    margin-bottom: 10px;
    color: $pt-black;
    border-radius: 5px;
    font-family: 'effra';

    &:active {
        border-color: $pt-black;
        -webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
        -moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
        box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
    }

    &:focus {
        border-color: $pt-black;
        -webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
        -moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
        box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
    }
    &::-webkit-input-placeholder {
        color: $pt-gray-04;
        text-transform: capitalize;
        font-family: 'effra';
        font-size: 12px;
        font-weight: 400;
        @include media-query(919px) {
            font-size: 10px;
        }
    }
    &::-moz-placeholder {
        color: $pt-gray-04;
        text-transform: capitalize;
        font-family: 'effra';
        font-size: 12px;
        font-weight: 400;
        @include media-query(919px) {
            font-size: 10px;
        }
    }
    &:-ms-input-placeholder {
        color: $pt-gray-04;
        text-transform: capitalize;
        font-family: 'effra';
        font-size: 12px;
        font-weight: 400;
        @include media-query(919px) {
            font-size: 10px;
        }
    }
    &:-moz-placeholder {
        color: $pt-gray-04;
        text-transform: capitalize;
        font-family: 'effra';
        font-size: 12px;
        font-weight: 400;
        @include media-query(919px) {
            font-size: 10px;
        }
    }


}

.control select {
    display: inline-block;
    color: #373737;
    padding: 9px 35px 8px 14px;
    width: 100%;
    max-width: 100%;
    margin: 0 5px 0 0;
    border: 1px solid $pt-gray-04;
    box-shadow: none;
    border-radius: 5px;
    appearance: none;
    width: 100%;
    background-color: #ffffff;
    background-image: url('../images/arrow-down-black.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 12px 8px;
    line-height: 14px;
    &:focus {
        border-color: $pt-black;
        -webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
        -moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
        box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
    }
}

.opc-wrapper .control textarea {
    min-height: 120px;
    background: #efefef;
    margin-right: 10px;
    width: 100%;
    background-clip: padding-box;
    font-weight: lighter;
    font-family: $font-family-base;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 10px 9px;
    vertical-align: baseline;
    box-sizing: border-box;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 1px;

    &:hover,
    &:active,
    &:focus {
        border: none;
        box-shadow: none;
        outline: 0;
    }
}

.opc-wrapper,
.fieldset.address {
    .field .control._with-tooltip {
        input {
            width: calc(100% - 36px);
            height: 40px;
            border-radius: 4px;
            line-height: 40px;
            background: #ffffff;
            border: 1px solid $pt-gray-04;
            -moz-border-radius: 0;
            text-shadow: none;
            margin-bottom: 10px;
            color: $pt-black;
            border-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            font-family: 'effra';

            &:active {
                border-color: $pt-black;
                -webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
                -moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
                box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
            }

            &:focus {
                border-color: $pt-black;
                -webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
                -moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
                box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
            }
            &::-webkit-input-placeholder {
                color: $pt-gray-04;
                text-transform: capitalize;
                font-family: 'effra';
                font-size: 12px;
                font-weight: 400;
                @include media-query(919px) {
                    font-size: 10px;
                }
            }
            &::-moz-placeholder {
                color: $pt-gray-04;
                text-transform: capitalize;
                font-family: 'effra';
                font-size: 12px;
                font-weight: 400;
                @include media-query(919px) {
                    font-size: 10px;
                }
            }
            &:-ms-input-placeholder {
                color: $pt-gray-04;
                text-transform: capitalize;
                font-family: 'effra';
                font-size: 12px;
                font-weight: 400;
                @include media-query(919px) {
                    font-size: 10px;
                }
            }
            &:-moz-placeholder {
                color: $pt-gray-04;
                text-transform: capitalize;
                font-family: 'effra';
                font-size: 12px;
                font-weight: 400;
                @include media-query(919px) {
                    font-size: 10px;
                }
            }
        }
        .field-note {
            float: left;
            width: 100%;
        }
        .note {
            float: left;
        }
    }
}

.opc-wrapper legend.label {
    padding-top: 10px;
    clear: both;
    display: inline-block;
    font-weight: lighter;
    font-family: $font-family-base;
    color: #4b4b4b;
}

.opc-wrapper,
.form-shipping-address {
    .field .field-error {
        color: $brand-secondary;
        font-size: 12px;
        margin-top: 3px;
    }
}

.opc-wrapper {

    input[type="radio"],
    input[type="checkbox"] {
        margin: 0 !important;
    }
}

.checkout-index-index .mark {
    background: transparent;
}

.checkout-index-index .totals.shipping .label {
    font-size: 14px;
    padding: 0;
    color: #000;
}

.checkout-index-index .nav-sections,
.checkout-index-index .nav-toggle {
    display: none;
}

@media only screen and (max-width: 640px) {
    .opc-wrapper .step-title {
        font-size: 18px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 0;
    }
}

.opc-estimated-wrapper {
    background: #f1f2f4;
    margin: -40px -15px 25px;
    padding: 15px;
    font-size: 0;

    .items-basket,
    .action.showcart {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
    }

    .items-basket {
        width: calc(100% - 39px);
        padding: 4px 0;
    }

    .action.showcart {
        position: relative;
        background: none;
        border: none;
        width: 39px;
        height: 30px;
        padding: 0;
        color: $brand-primary;
        font-weight: 700;
        line-height: 30px;
        &:hover{
            color: white;
        }
        .counter {
            line-height: 25px;
        }

        &:before {
            content: "\e917";
            position: absolute;
            font-family: 'icomoon';
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, calc(-50% - 2px));
            font-size: 23px;
            font-weight: 400;
        }
    }
}

.opc-estimated-wrapper .estimated-block {
    font-size: 18px;
    font-weight: 700;
}

.opc-estimated-wrapper .estimated-block .estimated-label {
    display: block;
    margin: 0 0 5px;
}

.opc-estimated-wrapper .minicart-wrapper .action.showcart:before {
    color: #333;
}

.opc-progress-bar {
    margin: 0 0 20px;
    padding-left: 0;
    counter-reset: i;
    font-size: 0;
}

.opc-progress-bar-item {
    margin: 0 0 10px;
    width: 50%;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
}

.opc-progress-bar-item:before {
    background: #bfcdd9;
    top: 19px;
    content: '';
    height: 7px;
    left: 0;
    position: absolute;
    width: 100%;
}

.opc-progress-bar-item:first-child:before {
    border-radius: 6px 0 0 6px;
}

.opc-progress-bar-item:last-child:before {
    border-radius: 0 6px 6px 0;
}

.opc-progress-bar-item>span {
    display: inline-block;
    padding-top: 45px;
    width: 100%;
    word-wrap: break-word;
    color: $pt-gray-06;
    font-family: inherit;
    font-style: inherit;
    font-weight: 700;
    line-height: inherit;
    font-size: 17px;
    text-transform: uppercase;
}

.opc-progress-bar-item>span:before,
.opc-progress-bar-item>span:after {
    color: $pt-gray-06;
    height: 38px;
    margin-left: -19px;
    width: 38px;
    border-radius: 50%;
    content: '';
    left: 50%;
    position: absolute;
    top: 0;
}

.opc-progress-bar-item>span:before{
    background: #bfcdd9;
}

.opc-progress-bar-item>span:after {
    background: #fff;
    height: 26px;
    margin-left: -13px;
    top: 6px;
    width: 26px;
    content: counter(i);
    counter-increment: i;
    color: #bfcdd9;
    font-family: inherit;
    font-style: inherit;
    font-weight: 600;
    line-height: inherit;
    font-size: 18px;
}

.opc-progress-bar-item._active:before {
    background: $brand-primary;
}

.opc-progress-bar-item._active>span {
    color: $brand-primary;
}

.opc-progress-bar-item._active>span:before {
    background: $brand-primary;
}

.opc-progress-bar-item._active>span:after {
    content: "\f00c";
    font-family: "FontAwesome";
    font-size: 20px;
    color: $brand-primary;
}

.opc-wrapper .label {
    font-size: 16px;
    line-height: 1;
    color: #4b4b4b;
    text-align: left;
    white-space: inherit;
    vertical-align: baseline;
    border-radius: .25em;
    display: block;
    margin-top: 10px;
    padding-left: 0;
}

#checkoutSteps {
    padding-left: 0;
    list-style: none;

    select {
        background: #ffffff url(../images/arrow-down-black.svg) no-repeat calc(100% - 10px) center;
        color: #373737;
        background-size: 20px 10px;
        padding: 9px 35px 8px 14px;
        border: 1px solid $pt-gray-04;
        border-radius: 5px;
        box-shadow: none;
        appearance: none;
        width: 100%;
        line-height: 20px;
        &:active {
            border-color: $pt-black;
            -webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
            -moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
            box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
        }

        &:focus {
            border-color: $pt-black;
            -webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
            -moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
            box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
        }
        &::-webkit-input-placeholder {
            color: $pt-gray-04;
            text-transform: capitalize;
            font-family: 'effra';
            font-size: 12px;
            font-weight: 400;
            @include media-query(919px) {
                font-size: 10px;
            }
        }
        &::-moz-placeholder {
            color: $pt-gray-04;
            text-transform: capitalize;
            font-family: 'effra';
            font-size: 12px;
            font-weight: 400;
            @include media-query(919px) {
                font-size: 10px;
            }
        }
        &:-ms-input-placeholder {
            color: $pt-gray-04;
            text-transform: capitalize;
            font-family: 'effra';
            font-size: 12px;
            font-weight: 400;
            @include media-query(919px) {
                font-size: 10px;
            }
        }
        &:-moz-placeholder {
            color: $pt-gray-04;
            text-transform: capitalize;
            font-family: 'effra';
            font-size: 12px;
            font-weight: 400;
            @include media-query(919px) {
                font-size: 10px;
            }
        }
    }
}

#co-shipping-form {
    .required-label {
        color: $brand-secondary;
        font-size: 85%;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.opc-wrapper legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    font-size: 21px;
    line-height: inherit;
    color: #333333;
    border: 0;
    border-bottom: none;
}

.opc-wrapper .step-title {
    margin: 0;
    padding: 0 0 10px;
    text-transform: capitalize;
    font-family: 'effra';
    color: $pt-black;
    font-weight: bold;
    letter-spacing: -1px;
    font-size: 36px;
    line-height: 45px;
    border-bottom: 4px solid $pt-black;
}

.opc-sidebar .opc-block-summary {
    margin-bottom: 0;
}

.authentication-dropdown .modal-content {
    box-shadow: none;
    border: none;
    background: none;
    border-radius: 0;
}

.checkout-index-index {
    .agreements-modal {

        .modal-content,
        .modal-footer {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .block-authentication {
        overflow: hidden;
    }
    a.action {
        &.skip{
            display:none;
        }
    }
}

.opc-wrapper {
    #customer-email-fieldset {
        border-bottom: 1px solid $pt-gray-01;
        padding-bottom: 20px;
        display: flex;
        flex-flow: column;
        .actions-toolbar {

            div.primary,
            div.secondary {
                display: inline-block;
                vertical-align: middle;
            }

            div.primary {
                margin-right: 15px;
            }

            div.secondary a {
                color: #333;
                text-decoration: underline;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}

.opc-wrapper,
.fieldset.address {
    .field-tooltip {
        #tooltip-label {
            display: none;
        }

        #tooltip {
            &:focus {
                outline: 0;
            }
        }
    }
}

.opc-progress-bar-item._complete {
    cursor: pointer;
}

.opc-progress-bar-item._complete>span {
    color: #333;
}

.opc-progress-bar-item._complete>span:after {
    content: "\f00c";
    font-family: "FontAwesome";
    font-size: 20px;
}

.field.choice .field-tooltip {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: -3px;
}

.field.choice .field-tooltip-action {
    line-height: 21px;
}

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
    border-color: #ed8380;
}

.opc-wrapper .fieldset>.field>.label {
    font-weight: 100;
    color: #4b4b4b;
}

@media only screen and (min-width: 768px) {
    .opc-estimated-wrapper {
        display: none;
    }

    .opc-wrapper .fieldset>.field.required,
    .opc-wrapper .fieldset>.field._required {
        position: relative;
    }

    .opc-wrapper .fieldset>.field.required>label,
    .opc-wrapper .fieldset>.field._required>label {
        padding-right: 25px;
    }

    .opc-wrapper .fieldset>.field.required>label:after,
    .opc-wrapper .fieldset>.field._required>label:after {
        margin-left: 10px;
        position: absolute;
        top: 9px;
    }

    .checkout-index-index {
        .opc-summary-wrapper {
            .opc-block-summary {
                margin-top: 61px;
                border: 1px solid #eaeaea;
                border-radius: 20px;
                box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
            }
        }

        .modal-popup {
            .modal-footer {
                .action-save-address {
                    float: right;
                    margin: 0 10px 0 10px;
                    background: $brand-primary;
                    transition: all 0.15s ease-out;
                }
                .action.secondary {
                    background: $pt-black;
                    transition: all 0.15s ease-out;
                    &:hover {
                        background: $pt-black!important;
                        outline: 2px solid #fff;
                        box-shadow: 0 0 0 4px $pt-black;
                    }
                }
            }
        }
    }
}

//@media only screen and (min-width: 1024px) {
.checkout-index-index .modal-popup .modal-inner-wrap {
    transform: translateX(-50%);
    width: 100%;
    max-width: 800px;
    left: 50%;
}

//}

.field-tooltip {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 27px;
}

.fieldset.address {
    .field-tooltip {
        top: 24px;
    }
}

.field-tooltip._active {
    z-index: 100;
}

.field-tooltip._active .field-tooltip-content {
    display: block;
}

.field-tooltip._active .field-tooltip-action:before {
    background-color: $brand-secondary;
}

.field-tooltip .field-tooltip-action {
    position: relative;
    display: inline-block;
    text-decoration: none;
}

.field-tooltip .field-tooltip-action>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.field-tooltip .field-tooltip-action:before {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    content: "\f128";
    vertical-align: middle;
    display: inline-block;
    width: 26px;
    border-radius: 5px;
    margin: inherit;
    background-color: $brand-primary;
    color: #fff;
    font-family: "FontAwesome";
    font-size: 19px;
    font-weight: normal;
    line-height: inherit;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    speak: none;
    overflow: hidden;
}

.field-tooltip .field-tooltip-action:hover:before {
    background-color: $brand-secondary;
}

._keyfocus .field-tooltip .field-tooltip-action:focus {
    z-index: 100;
}

._keyfocus .field-tooltip .field-tooltip-action:focus+.field-tooltip-content {
    display: block;
}

._keyfocus .field-tooltip .field-tooltip-action:focus:before {
    color: #333;
}

.field-tooltip .field-tooltip-content {
    background: #f1f2f4;
    border-radius: 1px;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 20px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.15);
}

.field-tooltip .field-tooltip-content:before {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    border-right-color: #f1f2f4;
    left: -20px;
    top: 12px;
    content: '';
    display: block;
    position: absolute;
    z-index: 3;
}

@media only screen and (max-width: 480px) {
    .modal-popup .field-tooltip .field-tooltip-content {
        width: 200px;
    }
}

.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
    margin-top: 28px;
}

.opc-wrapper .form-shipping-address {
    border-top: 1px solid $pt-gray-01;
    padding-top: 35px;
    #shipping-new-address-form {
        display: flex;
        flex-flow: column;
    }
}

.opc-wrapper .form-login .fieldset .note,
.opc-wrapper .form-shipping-address .fieldset .note {
    font-size: 14px;
    margin-top: 10px;
    display: block;
}

.opc-wrapper .shipping-address-items {
    margin: 0 0 15px;
    font-size: 0;
}

.opc-wrapper .shipping-address-item {
    position: relative;
    display: inline-block;
    border: 1px solid #f1f2f4;
    width: calc(50% - 8px);
    margin: 20px 15px 0 0;
    padding: 20px;
    vertical-align: top;
    line-height: 22px;
    font-size: 15px;
    word-wrap: break-word;
    transition: 0.3s border-color;

    &:nth-child(2n) {
        margin-right: 0;
    }
}

.opc-wrapper .shipping-address-item.selected-item {
    background-color: #f1f2f4;
}

.opc-wrapper .shipping-address-item.selected-item:after {
    background: transparent;
    color: #fff;
    content: none;
    font-family: "icons-blank-theme";
    height: 27px;
    width: 29px;
    font-size: 27px;
    line-height: 21px;
    padding-top: 2px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
}
.address-button-container {
    .new-address-popup > button.action-show-popup {
        background: $brand-primary;
        transition: all 0.15s ease-out;
        &:hover, &:focus, &:active {
            background: $brand-hover!important;
            color: white;
            outline: 2px solid #fff;
            box-shadow: 0 0 0 4px $brand-hover;
        }
    }
}

.address-button-container {
    .search-address > button {
        background: $brand-primary;
        transition: all 0.15s ease-out;
        background-color: $brand-primary !important;
        &:hover, &:focus, &:active {
            background: $brand-hover!important;
            color: white;
            outline: 2px solid #fff;
            box-shadow: 0 0 0 4px $brand-hover;
        }
    }
}


#checkout-step-shipping {

    .action-show-popup,
    .action-secondary {
        background: $brand-primary;
        border: none;
        color: #ffffff;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 5px;
        border: none;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        transition: all 0.15s ease-out;
        font-family: 'effra';
        &:hover, &:focus, &:active {
            background: $brand-hover!important;
            color: white;
            outline: 2px solid #fff;
            box-shadow: 0 0 0 4px $brand-hover;
        }

        >span:before {
            font-family: "FontAwesome";
            padding-right: 5px;
        }
    }

    .action-secondary>span {
        &:before {
            content: "\f002";
        }
    }

    .action-show-popup>span {
        &:before {
            content: "\f067";
        }
    }

    .field.addresses {
        >.control {
            margin-bottom: 15px;
        }

        +.action-show-popup+#opc-new-shipping-address+.ecc_customer_order_ref {
            max-width: 100%;
            padding: 30px 0;
            border-top: 1px solid $pt-gray-01;
            border-bottom: 1px solid $pt-gray-01;

            .label,
            .control {
                max-width: 470px;
            }
        }
    }

    .search-address {
        float: left;
        margin: 0 15px 25px 0;
    }
}

#opc-new-shipping-address {
    .field.choice {
        margin: 15px 0;

        input[type="checkbox"],
        .label {
            display: inline-block;
            vertical-align: top;
        }

        input[type="checkbox"] {
            margin-right: 5px !important;
        }

        .label {
            margin: 0;
        }
    }

    .field.street {
        margin-top: 15px;

        legend {
            border-bottom: none;
        }
    }
}

.modal-content #opc-new-shipping-address {
    margin: 0 -2.6rem;
}

.payment-method input[type="radio"],
.payment-method input[type="radio"]+label {
    display: inline-block;
}

.opc-wrapper .shipping-address-item.selected-item {
    border-color: transparent;
}

.opc-wrapper .shipping-address-item .action-select-shipping-item,
.opc-wrapper .shipping-address-item .edit-address-link {
    border: none;
    height: 34px;
    line-height: 28px;
    margin: 10px 0 0;
    padding: 2px 12px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    background-color: $brand-primary;
    background-repeat: no-repeat;
    border: none;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'effra';
    transition: all 0.15s ease-out;
    &:focus {
        background: $brand-hover!important;
        color: white;
        outline: 2px solid #fff;
        box-shadow: 0 0 0 4px $brand-hover;
    }
    &:hover {
        background: $brand-hover!important;
        color: white;
        outline: 2px solid #fff;
        box-shadow: 0 0 0 4px $brand-hover;
    }
}

.address-button-container {
    .button {
        border: none;
        height: 34px;
        line-height: 28px;
        margin: 10px 0 0;
        padding: 2px 12px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        background-color: $brand-primary;
        background-repeat: no-repeat;
        border: none;
        border-radius: 4px;
        text-align: center;
        text-transform: uppercase;
        @include transitions(400ms);

        &:hover,
        &:focus {
            background-color: $brand-secondary;
        }
    }
}

.opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
    background-color: #ccc;
    pointer-events: none;
}

.opc-wrapper .action-select-shipping-item {
    float: right;
    margin: 20px 0 0;
}

.opc-wrapper .edit-address-link {
    display: block;
    float: left;
    margin: 26px 5px 0 0;
}

.checkout-shipping-method .step-title {
    margin-bottom: 0;
}

.checkout-shipping-method .no-quotes-block {
    margin: 20px 0;
    font-size: 16px;
}

.methods-shipping .actions-toolbar .action.primary {
    margin: 20px 0 0;
    padding: 12px 30px;
    font-size: 16px;
}

.table-checkout-shipping-method {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 35px;

    tbody td {
        padding-top: 12px;
    }

    tbody td:first-child {
        padding-left: 0;
        padding-right: 0;
        width: 20px;
    }

    tbody .row-error td {
        border-top: none;
        padding-bottom: 10px;
        padding-top: 0;
    }

    .col-price {

        .price-including-tax,
        .price-excluding-tax {
            &:after {
                position: relative;
                right: 0;
                content: attr(data-label);
                font-size: 10px;
                color: #333;
                font-weight: 100;
            }

            .price {
                font-weight: 700;

                @include media-query(539px) {
                    font-size: 13px;
                }
            }
        }

        .price-excluding-tax {
            font-size: 18px;

            @include media-query(539px) {
                font-size: 13px;
            }
        }

        .price-including-tax {
            @include media-query(539px) {
                font-size: 12px;
            }

            &:before {
                content: ' (';
            }

            &:after {
                content: attr(data-label) " )";
            }
        }
    }

    .col-method {
        @include media-query(539px) {
            font-size: 13px;
        }
    }

    @include media-query(767px) {
        tr {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            margin: 0 0 10px;
        }

        tbody td {
            padding-top: 0;

            &.col-method {
                order: 1;
            }

            &.col-price {
                order: 3;
                width: 100%;
                flex-basis: 100%;
                padding-left: 20px;
            }

            &.col-name {
                order: 2;
                flex: 1;
                font-size: 15px;
            }

            &.col-comment {
                order: 4;
                width: 100%;
                flex-basis: 100%;
            }
        }
    }
}

.checkout-shipping-method {
    position: relative;
}

.shipping-policy-block.field-tooltip {
    top: 12px;
}

.shipping-policy-block.field-tooltip .field-tooltip-action {
    color: $brand-primary;
    cursor: pointer;
}

.shipping-policy-block.field-tooltip .field-tooltip-action:before {
    display: none;
}

.shipping-policy-block.field-tooltip .field-tooltip-content {
    width: 420px;
    top: 30px;
}

@media only screen and (max-width: 640px) {
    .shipping-policy-block.field-tooltip {
        margin-bottom: 20px;
        position: relative;
        right: auto;
        top: auto;
    }

    .shipping-policy-block.field-tooltip .field-tooltip-content {
        width: 300px;
        right: auto;
    }

    .shipping-policy-block.field-tooltip .field-tooltip-content:before,
    .shipping-policy-block.field-tooltip .field-tooltip-content:after {
        right: auto;
    }
}

.opc-summary-wrapper .opc-block-shipping-information {
    padding: 0 30px;

    @include media-query(767px) {
        display: none;
    }
}

.shipping-information-title {
    margin: 0 0 10px;
    position: relative;
    font-size: 0;

    >span,
    .action {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
    }

    >span {
        color: $brand-primary;
        font-weight: 700;
        text-transform: uppercase;
        width: calc(100% - 75px)
    }

    .action-edit {
        width: 75px;
        margin: 0;
        padding: 3px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        background-color: $brand-primary;
        background-repeat: no-repeat;
        border: none;
        border-radius: 4px;
        text-align: center;
        text-transform: uppercase;
        font-family: 'effra';
        transition: all 0.15s ease-out;
        &:hover, &:focus, &:active {
            background: $brand-hover!important;
            color: white;
            outline: 2px solid #fff;
            box-shadow: 0 0 0 4px $brand-hover;
        }
    }
}

.shipping-information .shipping-information-content {
    line-height: 27px;
}

.opc-block-shipping-information .shipping-information-content .actions-toolbar {
    margin-left: 0;
}

.shipping-information .ship-to,
.shipping-information .ship-via {
    margin: 0 0 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
}

.opc-block-summary {
    background: #f5f5f5;
    padding: 22px 30px 0;
    margin: 0 0 20px;
}

.opc-block-summary>.title {
    display: block;
}

.opc-block-summary .mark .value {
    color: #999;
    display: block;
}

.opc-block-summary .grand.incl+.grand.excl .mark,
.opc-block-summary .grand.incl+.grand.excl .amount {
    border-top: 0;
    font-size: 14px;
    padding-top: 0;
}

.opc-block-summary .grand.incl+.grand.excl .mark strong,
.opc-block-summary .grand.incl+.grand.excl .amount strong {
    font-weight: 400;
}

.opc-block-shipping-information {
    margin-top: 20px;
}

.opc-block-summary .not-calculated {
    font-style: italic;
}

.opc-block-summary .items-in-cart>.title {
    position: relative;
    padding: 16px 24px 16px 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin-bottom: 0;

    strong {
        span {
            font-size: 15px;
            color: #333;
        }
    }

}
.opc-block-summary {
    .block.items-in-cart {
        .actions-toolbar {
            .secondary {
                .action.viewcart {
                    color: $brand-primary;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.opc-block-summary .items-in-cart>.title>span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.opc-block-summary .items-in-cart>.title:after {
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
    line-height: 12px;
    color: inherit;
    content: "\f078";
    font-family: "FontAwesome";
    margin: 3px 0 0;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle;
}

.opc-block-summary .items-in-cart>.title:hover:after {
    color: inherit;
}

.opc-block-summary .items-in-cart>.title:active:after {
    color: inherit;
}

.opc-block-summary .items-in-cart>.title:after {
    position: absolute;
    right: 0;
    top: 20px;
}

.opc-block-summary .items-in-cart>.title strong {
    font-size: 18px;
    font-weight: 300;
    margin: 0;
}

.opc-block-summary .items-in-cart.active>.title:after {
    content: "\f077";
}

.opc-block-summary .items-in-cart .product {
    position: relative;
}

.opc-block-summary .minicart-items-wrapper {
    margin: 0;
    padding: 15px 0 0 0;
    border: 0;
    border-top: 1px solid #eaeaea;

    @media (min-width: 900px) {
        max-height: 520px;
    }
}

.column.main .opc-block-summary .product-item {
    margin: 0;
    padding-left: 0;
    border-top-color: #eaeaea;

    .product-image-container {
        .product-image-wrapper {
            img {
                border: 1px solid #dedede;
            }
        }
    }

    .details-qty {
        .label {
            display: inline;
            color: $brand-primary;
            font-weight: bold;
            text-transform: uppercase;
        }

        .value {
            display: inline;
        }
    }

    .product-item-inner {
        margin: 0 0 10px;
        width: 100%;
    }

    .product-item-name-block {
        text-align: left;
        color: #333;
    }

    .subtotal {
        clear: both;
        display: block;
        padding-top: 20px;

        .price {
            font-size: 16px;
            font-weight: 400;
        }

        .price-excluding-tax {
            width: 100%;
            display: inline-block;
            clear: both;
            margin: 0;
            padding: 0;
            line-height: 14px;

            .price {
                font-size: 22px;
                color: #333;
                font-weight: bold;
            }

            &:after {
                position: relative;
                right: 0;
                content: attr(data-label);
                font-size: 10px;
                color: #333;
                font-weight: 100;
            }
        }

        .price-including-tax {
            width: 100%;
            display: inline-block;
            clear: both;
            margin: 0;
            padding: 0;
            line-height: 14px;

            .price {
                font-size: 12px;
                color: #333;
                font-weight: 600;
            }

            &:after {
                position: relative;
                right: 0;
                content: attr(data-label);
                font-size: 10px;
                color: #333;
                font-weight: 100;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .opc-summary-wrapper .modal-header .action-close {
        display: none;
    }
}

div[name="shippingAddress.telephone"] {
    position: relative;
}

.authentication-dropdown {
    box-sizing: border-box;
}

.authentication-wrapper {
    float: right;
    max-width: 50%;
    position: relative;
    z-index: 1;
    margin-top: 20px;

    button.action-auth-toggle {
        background: $brand-secondary !important;
        border-color: $brand-secondary !important;
        color: #ffffff !important;
        font-family: 'proxima-nova', sans-serif !important;
        padding: 8px 20px;
        border-radius: 5px;
        border: none;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
    }

    .authentication-dropdown {
        .modal-header {
            border-bottom: none;
            padding: 0;
        }

        .modal-inner-wrap {
            padding: 20px;
        }

        #block-customer-login-heading {
            color: $brand-primary;
            font-size: 22px;
            line-height: 28px;
            text-transform: uppercase;
            letter-spacing: -1px;
            font-weight: 600;
            padding-bottom: 15px;
        }

        .block-authentication .block-title {
            margin-bottom: 2px;
        }

        .fieldset {
            margin-bottom: 20px;
        }

        &:active,
        &:focus,
        &:hover {
            outline: 0;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }
}

._has-auth-shown .authentication-wrapper {
    z-index: 900;
}

.block-authentication .block-title {
    font-size: 18px;
    border-bottom: 0;
    margin-bottom: 25px;
}

.block-authentication .block-title strong {
    font-weight: 300;
}

.block-authentication .field .label {
    font-weight: 400;
    color: #4b4b4b;
}

.block-authentication .actions-toolbar>.secondary {
    padding-top: 25px;
    text-align: left;
}

.block-authentication .block[class] {
    margin: 0;
}

.block-authentication .block[class] ul {
    list-style: none;
    padding-left: 10px;
}

.block-authentication .block[class] .field .control,
.block-authentication .block[class] .field .label {
    float: none;
    width: auto;
}

.block-authentication .block[class]+.block {
    border-top: 1px solid #c1c1c1;
    margin-top: 40px;
    padding-top: 40px;
    position: relative;
}

.block-authentication .block[class]+.block::before {
    height: 36px;
    line-height: 34px;
    margin: -17px 0 0 -18px;
    min-width: 36px;
    background: #fff;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    box-sizing: border-box;
    color: #c1c1c1;
    content: attr(data-label);
    display: inline-block;
    left: 50%;
    letter-spacing: normal;
    padding: 0 0.2rem;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
}

@media only screen and (min-width: 768px) {
    .authentication-dropdown {
        position: absolute;
        top: 100%;
        width: 100%;
        background-color: #fff;
        border: 1px solid #aeaeae;
        text-align: left;
        -webkit-transform: scale(1, 0);
        transform: scale(1, 0);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transition: visibility 0 linear 0.15s, -webkit-transform linear 0.15s;
        transition: visibility 0 linear 0.15s, -webkit-transform linear 0.15s;
        transition: transform linear 0.15s, visibility 0 linear 0.15s;
        transition: transform linear 0.15s, visibility 0 linear 0.15s, -webkit-transform linear 0.15s;
        visibility: hidden;
    }

    .authentication-dropdown._show {
        z-index: 100;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-transition: visibility 0 linear 0, -webkit-transform linear 0.15s;
        transition: visibility 0 linear 0, -webkit-transform linear 0.15s;
        transition: transform linear 0.15s, visibility 0 linear 0;
        transition: transform linear 0.15s, visibility 0 linear 0, -webkit-transform linear 0.15s;
        visibility: visible;
    }

    .authentication-wrapper {
        width: 66.66667%;
        text-align: right;
    }

    .authentication-dropdown .fieldset {
        overflow: hidden;
    }

    .block-authentication .block-title {
        font-size: 26px;
        border-bottom: 0;
        margin-bottom: 25px;
    }

    .block-authentication .actions-toolbar>.primary {
        float: right;
    }

    .block-authentication .actions-toolbar>.primary .action {
        margin-right: 0;
    }

    .block-authentication .actions-toolbar>.secondary {
        float: left;
        margin-right: 2rem;
        padding-top: 1rem;
    }

    .popup-authentication .modal-inner-wrap {
        min-width: 768px;
        width: 60%;
    }

    .popup-authentication .block-authentication {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        border-top: 1px solid #c1c1c1;
    }

    .popup-authentication .block[class],
    .popup-authentication .form-login,
    .popup-authentication .fieldset,
    .popup-authentication .block-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    .popup-authentication .block[class] {
        box-sizing: border-box;
        float: left;
        padding: 10px 30px 0 0;
        width: 50%;
    }

    .popup-authentication .block[class]+.block {
        border-left: 1px solid #c1c1c1;
        border-top: 0;
        margin: 0;
        padding: 10px 0 0 40px;
    }

    .popup-authentication .block[class]+.block::before {
        left: 0;
        top: 50%;
    }

    .popup-authentication .actions-toolbar {
        margin-bottom: 0;
        margin-top: auto;
    }
}

.checkout-payment-method .step-title {
    margin-bottom: 20px;
}

.checkout-payment-method .payment-method:first-child .payment-method-title {
    border-top: 0;
}

.checkout-payment-method .payment-method._active .payment-method-content {
    display: block;
}

.checkout-payment-method .payment-method-title {
    margin: 0;
}

.checkout-index-index {
    .choice.field {

        .checkbox,
        .label {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
        }

        .label {
            margin-left: 5px;
        }
    }
}

.checkout-payment-method .actions-toolbar div.primary button.action-cancel,
.checkout-payment-method .actions-toolbar div.primary button.action-update,
.checkout-agreements-block button.action-secondary {
    float: none;
    border: none;
    height: 34px;
    line-height: 28px;
    margin: 0;
    padding: 2px 12px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    background-color: $brand-primary;
    background-repeat: no-repeat;
    border: none;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    @include transitions(400ms);

    &:hover,
    &:focus {
        background-color: $brand-secondary;
    }
}

.checkout-payment-method .actions-toolbar {
    margin-top: 0;
    text-align: left;
    display: block;

    button.primary {
        padding: 7px 15px;
        font-size: 18px;
    }

    div.primary {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;

        button.action {
            transition: all 0.15s ease-out;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            &.action-update {
                order: 2;
                margin-left: 6px;
            }
        }
    }
}



.checkout-payment-method .payment-method-title .payment-icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}

.checkout-payment-method .payment-method-title .action-help {
    display: inline-block;
    margin-left: 5px;
}

.checkout-payment-method .payment-method-content {
    display: none;
    padding: 0 0 20px 26px;
}

.checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 20px;
}

.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
    max-width: 500px;
}

.opc-wrapper .checkout-payment-method .step-content .field.billing-address-same-as-shipping-block {
    margin: 0 0 5px;
}

.checkout-payment-method .payment-method-billing-address {
    margin: 0 0 10px;
}

.checkout-payment-method .payment-method-billing-address .primary .action-update {
    margin-right: 0;
}

.checkout-payment-method {

    .payment-method-billing-address,
    .checkout-agreements-block {
        .action {
            &.action-edit-address {
                background: $brand-primary;
                font-family: 'proxima-nova', sans-serif;
                color: #ffffff;
                padding: 2px 10px;
                border-radius: 4px;
                border: none;
                font-size: 14px;

                &:hover {
                    background: $brand-secondary;
                }
            }
        }
    }

    .payment-method-billing-address {
        >.field {
            margin-bottom: 5px;
        }

        .billing-address-details {
            line-height: 18px;
        }
    }

    .checkout-agreements-block {
        margin-top: 35px;
        margin-left: -26px;
        padding: 40px 50px;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
        border-radius: 20px;

        .checkout-agreements {
            margin-bottom: 20px;

            .label {
                display: inline-block;
                padding-right: 0;
                padding-left: 5px;
            }

            .action.action-show {
                padding: 0;
                border: none;
                text-decoration: underline;
                font-size: inherit;
                text-transform: none;
                border-radius: 0;
                color: #4b4b4b;
                background: inherit;

                &:hover {
                    text-decoration: none;
                    background: inherit !important;
                }
                span {
                    color: inherit;
                    font-weight: 100;
                    &:hover {
                        color: #4b4b4b !important;
                    }
                }
            }
        }
    }
}

.checkout-payment-method .payment-method-note+.payment-method-billing-address {
    margin-top: 20px;
}

.checkout-payment-method .payment-method-iframe {
    background-color: transparent;
    display: none;
    width: 100%;
}

.checkout-payment-method .no-payments-block {
    margin: 20px 0;
}

.opc-payment-additional,
.payment-option-title {
    &:focus {
        outline: 0;
    }
}

.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
    content: "\f077";
}

.checkout-payment-method .payment-option._collapsible .payment-option-title {
    cursor: pointer;
}

.checkout-payment-method .payment-option._collapsible .payment-option-content {
    display: none;
}

.checkout-payment-method .payment-option-title {
    border-top: 1px solid #ccc;
    padding: 20px 0 20px 27px;
}

.checkout-payment-method .payment-option-title .action-toggle {
    display: inline-block;
    color: $brand-primary;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
}

.checkout-payment-method .payment-option-title .action-toggle:after {
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 16px;
    color: $brand-primary;
    content: "\f078";
    font-family: FontAwesome !important;
    font-family: "icons-blank-theme";
    margin: -5px 0 0 10px;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    vertical-align: middle;
}

.checkout-payment-method .payment-option-content {
    padding: 0 0 20px;
}

.checkout-payment-method .payment-option-inner {
    margin: 0;

    .field {
        margin-bottom: 10px;
    }
}

.checkout-payment-method .credit-card-types {
    padding: 0;
}

.checkout-payment-method .credit-card-types .item {
    display: inline-block;
    list-style: none;
    margin: 0 5px 0 0;
    vertical-align: top;
}

.checkout-payment-method .credit-card-types .item._active {
    font-weight: 700;
}

.checkout-payment-method .credit-card-types .item._active img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}

.checkout-payment-method .credit-card-types .item._inactive {
    opacity: 0.4;
}

.checkout-payment-method .credit-card-types .item span {
    display: inline-block;
    padding-top: 6px;
    vertical-align: top;
}

.checkout-payment-method .credit-card-types img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.checkout-payment-method .ccard .fields>.year {
    padding-left: 5px;
}

.checkout-payment-method .ccard .fields .select {
    padding-left: 5px;
    padding-right: 5px;
}

.checkout-payment-method .ccard .month .select {
    width: 140px;
}

.checkout-payment-method .ccard .year .select {
    width: 80px;
}

.checkout-payment-method .ccard .number .input-text {
    width: 225px;
}

.checkout-payment-method .ccard>.field.cvv>.control {
    padding-right: 20px;
    width: auto;
}

.checkout-payment-method .ccard .cvv .input-text {
    width: 55px;
}

.checkout-payment-method .ccard.fieldset>.field .fields.group.group-2 .field {
    width: auto !important;
}

.checkout-agreements-block {
    margin-bottom: 20px;
}

.checkout-agreements-block .action-show {
    vertical-align: baseline;
}

.checkout-agreements-items {
    padding-bottom: 30px;
}

.checkout-agreements-items .checkout-agreements-item {
    margin-bottom: 20px;
}

.checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: 0;
}

.checkout-agreement-item-content {
    overflow: auto;
}

.opc-block-summary,
#opc-sidebar,
.opc-summary-wrapper,
.opc-block-summary .items-in-cart>.title {

    &:active,
    &:focus,
    &:hover {
        outline: 0;
    }
}

.dda-block .dda-form {
    padding: 20px 0;

    .field {
        max-width: 470px;
    }
}

@media only screen and (max-width: 640px) {
    .opc-wrapper .shipping-address-item {
        margin: 15px 0 0;
        width: 100%;
    }

    .opc-wrapper .form-login,
    .opc-wrapper .form-shipping-address {
        margin-top: 28px;
    }

    .opc-wrapper .action-select-shipping-item {
        float: none;
        margin-top: 10px;
        width: 100%;
    }

    .opc-wrapper .action-show-popup {
        width: 100%;
        margin-bottom: 10px;
    }

    #checkout-step-shipping .search-address {
        float: none;
        margin-right: 0;

        button {
            width: 100%;
        }
    }

    .opc-wrapper .shipping-address-item .edit-address-link {
        display: inline-block;
        text-decoration: none;
        margin: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        width: 34px;
        height: 34px;
        padding: 4px;

        &:before {
            content: '';
            background-image: url('../images/edit-icon-nobg.svg');
            display: block;
            width: 100%;
            height: 100%;
            speak: none;
            text-align: center;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        >span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }

    .opc-wrapper .edit-address-link:hover:before {
        color: #303030;
    }

    .opc-wrapper .edit-address-link:active:before {
        color: #303030;
    }
}

@media only screen and (min-width: 1024px) {
    .opc-wrapper .shipping-address-item {
        width: calc(33.3333% - 10px);

        &:nth-child(2n) {
            margin-right: 15px;
        }

        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

@media only screen and (min-width: 992px) {
    .opc-progress-bar {
        padding-right: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .checkout-payment-method .payment-option {
        margin: 0 -15px;
    }

    .checkout-payment-method .payment-option .payment-option-content {
        padding: 0 15px 20px;
    }
}

@media only screen and (min-width: 768px) {
    .checkout-payment-method .payment-option-content .payment-option-inner+.actions-toolbar {
        margin-left: 0;
    }

    #checkoutSteps {
        padding-right: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .checkout-payment-method .payment-methods {
        margin: 0 -15px;
    }

    .checkout-payment-method .payment-method-title {
        padding: 15px;
    }

    .checkout-payment-method .payment-method-content {
        padding: 0 15px 20px;
    }

    .checkout-payment-method .payment-method-billing-address .action-cancel {
        margin-top: 10px;
    }
}

@media only screen and (min-width: 768px) {
    .checkout-payment-method .payment-methods .actions-toolbar .primary {
        margin: 0;
    }

    .checkout-payment-method .fieldset>.field-select-billing>.control {
        float: none;
        width: 100%;
    }

    .checkout-payment-method .payment-method-content .fieldset>.field {
        margin: 0 0 20px;
    }

    .checkout-payment-method .payment-method-content .fieldset>.field.type .control {
        margin-left: 25.8%;
    }

    .checkout-payment-method .payment-method-content .fieldset>.field.type.no-detection .control {
        margin-left: 0;
    }

    .payment-method-billing-address .action-update {
        float: right;
    }

    .payment-method-billing-address .actions-toolbar .action-cancel {
        margin: 0 20px 0 0;
    }
}

@media (max-width: 991px) {
    .opc-block-summary {
        padding: 22px 20px 0;
    }

    .opc-wrapper .step-title {
        font-size: 32px;
        line-height: 34px;
    }
}

@media (max-width: 767px) {
    .checkout-index-index .form.payments {
        padding-right: 15px;
        padding-left: 15px;
    }

    .checkout-payment-method .checkout-agreements-block {
        margin-left: -15px;
    }

    .field-tooltip .field-tooltip-content {
        top: 42px;
        left: inherit;
        right: -5px;
        text-align: right;

        &:before {
            top: -20px;
            left: inherit;
            right: 8px;
            border-right-color: transparent;
            border-bottom-color: #f1f2f4;
        }
    }

    .opc-wrapper .step-title {
        font-size: 25px;
        line-height: 27px;
    }

    .opc-progress-bar {
        display: none;
    }

    .custom-slide {
        position: fixed;
        background: #fff;
        top: 0;
        right: 0;
        bottom: 0;
        left: inherit;
        min-width: 0;
        max-width: 400px;
        box-shadow: 0 -2px 7px 0 rgba(0, 0, 0, 0.4);
        transition: visibility 0s 0.3s, opacity 0.3s ease;
        opacity: 0;
        visibility: hidden;
        z-index: 900;

        &._show {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s ease;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 10 !important;
        }

        .block.items-in-cart {
            min-width: 343px;
        }
    }
}
