.footer-strap-line {
    border-top: 2px solid #eaeaea;
    text-align: center;
    padding: 25px 0;

    img {
        max-width: 55%;

        @include media-query(600px) {
            max-width: 80%;
        }
    }
}

.page-footer-before {
    position: relative;
    margin-top: 0;
    padding-bottom: 50px;
    background: $brand-secondary!important;
    &:before {
        content: '';
        background: rgb(45,41,38);
        background: linear-gradient(90deg, rgba(45,41,38,1) 0%, rgba(194,196,196,1) 10%, rgba(224,225,225,1) 40%, rgba(45,41,38,1) 80%, rgba(224,225,225,1) 90%, rgba(83,86,90,1) 99%);
        height: 10px;
        display: block;
        @media (max-width: 767px) {
            height: 5px;
        }
    }
    .col-big-gutter-right {
        padding-left: 40px;
    }

    .col-big-gutter-left {
        padding-right: 40px;
    }

    @include media-query(767px) {
        .col-big-gutter-right {
            padding-left: 15px;
        }

        .col-big-gutter-left {
            padding-right: 15px;
        }
    }

    .newsletter-footer-section {
        margin-top: 50px;
        margin-bottom: 40px;

        h4 {
            color: white;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 10px;
            font-family: 'effra';
        }

        .form-group {
            width: 100%;
            margin-bottom: 10px;

            .input-group {
                width: 100%;

                input {
                    width: 100%;
                    font-size: 13px;
                    padding-top: 10px;
                    height: auto;
                    padding-bottom: 10px;
                    padding-left: 15px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    border-radius: 4px;
                    color: #333333;
                }
            }
        }

        button {
            width: 100%;
            background: $brand-primary;
            color: white;
            font-family: 'proxima-nova', sans-serif !important;
            padding: 10px 20px;
            border-radius: 5px;
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            border: none;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 600;
            @include transitions(400ms);

            &:hover {
                background: $brand-hover;
                outline: 2px solid #fff;
                box-shadow: 0 0 0 4px $brand-hover;
            }
            &:focus {
                background: $brand-hover;
                outline: 2px solid #fff;
                box-shadow: 0 0 0 4px $brand-hover;
            }

            &:active {
                background: $brand-hover;
            }
        }

        @include media-query(420px) {
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
}

.footer-links {
    margin-top: 40px;
    margin-bottom: 10px;
    display: block;
    overflow: hidden;

    @include media-query(991px) {
        display: flex;
        flex-wrap: wrap;

        .footer-links-item {
            margin-bottom: 15px;
        }
    }

    @include media-query(767px) {
        margin-bottom: 15px;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 1px;
        height: calc(100% + 77px);
        background-color: rgba(255, 255, 255, 0.25);

        @include media-query(1199px) {
            height: calc(100% + 50px);
        }

        @include media-query(767px) {
            top: 100%;
            width: 100%;
            height: 1px;
        }
    }

    h4 {
        color: white;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 10px;
        font-family: 'effra';
    }

    .footer-links-item {
        position: relative;

        @include media-query(767px) {
            margin-bottom: 30px;

            &:before {
                cursor: pointer;
                position: absolute;
                right: 26px;
                top: 0;
                font-size: 18px;
                content: "\e970";
                font-family: "icomoon";
                color: white;
                transform: rotate(90deg);
                transition: transform 0.2s ease-in-out;
            }

            &.open {
                &:before {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    ul {
        padding-left: 0;
        list-style: none;
        margin: 0;
        padding: 10px 0 0 0;

        li {
            display: block;
            margin: 5px 0;

            a {
                text-decoration: none;
                font-family: inherit;
                font-weight: lighter;
                line-height: 14px;
                font-size: 14px;
                color: $pt-gray-04;
                &:hover {
                    text-decoration: underline;
                }

                &:active {
                    text-decoration: none;
                }

                &:focus {
                    text-decoration: none;
                }
            }
        }

        @include media-query(767px) {
            display: none;
        }
    }
}

.page-footer {
    padding-top: 40px;
    padding-bottom: 40px;
    background: $brand-secondary!important;
    .container:first-child {
        border-top: 1px solid $pt-gray-04;
        padding-top: 40px;
    }
    .footer-social,
    .footer-accreditations,
    .footer-getintouch {
        h4 {
            color: white;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 20px;
        }
        h5 {
            font-size: 15px;
            font-weight: 600;
        }
    }
    .footer-accreditations {
        width: 21%;
        @include media-query(1023px) {
            width: 100%;
        }
    }
    .footer-social-icons {
        width: 29%;
        @include media-query(1023px) {
            width: 100%;
        }
    }

    .footer-social {
        a.social-link {
            color: $brand-secondary;
            width: 40px;
            height: 40px;
            font-size: 23px;
            line-height: 40px;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
            border-radius: 8px;
            -moz-border-radius: 8px;
            -webkit-border-radius: 8px;
            text-decoration: none;
            background: white;
            @include transitions(400ms);
            margin-right: 5px;
            margin-bottom: 10px;
            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background: $brand-primary;
                color: #fff;
                text-decoration: none;
                .fa-tiktok {
                    &:before {
                        background: url('../images/tiktok-white.svg') no-repeat;
                    }
                }
            }

            &:active {
                background: $brand-primary;
                color: #fff;
                text-decoration: none;
            }

            &:focus {
                background: $brand-primary;
                color: #fff;
                text-decoration: none;
            }
            .fa-tiktok {
                &:before {
                    content: "";
                    background: url('../images/tiktok.svg') no-repeat;
                    display: inline-block;
                    height: 16px;
                    width: 15px;
                    border: none;
                    border-radius: 0;
                }
            }
        }
    }

    .copyright {
        font-size: 14px;
        padding-top: 20px;
        display: block;
    }

    .footer-getintouch {
        div {
            margin-bottom: 10px;
            p {
                margin-bottom: 5px;
            }
        }
        > p {
            font-size: 14px;
            margin-bottom: 15px;
        }
    }

    .footer-accreditations,
    .footer-social-icons,
    .footer-getintouch {
        @include media-query(991px) {
            margin-bottom: 30px;
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .footer-links .col-sm-6.footer-links-item {
        width: 49.5%;
    }
}
