#mobile-menu-btn {
  display: none;
  position: absolute;
  left: 0;
  top: 40px;
  width: 70px;
  height: 72px;
  background: $brand-secondary;
  color: white;
  padding-top: 48px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  cursor: pointer;
  @include media-query(710px) {
    height: 70px;
  }
  &:before {
    position:  absolute;
    left: 50%;
    top: 50%;
    content: "\f0c9";
    font-family: "FontAwesome";
    font-size: 30px;
    color: white;
    margin: -26px 0 0 -12px;
  }
}
.offcanvas-overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, 0.9);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  opacity: 0;
  transition: opacity 1s;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  cursor: pointer;
}

.offcanvas {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: $pt-gray-01;
  padding: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
}
#mobile-close-btn {
  position: absolute;
  left: -36px;
  top: 10px;
  width: 36px;
  height: 36px;
  background: white;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  cursor: pointer;
  z-index:9999;
  &.slideranimation {
    -webkit-animation: slide 0.2s forwards;
    -webkit-animation-delay: 1s;
    animation: slide 0.2s forwards;
    animation-delay: 1s;
  }
  &.outeranimation {
    -webkit-animation: hide 0.2s forwards;
    -webkit-animation-delay: 1s;
    animation: hide 0.2s forwards;
    animation-delay: 1s;
  }
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    background: url(../images/close-icon.svg) no-repeat center center;
    background-size: contain;
    margin: -10px 0 0 -10px;
  }
}
@-webkit-keyframes slide {
  100% { left: 291px; }
}

@keyframes slide {
  100% { left: 291px; }
}

@-webkit-keyframes hide {
  100% { left: -36px; }
}

@keyframes hide {
  100% { left: -36px; }
}

@include media-query(767px) {
  #mobile-menu-btn {
    display: block !important;
    cursor: pointer;
  }
}

.mobile-menu-container {
  overflow-x: hidden;
  .container {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .mx-megamenu.mobile-menu {
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        position: relative;
        background: $pt-gray-01;
        text-align: left;
        margin-top: -1px;
        padding: 0 8px 10px;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 18px;
          display: block;
          width: calc(100% - 36px);
          height: 2px;
          background-color: $pt-gray-04;
          -webkit-backface-visibility: hidden;
          -webkit-transform: translateZ(0) scale(1.0, 1.0);
          transform: translateZ(0);
        }
        &:last-child:after {
          content: none;
        }
        &:last-child {
          padding-bottom: 0;
          a {
            border-bottom: 1px solid #C2C4C4;
          }
        }
        .all-sub-cat {
          .nav-anchor.has-submenu, .close {
            display: none;
          }
        }
        a {
          font-family: 'effra';
          color: $brand-secondary;
          font-size: 13px;
          font-weight: 600;
          line-height: 17px;
          text-transform: uppercase;
          display: block;
          cursor: pointer;
          position: relative;
          padding-right: 25px;
          &:before {
            content: '';
            position: absolute;
            display: block;
            width: 15px;
            height: 12px;
            background: url(../images/arrow-down-black.svg) no-repeat center center;
            background-size: contain;
            right: 5px;
            top: 50%;
            margin-top: -6px;
          }
          span {
            span:not(.yo-ico) {
              display: inline-block;
              font-size: 13px;
              width: 100%;
              clear: right;
              cursor: pointer;
            }
          }
          &.mx-megamenu__link {
            padding-top: 14px!important;
            > span {
              display: inline-block;
              width: 90%;
              > span {
                width: auto;
                margin-right: 2px;
              }
            }
          }
        }
        .menu-footer-sections {
          .menu-footer-button {
            font-weight: bold;
          }
        }
        &.level0 {
          a:not(.social-media) {
            padding: 10px 0 10px;
            margin-right: 10px;
            margin-left: 10px;
          }
          .submenu {
            display: none;
            padding: 15px 0;
            a {
              &:before {
                display: none;
              }
            }
            .level1 {
              .mx-megamenu__link {
                  font-size: 14px;
                border-bottom: 2px solid $brand-primary;
              }
              .mx-megamenu__submenu {
                padding: 10px 0;
                + .mx-megamenu__submenu {
                 padding-top: 0; 
                }
                .category-item {
                  a {
                    text-transform: none;
                    color: #333;
                    font-size: 14px;
                    font-weight: 100;
                    border-bottom: none !important;
                  }
                }
              }
            }
            .level2 {
              a {
                padding: 5px 0;
              }
            }
          }
        }
        &.active-item {
          background: white;
          a {
            color: $pt-black;
          }
          > a {
            border-bottom: 1px solid #d4d4d4;
            &:before {
              background-image: url(../images/arrow-up-blue.svg);
            }
          }
          .mx-megamenu__footer {
            a {
              color: #fff;
            }
          }
        }
        &.no-children {
          a {
            &:before {
              display: none !important;
            }
          }
        }
        .social-media-outter {
          margin: -15px 10px 0;
          justify-content: space-between;
          .social-link {
            background: $pt-black;
            width: 38px;
            height: 38px;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0!important;
            i {
              &:before {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .manufacturer-2col-right .mx-megamenu__submenu {
      flex-direction: row;
      max-height: inherit;
      .level2 {
        width: 100%;
        flex-basis: 100%;
      }
    }
    .mx-megamenu__footer {
      .menu-footer-button + .menu-footer-button {
        margin-top: 8px;
      }
    }
  }
}