.products-grid {
	width: 100%;
	display: block;
	clear: both;
}

ol {
	list-style-type: none;
	&.widget-product-grid {
		width: 100%;
		margin: 0;
		padding: 0;
	}
}

.stock-availability-container {
	margin: 0;
	clear: both;
	display: block;
	width: 100%;
	padding: 15px 0;
	.product-availability {
		position: relative;
		display: inline-block;
		padding-right: 5px;
		padding-left: 26px;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 700;
		&.in-stock {
			color: $pt-green;
			&:before {
				font-family: 'FontAwesome';
				position: absolute;
				left: 0;
				top: -6px;
				color: $pt-green;
				content: "\f058";
				font-size: 22px;
				font-weight: 600;
			}
		}
		&.out-of-stock {
			color: $pt-orange;
			padding-left: 30px;
			&:before {
				font-family: 'icomoon';
				position: absolute;
				left: 0;
				top: -1px;
				color: $pt-black;
				content: "\e914";
				font-size: 16px;
				font-weight: 600;
			}
		}
		.product-stock-count {
		  display: inline;
		  font-family: inherit;
		  color: #333;
		  text-transform: none;
		}
	}
	.product-stock-count {
		position: relative;
		top: 0;
		font-size: 13px;
		font-weight: 700;
		display: inline;
		font-family: inherit;
		color: $pt-black;
	}
}

.listing {
	margin-bottom: 5px;
	font-size: 14px;
	.discount {
		font-weight: 700;
	}
	@include media-query(539px) {
		min-height: inherit;
	}
}

.catalogsearch-result-index,
.catalog-category-view,
.cms-index-index,
.amshopby-index-index,
.searchwidget-search-result,
.ambrand-index-index,
.wishlist-index-index {
	.products-grid {
		.row > [class*='col-'] {
			margin-bottom: 100px;
			@include media-query(1199px) {
				margin-bottom: 45px;
			}
			@include media-query(539px) {
				margin-left: auto;
				margin-right: auto;
				max-width: 244px;
			}
		}
	}
	.product-item-info {
		.product-item-actions {
			.actions-primary {
				button[type="submit"] {
					@media (max-width:550px) {
						width: calc(100% - 125px);
						line-height: 12px;
						padding-top: 4px;
						padding-bottom: 4px;
						.responsive-br {
							display: block;
						}
					}
					@include media-query(320px) {
						width: 100%;
						margin-top: 10px;
					}
				}
			}
		}
	}
}

.catalogsearch-result-index,
.catalog-category-view,
.cms-index-index,
.amshopby-index-index,
.searchwidget-search-result,
.ambrand-index-index {
	#maincontent {
		padding-right: 0;
		padding-left: 0;
		@include media-query(767px) {
			padding-right: 15px;
			padding-left: 15px;
		}
	}
	.columns {
		.column.main {
			padding-right: 0;
			@include media-query(991px) {
				padding-right: 15px;
			}
		}
	}

	.sidebar {
		.category-image-outter {
			margin: 0 auto;
			max-width: 270px;
			@include media-query(767px) {
				display: none;
			}
		}
		.category-image {
			margin: 7px auto 25px;
			border: 1px solid $pt-gray-04;
			border-radius: 10px;
			text-align: center;
			@include media-query(767px) {
				display: none;
			}
			img {
				display: block;
				position: relative;
				max-width: 100%;
				height: auto;
				border-radius: 10px;
			}
		}
	}
	#maincontent .columns {
		@include media-query(991px) {
			margin-right: -15px;
    		margin-left: -15px;
		}
		> .column, + .sidebar {
			@include media-query(767px) {
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
	.search-block-outter, .marketing-block {
		@include media-query(767px) {
			display: none;
		}
	}
	.mobile-bottom-actions {
		display: none;
		max-width: 100%;
		@include media-query(767px) {
			display: block;
			margin-top: 60px;
			.search-block-outter, .marketing-block {
				display: block;
			}
			.search-block-outter {
				margin-bottom: 35px;
			}
		}
	}
}

.catalog-category-view.category-shop-by-category {
	#maincontent .columns {
		@include media-query(991px) {
			margin-right: 0;
			margin-left: 0;
			> .column, + .sidebar {
				padding-right: 15px;
				padding-left: 15px;
			}
		}
		@include media-query(767px) {
			margin-right: -15px;
			margin-left: -15px;
		}
	}
	.mobile-bottom-actions {
		display: none;
	}
}

.catalog-category-view, .ambrand-index-index {
	.product-items {
		.product-item {
			margin-bottom: 20px;
			padding-top: 20px;
			border-top: 1px solid $pt-gray-04;
			.product-item-details {
				.product-item-name {
					a {
						text-transform: uppercase;
					}
				}
			}
			.product-item-inner {
				.product-item-actions {
					.actions-primary {
						form {
							.tocart.primary {
								padding-top: 12px;
								padding-bottom: 12px;
							}
							.towishlist {
								position: unset;
								display: flex;
								justify-content: center;
								align-items: center;
								margin-top: 10px;
								width: 100%;
								border-radius: 5px;
								background: $pt-black;
								color: #fff;
								transition: all 0.15s ease-out;
								font-family: 'effra';
								font-size: 12px;
								text-transform: uppercase;
								padding-top: 8px;
								padding-bottom: 8px;
								&:before {
									color: #fff;
									font-size: 16px;
									margin-right: 5px;
								}
								&:hover {
									background: $pt-black;
									outline: 2px solid #fff;
									box-shadow: 0 0 0 4px $pt-black;
									color: #fff;
								}
								&:focus {
									background: $pt-black;
									outline: 2px solid #fff;
									box-shadow: 0 0 0 4px $pt-black;
									color: #fff;
								}
							}
						}
					}
				}
			}
		}
	}
}

.product-item-info, #amasty-shopby-product-list {
	.price-box {
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
		content: '';
		clear: both;
		> .price-container {
			width: 100%;
		}
		&:after {
			display: table;
    		clear: both;
    		content: '';
		}
		.price-including-tax {
			clear: both;
			display: inline;
			float: left;
			position: relative;
			span {
				&.price {
					color: #333333;
					font-size: 15px;
					font-weight: bold;
					line-height: 25px;
					&:after {
						position: absolute;
						top: 50%;
						left: 100%;
						content: "inc VAT";
						width: 60px;
						margin-top: -12px;
						font-size: 12px;
						font-weight: 500;
						padding-left: 5px;
					}
				}
			}
		}
		.price-excluding-tax {
			clear:both;
			display: inline;
			float: left;
			position: relative;
			span {
				&.price {
					font-weight: bold;
					color: #333333;
					font-size: 30px;
					line-height: 25px;
					&:after {
						position: absolute;
						top: 50%;
						left: 100%;
						content: "ex VAT";
						width: 70px;
						margin-top: -8px;
						font-size: 15px;
						font-weight: 500;
						padding-left: 5px;
					}
				}
			}
		}
		.pricing-divider {
			float: left;
			display: block;
			width: 100%;
			border-bottom: 1px solid #333333;
		}
		.minimal-price-link {
			display: block;
			clear: both;
			margin-bottom: 6px;
			font-weight: 700;
			.pricing-divider {
				display: none;
			}
		}
	}
}

.tabbed-category, .tabbed-search {
	.catalog.data.items {
		> .item.title {
			border-top: none;
			background: $pt-gray-04;
			&:first-child {
				border-radius: 10px 0 0 0;
				&:active {
					&:before {
						width: 0;
					}
				}
			}
			&:nth-child(3) {
				border-radius: 0 10px 0 0;
				&:active {
					border-radius: 0 10px 0 0;
				}
			}
			&:after {
				background-color: $pt-gray-08;
			}
			&:hover {
				background: $pt-gray-06;
				a.switch {
					color: $pt-black;
				}
			}
			
			a.switch {
				color: $pt-black;
				font-family: 'effra';
				font-weight: bold;
				&:hover {
					color: $pt-black;
				}
			}
			&.active {
				background: $brand-secondary;
				color: #fff;
				border-top: none;
				a.switch {
					color: #fff;
					.collection-total {
						span {
							color: #fff;
						}
					}
				}
			}
			.collection-total {
				margin-left: 5px;
				background: none;
				width: auto;
				height: auto;
				span {
					@include media-query(767px) {
						line-height: 10px;
					}
				}
			}
		}
	}
}

.sidebar-main {
	.search-block-outter {
		.search-block {
			background: $brand-secondary;
			border-radius: 5px;
			.search-block-content {
				h3 {
					font-family: 'effra';
					font-weight: bold;
					font-size: 20px;
					text-transform: capitalize;
				}
				button {
					text-align: left;
					padding: 10px 12px;
					span {
						font-family: 'effra';
						font-weight: bold;
						&:before {
							position: absolute;
							font-family: 'icomoon' !important;
							content: "\e912";
							right: -3px;
							top: 50%;
							width: 22px;
							height: 22px;
							margin-top: -15px;
							font-size: 22px;
						}
					}
				}
			}
		}
	}
}
.search-block-outter {
	.search-block {
		background: $brand-secondary;
		border-radius: 5px;
	}
}

.catalogsearch-result-index,
.catalog-category-view,
.cms-index-index,
.catalog-product-view,
.ambrand-index-index,
.searchwidget-search-result {
	#maincontent {
		@include media-query(991px) {
			width: 100%;
		}
	}
	.sidebar-all {
		@include media-query(991px, 768px) {
			width: 32.6%;
		}
		@include media-query(767px) {
			float: none;
			margin-top: 15px;
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.catalogsearch-result-index,
.catalog-category-view,
.cms-index-index,
.catalog-product-view,
.amshopby-index-index,
.ambrand-index-index,
.searchwidget-search-result,
.wishlist-index-index {
	.sidebar-main {
		[title="Customer Reviews"] {
			display: block;
			img {
				margin: 0 auto;
			}
		}
		@include media-query(767px) {
			[title="Customer Reviews"] {
				display: none;
			}
		}
	}
	.mobile-bottom-actions {
		[title="Customer Reviews"] {
			display: block;
			img {
				margin: 0 auto;
			}
		}
	}
	.category-description {
		margin: 30px auto;
		float: none;
		@include media-query(767px) {
			margin-top: 15px;
		}
	}

	.category-children {
		margin-bottom: 30px;

		ul, ol {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;

				a {
					font-size: 13px;
					text-decoration: underline;

					&:hover {
						font-weight: bold;
						font-size: 13px;
					}

					&:focus {
						font-weight: 600;
						font-size: 13px;
					}
				}
			}

			li + li {
				margin-left: 10px;
				padding: 10px;
				font-size: 14px;
				font-weight: 500;
			}
		}
	}

	.product-item-name .grid-wish-compare {
		display: inline-block;
		float: right;
		font-size: 24px;
		line-height: 1;
		vertical-align: middle;

		.towishlist:hover {
			i {
				color: #FF0000 !important;
			}
		}

		.tocompare:hover {
			i {
				color: #000033 !important;
			}
		}
	}

	#amasty-shopby-product-list {
		.products.wrapper {
			clear: both;
		}
	}

	.products-list {
		.product-items {
			.MagicToolboxContainer {
				padding: 0;
				&.placeholder img {
					vertical-align: top;
				}
			}
		}
		.product-item {
			margin-bottom: 65px;
			padding-top: 20px;
			border-top: 1px solid $pt-gray-04;
		}
		.product-item-info  {
			.product-item-attributes {
				border-bottom: none;
				min-height: 0;
				margin-top: 5px;
				padding-bottom: 0;
			}
			.product-item-name {
				height: auto;
				padding-top: 0;
			}
			.stock-availability-container {
				padding-top: 5px;
			}
		}
	}

	.products-grid {
        &.products-customtype {
            padding-top: 25px;
            border-bottom: 4px solid #eaeaea;
        }
        .product-item-photo {
			display: block;
			overflow: hidden;
		}

		.swatch-option {
			width: 20px;
			min-width: auto;
			margin: 0 5px 5px 0;
		}
	}

	.block-products-list {
		.row > [class*='col-'] {
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;
		}

		.product-item-photo {
			display: block;
			overflow: hidden;
		}

		.swatch-option {
			width: 20px;
			min-width: 100%;
			margin: 0 5px 5px 0;
		}
	}

	.products.wrapper + div.toolbar-products {
		> .show-options .toolbar-sorter, >.show-options .limiter, >.display-mode .modes {
			display: none;
		}
		> .toolbar-amount {
			margin-right: 20px;
			@include media-query(1199px, 768px) {
				margin-right: 0;
			}
			@include media-query(767px) {
				margin-right: 7px;
			}
			@include media-query(540px) {
				margin-right: 0;
				text-align: center;
				margin-bottom: 8px;
				max-width: 100%;
			}
		}
	}

	div.toolbar-products {
		&:first-child {
			padding: 10px 20px;
			font-size: 0;
			@include media-query(420px) {
				padding: 15px 20px;
			}
			.show-options, .display-mode {
				display: inline-block;
				vertical-align: middle;
				font-size: 14px;
			}
			.show-options {
				width: calc(100% - 82px);
				border-right: 1px solid #c0c0c0;
				@include media-query(520px) {
					border-right: none;
					border-bottom: 1px solid #c0c0c0;
					width: 100%;
					padding-bottom: 15px;
				}
			}
			label {
				display: inline-block;
				width: 100%;
				vertical-align: middle;
				margin: 0 10px 8px 0;
				color: $pt-black;
				font-weight: 700;
				text-transform: capitalize;
				font-size: 14px;
				line-height: 14px;
				font-family: 'effra';
				@include media-query(991px) {
					display: block;
					margin-bottom: 8px;
				}
			}
			.select-field {
				background: none;
				select {
					width: 100%;
				}
			}

			select {
		      color: #373737;
		      padding: 6px 35px 6px 10px;
		      max-width: 100%;
		      margin: 0;
		      border: 1px solid $pt-gray-04;
		      box-shadow: none;
		      border-radius: 5px;
		      -moz-appearance: none;
		      -webkit-appearance: none;
		      appearance: none;
		      background-color: #fff;
		      background-image: url('../images/arrow-down-black.svg');
		      background-repeat: no-repeat;
		      background-position: calc(100% - 10px) center;
		      background-size: 20px 10px;
			  font-family: 'effra';
			  &:focus {
				border-color: $pt-black;
				-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
			  }	
		    }
		    select::-ms-expand {
		      display: none;
		    }
		    select:focus {
		      outline: none;
		    }
		    select option {
		      font-weight: normal;
		    }

			.toolbar-sorter, .limiter {
				width: 100%;
			}
			.toolbar-sorter {
				max-width: 265px;
				margin-right: 20px;
				@include media-query(991px) {
					max-width: calc(60% - 22px);
				}
				@include media-query(520px) {
					max-width: calc(60% - 10px);
					margin-right: 8px;
				}
				@include media-query(420px) {
					max-width: 100%;
					margin-right: 0;
					margin-bottom: 20px;
				}
				.select-field, .sorter-action {
					display: inline-block;
					vertical-align: middle;
				}
				.select-field {
					@include media-query(991px) {
						width: calc(100% - 38px);
					}
				}
				.sorter-action {
					position: relative;
					background: #fff;
					border: none;
					border-radius: 5px;
					height: 34px;
					width: 34px;
					line-height: 24px;
					.icon-arrow-down, .icon-arrow-up {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%) scale(1,1);
						display: block;
						width: 12px;
						height: 16px;
						background: url('../images/full-down-arrow.svg') no-repeat center center;
						background-size: contain;
					}
					.icon-arrow-up {
						transform: translate(-50%,-50%) scale(1,-1);
					}
				}
			}
			.limiter {
				max-width: 185px;
				@include media-query(991px) {
					max-width: calc(40% - 22px);
					margin-right: 20px;
					select {
						width: 100%;
					}
				}
				@include media-query(520px) {
					max-width: calc(40% - 10px);
					margin-right: 0;
					margin-left: 8px;
				}
				@include media-query(420px) {
					max-width: 100%;
					margin-left: 0;
				}
			}
			.display-mode {
				width: 82px;
				@include media-query(520px) {
					width: 100%;
					.modes {
						padding-left: 0;
						padding-top: 10px;
						text-align: right;
					}
				}
			}
			.toolbar-sorter, .limiter, .modes {
				display: inline-block;
				vertical-align: middle;
				font-size: 14px;
			}
			>.pages, >.toolbar-amount {
				display: none;
			}
		}
	}

	#diagrams-list .show-options {
		width: 100%;
		border-right: none;
		.limiter {
			@include media-query(768px) {
				max-width: calc(40% - 2px);
				margin-right: 0;
			}
			@include media-query(520px) {
				max-width: calc(40% - 10px);
			}
			@include media-query(420px) {
				max-width: 100%;
			}
		}
	}

	.product-item-info {
		div.product-image-container {
			position: relative;
			border: 1px solid $pt-gray-04;
			overflow: hidden;
			max-height: 244px;
			width: calc(100% - 2px);
			.product.photo {
				display: block;
				text-align: center;
			}
			span {
				margin: 0;
				padding: 0;
				span {
					margin: 0;
					padding: 0;
				}
			}
			img {
				position: relative;
				display: inline-block;
				width: auto;
				height: 100%;
				max-width: 100%;
				max-height: 244px;
				object-fit: contain;
				font-family: 'object-fit: contain;'; // IE Polyfill : https://github.com/fregante/object-fit-images
				margin: 0 auto;
				z-index:4;
			}
			.MagicToolboxContainer {
				.Magic360-container {
					position: static !important;
					margin: 0 auto;
					&:before {
						content: '';
						position: absolute;
						right: 38px;
						bottom: -3px;
						z-index: 10;
						background: white url('../images/360-icon.svg') no-repeat center center;
						background-size: 22px 22px;
						border: 1px solid #eaeaea;
						width: 40px;
						height: 40px;
						text-align: center;
						padding: 6px;
						box-sizing: border-box;
					}
				}
				.Magic360, .Magic360-container {
					border: none;
				}
				.Magic360-container {
					display: block !important;
				}
			}
			.actions-secondary, [data-role="add-to-links"] {
				.tocompare {
					display: none;
				}
				[data-action="add-to-wishlist"] {
                    display: none;
                    position: absolute;
					right: -1px;
					bottom: -3px;
					z-index: 10;
					background: white;
					border: 1px solid #eaeaea;
					width: 140px;
					height: 40px;
					text-align: center;
					padding: 6px;
					box-sizing: border-box;
					&:before {
						font-family: 'FontAwesome';
						content: "\f08a";
						color: #333333;
						font-size: 20px;
					}
					span {
						display: none;
					}
					i {
						display: none;
					}
				}
			}
		}
		.product-item-name {
			text-transform: capitalize;
			color: #333333;
			font-size: 20px;
			line-height: 1.15;
			font-family: inherit;
			font-weight: 100;
			display: block;
			padding: 10px 0;
			a {
				color: $brand-primary;
				text-decoration: none;
				&:hover {
					text-decoration: none;
				}
				&:active {
					text-decoration: none;
				}
				&:focus {
					text-decoration: none;
				}
			}
		}

		.product-item-attributes {
			border-bottom: 1px solid #ddd;
			margin-top: 15px;
			padding-bottom: 15px;
			.product-item-info {
				font-size: 14px;
				line-height: 1.2;
				margin-bottom: 6px;
				strong {
					display: block;
					color: $pt-black;
				}
			}
		}

		.product-reviews-summary {
			/* Hidden for now */
			display: none;
		}

		.amshopby-option-link {
			display: none;
		}

		.product-item-actions {
			clear: both;
			width: 100%;
			margin: 0;
			padding-top: 15px;
			.actions-primary {
				position: relative;
				clear: both;
				.field.qty {
					border: 1px solid $pt-gray-04;
					background:#fff;
					margin: 0;
					width: 110px;
					position: relative;
					display: inline-block;
					vertical-align: top;
					margin-right: 10px;
					-webkit-border-radius: 4px;
					-moz-border-radius: 4px;
					border-radius: 4px;
					padding: 5px;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
					input {
						background: #fff;
						text-align: center;
						border: none;
						width: 50px;
						margin-left: 0;
						@include media-query(1199px, 992px) {
							width: 70%;
						}
					}
					button {
						margin: 0;
						padding: 0;
						width: 20px;
						height: 20px;
						background: $brand-secondary;
						font-family: inherit;
						display: inline-block;
						line-height: 20px;
						border: none;
						&.decrement {
							position: absolute;
							left: 10px;
							top: 50%;
							margin-top: -10px;
							color: white;
							font-size: 20px;
						}
						&.increment {
							position: absolute;
							right: 10px;
							top: 50%;
							margin-top: -10px;
							color: white;
							font-size: 16px;
						}

						&:hover {
							outline: none;
							background: $pt-gray-09;
							color: white;
						}
						&:active, &:focus {
							outline: none;
						}
					}
					@media (max-width:400px) {
						width: 110px;
					}
					@include media-query(320px) {
						width: 100%;
						text-align: center;
						margin-right: 0;
						input {
							width: 100%;
							margin-left: 0;
						}
					}
				}
				button[type="submit"] {
					display: inline-block;
					vertical-align: top;
					width: calc(100% - 125px);
					padding-left: 10px;
					padding-right: 10px;
					&:hover, &:active, &:focus {
						padding-left: 10px;
						padding-right: 10px;
					}
					.responsive-br {
						display: none;
					}
				}
			}
		}
	}
	.products-grid .product-items-model.row > [class*='col-'] {
		margin-bottom: 30px;
	}

	.document-section {
		.select {
			background: #ffffff url('../images/arrow-down-black.svg') no-repeat calc(100% - 10px) center;
			color: #373737;
			font-weight: 400;
			background-size: 20px 10px;
			padding: 11px 35px 10px 10px;
			border: 1px solid $pt-gray-04;
			border-radius: 5px;
			box-shadow: none;
			appearance: none;
			&:focus {
				border-color: $pt-black;
				-webkit-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				-moz-box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
				box-shadow: 0px 0px 4px 4px rgba(45,41,38,0.25);
			}
		}

		
	}
	.row-model {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.product-item-model {
		display: flex;
		margin-bottom: 30px;
		padding-right: 15px;
		padding-left: 15px;
		width: 50%;
		flex-basis: 50%;
		@include media-query(991px, 768px) {
		    width: 100%;
		    flex-basis: 100%;
		}
		@include media-query(767px) {
		    width: 50%;
		    flex-basis: 50%;
		}
		@include media-query(580px) {
		    width: 100%;
		    flex-basis: 100%;
		}
	}

	.product-item-model > .product-item-info {
		border: 2px solid #f1f2f4;
		padding: 20px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		height: 100%;
		> * {
		    width: 100%;
		    flex-basis: 100%;
		}
		.model-content {
			font-size: 0;
			.model-infos, .model-media {
				display: inline-block;
				vertical-align: top;
			}
			.model-infos {
				width: calc(100% - 148px);
				font-size: 12px;
				@include media-query(992px, 768px) {
					width: calc(100% - 88px);
				}
				.product-item-name {
					height: auto;
					margin-bottom: 15px;
					padding: 0;
					font-size: 18px;
					font-weight: 400;
					text-transform: none;
				}
				.product-item-attributes {
					min-height: 0;
					border-bottom: none;
					.product-item-attributes {
						margin-bottom: 3px;
					}
					strong {
						display: inline;
					}
				}
			}
			.model-media {
				width: 128px;
				margin-left: 20px;
				@include media-query(992px, 768px) {
					width: 68px;
				}
				div.product-image-container {
					height: auto;
				}
				.actions-secondary {
					font-size: 0;
					padding-left: 0;
					.zoom, [data-action="add-to-wishlist"] {
						display: inline-block;
						vertical-align: middle;
					}
					.zoom {
						width: 100%;
						height: 30px;
						border-top: 1px solid #eaeaea;
						font-size: 12px;
						line-height: 30px;
						text-align: center;
						text-transform: uppercase;
						@include media-query(992px, 768px) {
							width: 50%;
							line-height: 7px;
						}
						a {
							display: inline-block;
							width: 100%;
							color: #333333;
							@include media-query(992px, 768px) {
								font-size: 0;
							}
							&:before {
								content: '';
								display: inline-block;
								vertical-align: inherit;
								vertical-align: text-top;
								font-size: 12px;
								width: 15px;
								height: 15px;
								margin-right: 4px;
								background: url('../images/yoma-icon/zoom.svg') no-repeat center center;
								background-size: contain;
							}
						}
					}
					[data-action="add-to-wishlist"] {
						position: relative;
						width: 30px;
						height: 30px;
						line-height: 17px;
						bottom: 0;
						right: 0;
						display: inline-block;
						border-bottom: none;
						border-right: none;
						@include media-query(992px, 768px) {
							width: 50%;
						}
						&:before {
							font-size: 17px;
						}
					}
				}
			}
		}
		.model-actions {
			align-self: flex-end;
			border-top: 1px solid #eaeaea;
			font-size: 0;
			margin-top: 15px;
			padding-top: 10px;
			.button {
				display: inline-block;
				width: 100%;
				font-size: 13px;
				line-height: 12px;
				padding: 8px 10px;
				text-align: center;
			}
			.document, .part {
				display: inline-block;
				vertical-align: middle;
				font-size: 14px;
				font-weight: 700;
			}
			.document {
				max-width: 227.5px;
				width: 100%;
				.doc-title {
					margin: -17px 0 7px;
					color: $brand-primary;
					font-size: 12px;
					font-weight: 700;
					text-transform: uppercase;
					span {
						display: inline-block;
						background-color: #fff;
						padding-right: 8px;
					}
				}
			}
			.document-section {
				font-size: 0;
				.select, .button {
					display: inline-block;
					vertical-align: middle;
					font-size: 14px;
				}
				.select {
					width: calc(100% - 105px);
					margin-right: 5px;
				}
				.button {
					max-width: 100px;
				}
			}
			.part {
				margin-top: 3px;
				.button {
					max-width: 120px;
					padding-top: 14px;
					padding-bottom: 14px;
				}
			}
			&.with-doc {
				.document {
					max-width: calc(100% - 80px);
					.document-block {
						padding-right: 15px;
						margin-right: 15px;
						border-right: 1px solid #eaeaea;
					}
					@mixin responsivedoc {
						max-width: 100%;
						.document-block {
							padding-right: 0;
							padding-bottom: 15px;
							margin-right: 0;
							margin-bottom: 12px;
							border-right: none;
							border-bottom: 1px solid #eaeaea;
						}
					}
					@include media-query(1199px, 768px) {
						@include responsivedoc();
					}
					@include media-query(440px) {
						@include responsivedoc();
					}
				}
				.part {
					.button {
						max-width: 80px;
						padding-top: 8px;
						padding-bottom: 8px;
					}
				}
			}
		}
	}
	#diagrams-list {
		.toolbar-documents {
			display: none;
		}
	}
	.products-list .product-items-documents {
		border-bottom: 1px solid #eaeaea;
		margin-bottom: 70px;
		.product-item-document {
			margin-bottom: 0;
			padding: 10px 20px;
			@include media-query(767px) {
				padding: 20px;
			}
			&:nth-child(odd) {
				background-color: #f1f2f4;
			}
			.product-item-name {
				@include media-query(991px) {
					margin-bottom: 15px;
				}
			}
			.document-section {
				font-size: 0;
				label, .select, .button {
					display: inline-block;
					vertical-align: middle;
					font-size: 14px;
				}
				label {
					width: 110px;
					margin-right: 15px;
					margin-bottom: 0;
					font-size: 13px;
					font-weight: 700;
					line-height: 13px;
					color: $brand-primary;
					text-transform: uppercase;
					@include media-query(640px) {
						width: 100%;
						margin-right: 0;
						margin-bottom: 10px;
					}
				}
				.select {
					width: calc(100% - 235px);
					background-color: #fff;
					padding: 10px 35px 9px 10px;
					border: 1px solid #e0e1e3;
					@include media-query(640px) {
						width: calc(100% - 110px);
					}
				}
				.button {
					width: 100px;
					margin-left: 10px;
					font-size: 12px;
					line-height: 12px;
				}
			}
		}
	}
}

.cms-index-index {
	.product-item-info {
		.product-item-actions {
			.actions-primary {
				.field.qty {
					@include media-query(1199px, 992px) {
						width: 100%;
					}
					@include media-query(991px, 700px) {
						width: 110px;
					}
				}
				button[type="submit"] {
					@include media-query(1199px, 992px) {
						width: 100%;
						margin-top: 10px;
					}
					@include media-query(991px, 700px) {
						width: calc(100% - 125px);
						line-height: 12px;
						padding-top: 4px;
						padding-bottom: 4px;
						.responsive-br {
							display: block;
						}
					}
				}
			}
		}
	}
}

.catalog-category-view, .catalogsearch-result-index, .ambrand-index-index, .wishlist-index-index, .searchwidget-search-result {
	.page-title-wrapper {
		&.page-title-wrapper-mobile {
			display: none;
			padding-left: 0;
			padding-right: 0;
		}
		@include media-query(767px) {
			display: none;
			&.page-title-wrapper-mobile {
				display: block;
			}
		}
	}
	.product-item-info {
		.product-item-actions {
			.actions-primary {
				.field.qty {
					@media (min-width: 992px) and (max-width: 1199px) {
						width: 110px;
						input {
							margin-left: 12px;
						}
					}
				}
				button[type="submit"] {
					@media (min-width: 768px) and (max-width: 1199px) {
						width: calc(100% - 125px);
						line-height: 12px;
						padding-top: 4px;
						padding-bottom: 4px;
						.responsive-br {
							display: block;
						}
					}
				}
			}
		}
	}
}

.catalog-category-view, .ambrand-index-index, .catalogsearch-result-index, .searchwidget-search-result {
	#maincontent {
		.page-title {
			text-align: left;
		}
	}
	.category-children {
		margin-bottom: 30px;
		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			li{
				display: inline-block;
				a{
					font-size: 13px;
					text-decoration: underline;
					&:hover{
						font-weight:bold;
						font-size: 13px;
					}
					&:focus{
						font-weight:600;
						font-size: 13px;
					}
				}
			}
			li+li{
				margin-left: 10px;
				padding: 10px;
				font-size: 14px;
				font-weight: 500;
			}
		}
	}
	.column.main {
		padding-top: 0;
	}

	.page-title {
		font-size: 36px;
	}

	.toolbar-products {
		background: $pt-gray-01;
		padding: 10px;
		box-sizing: border-box;
		margin-bottom: 25px;
		.modes {
			width: 100%;
			text-align: center;
			padding-left: 10px;
			a {
				border: none;
				background: transparent;
				box-shadow: none;
				color: $brand-primary;
				padding: 5px 7px;
				cursor: pointer;
				.icon-list, .icon-grid {
					display: inline-block;
					width: 18px;
					height: 18px;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
				}
				.icon-list {
					background-image: url('../images/list-icon.svg');
				}
				.icon-grid {
					background-image: url('../images/grid-icon.svg');
				}
				&.active {
					color: #333333;
					cursor: default;
					.icon-list {
						background-image: url('../images/list-icon-active.svg');
					}
					.icon-grid {
						background-image: url('../images/grid-icon-active.svg');
					}
				}
				span {
					font-size: 18px;
				}
			}
		}
	}
	#models-list .toolbar-products {
		.show-options {
			border-right: none;
		}
		.display-mode {
			display: none;
		}
	}
	.products-grid + .toolbar-products, .products-list + .toolbar-products {
		margin-bottom: 60px;
		padding: 30px 0 0;
		background-color: transparent;
		border-top: 4px solid #eaeaea;
		text-align: right;
		@include media-query(768px) {
			text-align: center;
			margin-bottom: 15px;
		}
		.toolbar-amount, .pages {
			display: inline-block;
			vertical-align: middle;
			@include media-query(540px) {
				display: block;
			}
		}
		p.toolbar-amount {
			max-width: 100px;
			margin-right: 20px;
			margin-bottom: 0;
			text-align: left;
			@include media-query(1199px) {
				max-width: 90px;
			}
			@include media-query(1199px, 768px) {
				width: 100%;
				max-width: 100%;
				margin-right: 0;
				margin-bottom: 15px;
				text-align: center;
			}
		}
		.pages {
			max-width: calc(100% - 145px);
			width: 100%;
			min-height: 45px;
			@include media-query(1199px, 768px) {
				width: 100%;
				max-width: 100%;
				height: auto;
				text-align: center;
				.pagination-lg > li {
					&:not(.hellip) {
						a, span {
							padding: 6px 10px;
							font-size: 16px;

						}
					}
					&.pages-item-previous, &.pages-item-next {
						span {
							padding: 0;
						}
					}
				}
			}
			@include media-query(767px) {
				width: calc(100% - 101px);
				max-width: 100%;
				min-height: 37px;
				text-align: right;
				.pagination-lg > li {
					&:not(.hellip) {
						a, span {
							font-size: 15px;
							padding: 5px 9px;
						}
					}
				}
			}
			@include media-query(540px) {
				width: 100%;
				height: auto;
				text-align: center;
			}
		}
	}
}
.catalog-product-view-model {
    .row-model {
        display: block;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}
.catalog-category-view .products-list + .toolbar-products {
	border-top: 4px solid $pt-black;
}
.pagination {
	display: flex;
	justify-content: flex-end;
	> li.item {
		span {
			display: flex;
			justify-content: center;
			min-width: 45px;
		}
		> a {
			border: 1px solid $brand-secondary;
			background: #fff;
			color: $pt-black;
			transition: all 0.15s ease-out;
			&:hover {
				background: $pt-gray-09;
				outline: 2px solid #fff;
				box-shadow: 0 0 0 4px $pt-gray-09;
				color: #fff;
			}
			&:focus {
				background: $pt-gray-09;
				outline: 2px solid #fff;
				box-shadow: 0 0 0 4px $pt-gray-09;
				color: #fff;
			}
		}
		&.active {
			span {
				background: $brand-secondary;
				border: 1px solid $brand-secondary;
			}
		}
		&.pages-item-next {
			a {
				background: $brand-primary;
				border: 1px solid $brand-primary;
				&:hover {
					background: $brand-hover;
					color: #fff;
					border: 1px solid $brand-hover;
				}
			}
		}
	}
}
.product-items.slick-slider .slick-slide .product-item {
    max-width: 100%!important;
}

.actions-primary{
    [data-action="add-to-wishlist"] {
        position: unset;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		width: 100%;
		border-radius: 5px;
		background: $pt-black;
		color: #fff;
		transition: all 0.15s ease-out;
		font-family: 'effra';
		font-size: 12px;
		text-transform: uppercase;
		padding-top: 8px;
		padding-bottom: 8px;
		&:before {
			color: #fff;
			font-size: 16px;
			margin-right: 5px;
		}
		&:hover {
			background: $pt-black;
			outline: 2px solid #fff;
			box-shadow: 0 0 0 4px $pt-black;
			color: #fff;
		}
		&:focus {
			background: $pt-black;
			outline: 2px solid #fff;
			box-shadow: 0 0 0 4px $pt-black;
			color: #fff;
		}
        &:before {
            font-family: 'FontAwesome';
            content: "\f08a";
            color: #ffffff;
            font-size: 16px;
        }
        span {
            display: none;
        }
        i {
            display: none;
        }
    }
}


.amshopby-descr {
	p, ul li {
		a {
			color: $brand-primary;
			&:hover {
				color: $brand-hover;
			}
		}
	}
}
.catalog-product-view {
	.product-item {
		.product-item-info {
			.product-item-info-secondary {
				.product-item-inner {
					.product-item-actions {
						.actions-primary {
							form {
								button[type="submit"] {
									@media (min-width: 768px) and (max-width: 1023px) {
										width: 100%;
										margin-top: 10px;
									}
								}
								.action.towishlist {
									&:before {
										content: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
