.breadcrumbs-container {
  width: 100%;
  color: #2f2f31;
  text-transform: capitalize;
  font-size: 12px;
  background: #fff;
  .breadcrumb, .breadcrumbs {
    padding: 12px 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;

    a {
      color: $brand-primary;
      text-decoration: none;
      font-size: 12px;
      &:hover {
        text-decoration: none;
        outline: 0;
      }
      &:active {
        text-decoration: none;
        outline: 0;
      }
      &:focus {
        text-decoration: none;
        outline: 0;
      }
    }

    > li {
      display: inline-block;
      position: relative;
      margin-right: 30px;
      padding-left: 10px;
      padding-top: 2px;
      &:first-child {
        padding-left: 0;
      }
      + li:before {
        position: absolute;
        font-family: 'icomoon';
        left: -20px;
        top: 0;
        content: "/";
        color: $pt-black;
        font-size: 14px;
        font-weight: bold;
      }
    }

    > .active {
      color: #2f2f31;
    }
  }
  .breadcrumb-mobile {
    li.home {
      a {
        display: inline-block;
        padding-left: 0px;
        font-weight: 700;
      }
    }
    li:before {
      position: absolute;
      font-family: 'icomoon';
      left: 0;
      top: -18px;
      content: none;
      color: #e0e1e3;
      font-size: 41px;
      font-weight: normal;
      transform: scaleX(-1);
      pointer-events: none;
    }
  }
}