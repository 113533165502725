.contact-index-index {
    .columns .column.main {
        margin-top: 20px;
        padding-right: 0;
        padding-left: 0;
    }
    .right {
        float: right !important;
    }
    h3 {
        margin: 0 0 10px;
        padding: 0 0 4px 0;
        text-transform: uppercase;
        color: #0051b2;
        font-weight: bold;
        font-size: 26px;
        font-family: inherit;
        border-bottom: 2px solid #0051b2;
    }
    .block-contact-info {
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;

        .detail-column {
            &:first-child {
                border-right: 1px solid #d1d1d1;
                @include media-query(991px) {
                    border-right: none;
                    border-bottom: 1px solid #d1d1d1;
                    padding-bottom: 10px;
                    margin-bottom: 5px;
                }
            }
            text-align: center;

            h4 {
                color: $brand-primary;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 800;
            }
            p {
                font-size: 12px;
                color: #646464;
                padding: 0 20px;
            }
        }
        .contact-column {
            width: 50px;
            height: 50px;
            text-align: center;
            display: inline-block;
            position: relative;
            margin-bottom: 10px;
            &.phone-icon {
                &:before {
                    position: absolute;
                    content: "\e97c";
                    font-family: 'icomoon';
                    font-size: 36px;
                    color: #333;
                    left: 50%;
                    top: 50%;
                    margin-left: -18px;
                    margin-top: -18px;
                }
            }
            &.address-icon {
                &:before {
                    position: absolute;
                    content: "\e980";
                    font-family: 'icomoon';
                    font-size: 36px;
                    color: #333;
                    left: 50%;
                    top: 50%;
                    margin-left: -18px;
                    margin-top: -18px;
                }
            }
        }
    }

    .amgooglemap_mapblock {
        display: block;
        margin-bottom: 20px;
    }

    .block-get-directions {
        display: inline-block;
        padding-top: 20px;
        width: 100%;

        div[role="tablist"] {
            div[role="tab"] {
                width: 100%;
                color: $brand-primary;
                font-size: 16px;
                cursor: pointer;
                padding: 14px 0 10px 0;
                font-weight: 600;
                border-bottom: 1px solid #d1d1d1;
                div[data-role="trigger"] {
                    position: relative;
                    &:focus {
                        outline: 0;
                    }
                    &:after {
                        position: absolute;
                        right: 11px;
                        top: 50%;
                        margin-top: -4.5px;
                        font-size: 9px;
                        color: #3f3f3f;
                        content: "\e975";
                        font-family: "icomoon";
                        -webkit-transition: -webkit-transform .2s ease-in-out;
                        -ms-transition: -ms-transform .2s ease-in-out;
                        transition: transform .2s ease-in-out;
                    }
                }
                &:focus {
                    outline: 0;
                }
                &.active {
                    div[data-role="trigger"] {
                        &:after {
                            content: "\e981";
                        }
                    }
                }
            }
            div[data-role="content"] {
                padding: 20px;
                background: #fafafa;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                color: #333;
                a {
                    color: $brand-primary;
                    text-decoration: underline;
                    &:hover {
                        color: $brand-secondary;
                        text-decoration: none;
                    }
                    &:active {
                        color: $brand-secondary;
                        text-decoration: none;
                    }
                    &:focus {
                        color: $brand-secondary;
                        text-decoration: none;
                    }
                }
            }
        }
    }

}
.contact-form-fieldset {
    background: $brand-primary;
    padding: 45px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 40px;
    @include media-query(991px) {
        margin-top: 50px;
    }
    h2 {
        margin: 0 0 10px;
        padding: 0 0 4px 0;
        text-transform: uppercase;
        color: white;
        font-weight: bold;
        font-size: 24px;
        font-family: inherit;
        border-bottom: 3px solid white;
    }

    label {
        margin-top: 20px;
        color: #c8d9ea;
        font-size: 14px;
        font-weight: 400;

        word-wrap: break-word;
        -webkit-word-wrap: break-word;
        -moz-word-wrap: break-word;
        white-space: normal;
        //word-break: break-all;

        span {
            &:after {
                color: #c8d9ea !important;
            }
        }
    }
    .company-name {
        span {
            &:after {
                display: none;
            }
        }
    }
    .contactyou {
        .control {
            label {
                width: 30%;
            }
        }
        input[type="radio"] {
            margin-right: 10px;
        }
    }
    .agreeprivacypolicy {
        .control {
            float: left;
            display: block;
            height: 50px;
            padding-right: 10px;
        }
        label {
            font-size: 12px;
        }
        a {
            color: #FFF;
            text-decoration: underline;
            &:hover {
                color: #FFF;
                text-decoration: none;
            }
        }
    }
    input, select, textarea {
        border-radius: 5px;
    }

    textarea {
        padding: 2px 10px;
    }

    .control select, .control .input-text {
        height: 40px;
    }

    .control .input-textarea {
        width: 100%;
    }

    .control {
        div[generated="true"] {
            background: $brand-secondary;
            color: white;
            display: inline-block;
            width: 100%;
            padding: 3px 5px;
            border-radius: 3px;
        }
    }

    span.required-field-span {
        color: white;
        font-size: 12px;
        margin-top: 20px;
        display: inline-block;
    }
    .actions-toolbar {
        width: 100%;
        display: inline-block;
        padding-top: 20px;

        button {
            font-size: 16px !important;
            &:hover {
                background: #d63325 !important;
                @include transitions(400ms);
            }
            &:active {
                background: #d63325 !important;
                @include transitions(400ms);
            }
            &:focus {
                background: #d63325 !important;
                @include transitions(400ms);
            }
        }
    }
}