%read-more-button {
    display: inline-block;
    padding: 5px 12px;
    border-radius: 4px;
    color: #fff;
    background-color: $brand-secondary;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out;
    &:hover,
    &:focus {
        background-color: $brand-primary;
    }
}

.row-post, .event-details, .event-related {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    [class*="col-"], .col {
        display: flex;
        margin-bottom: 30px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .related-title {
        color: $pt-black;
        font-weight: 700;
        text-transform: capitalize;
    }
}

.post-related, .event-related {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 15px;
        display: block;
        width: calc(100% - 30px);
        height: 4px;
        background-color: $brand-secondary;
    }
}

.post-related {
    margin-top: 55px;
    margin-bottom: 55px;
    padding-top: 20px;
}

.row-post {
    .col {
        width: 50%;
        flex-basis: 50%;
        @include media-query(991px, 768px) {
            width: 100%;
            flex-basis: 100%;
        }
        @include media-query(767px) {
            width: 50%;
            flex-basis: 50%;
        }
        @include media-query(580px) {
            width: 100%;
            flex-basis: 100%;
        }
    }
    .col-sm-12 {
        width: 100%;
        flex-basis: 100%;
    }
}
.wp-sidebar .block-blog-archives .item a, .wp-sidebar .block-blog-categories .item a {
    border-bottom: 1px solid $pt-gray-04!important;
}
.is-blog {
    .page-title-wrapper {
        margin-bottom: 20px;
        &.page-title-wrapper-mobile {
			display: none;
		}
    }
    .pager {
        margin-bottom: 60px;
        padding-top: 40px;
        border-top: 3px solid $color-grey;
        text-align: left;
        @include media-query(767px) {
            text-align: center;
        }
        .toolbar-amount {
            display: none;
        }
        .pagination {
            text-align: left;
        }
    }
    #maincontent.container {
        padding-right: 0;
        padding-left: 0;
    }
}
.wordpress-post-view {
    .page-title-wrapper {
        margin-bottom: 20px;
        &.page-title-wrapper-mobile {
			display: none;
		}
    }
}
.wp-sidebar {
    .block-blog {
        margin-bottom: 40px;

        .block-title {
            color: $pt-black;
            font-size: 24px;
            font-weight: 700;
            border-bottom: 3px solid $brand-secondary;
            text-transform: capitalize;
            padding-bottom: 0;
            margin-bottom: 5px;
            font-family: 'effra';
            > strong {
                font-weight: 700;
                font-size: inherit;
            }
        }

        // WordPress Tag cloud styles
        &-cloud {
            .tags-list {
                padding-top: 10px;

                li {
                    > a {
                        color: #333;

                        &:hover {
                            color: $brand-primary;
                            text-decoration: underline;
                        }
                    }

                    &:not(:last-child) {
                        &:after {
                            content: ", ";
                            display: inline;
                        }
                    }
                }
            }
        }

        &-archives,
        &-categories {
            .item a {
                display: block;
                color: #333;
                border-bottom: 1px solid $color-grey;
                padding: 12px 0;

                &:hover,
                &:focus {
                    color: $brand-primary;
                }
            }
        }
    }
}

.page-layout-2columns-left-reverse {
    .page-title-wrapper-mobile {
        display: none;

    }
    .post-list-wrapper {
        padding-left: 14%;
        @include media-query(1199px) {
            padding-left: 8%;
        }
        @include media-query(991px) {
            padding-left: 0;
        }
        .pages {
            .pagination {
                justify-content: center;
            }
        }
    }
}

.postTaxo {
    color: $brand-primary;
    font-weight: 700;
    span {
        vertical-align: text-top;
        margin-right: 5px;
    }
    .postTag {
        margin-top: 5px;
    }
    .postCat > span, .postTag > span {
        display: inline-block;
        vertical-align: middle;
        a {
            color: $brand-primary;
        }
    }
    .icon-list, .icon-tags {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }
    .icon-list {
        background-image: url('../images/list-icon-blue.svg');
    }
    .icon-tags {
        background-image: url('../images/icon-tags-blue.svg');
    }
}

.postDate {
    position: relative;
    width: 80px;
    .inner {
        position: absolute;
        top: 50%;
        left: 40px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        text-align: center;
        width: 80px;
        height: 80px;
        padding: 6px;
        color: #fff;
        background-color: $brand-secondary;
        border: 3px solid #fff;
        font-family: 'effra';
        font-weight: bold;
    }
    .month, .year {
        font-size: 11px;
        text-transform: uppercase;
    }
    .day {
        font-size: 36px;
        font-weight: 700;
        line-height: 0.85;
    }
}

.share-content {
    h3 {
        margin-top: 0;
        color: $brand-primary;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
    }
    a span {
        font-size: 0;
    }
}

#wp-archive-list, #wp-category-list {
    a {
        font-size: 16px;
    }
    .toggle-archives {
        display: block;
        padding: 12px 0;
        font-weight: 700;
        color: $brand-primary;
    }
}

.post-list {
    .post-list-item {
        background-color: #fff;
        border: 1px solid $color-grey;
        border-top: none;
        box-shadow: 0 0 10px rgba(#000, 0.1);
        border-radius: 10px;
        width: 100%;
        .post-image {
            position: relative;
            padding-bottom: 66%;
            width: 100%;
            height: 0;
            &.no-img .image {
                background-image: url('../images/icon-post.svg');
                background-size: 19.5% 19.5%;
            }
            .image {
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 100%;
                height: 100%;
                background-color: #dedede;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 10px 10px 0 0;
            }
        }

        .postMeta {
            padding: 5px 0;
            font-size: 0;
            border-top: 1px solid $color-grey;
            border-bottom: 1px solid $color-grey;

            .postDate,
            .postTaxo {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                a {
                    display: inline-block;
                    color: $brand-primary;
                }
            }
            .postDate {
                margin-left: 25px;
                .inner {
                    left: 25px;
                }
            }
            .postTaxo {
                width: calc(100% - 105px);
                padding-right: 15px;
                font-size: 12px;
                text-align: right;
                .icon-list, .icon-tags {
                    width: 14px;
                    height: 14px;
                }
            }
        }
        .postInfos {
            padding: 40px 25px 30px;
            .textInfos {
                min-height: 134px;
                @include media-query(580px) {
                    min-height: inherit;
                }
            }
            h2 {
                margin-top: 0;
                font-family: 'museo-slab';
                font-size: 20px;
                font-weight: 500;
                a {
                    color: $brand-primary;
                }
            }
            .read-more {
                margin-top: 15px;
                a {
                    @extend %read-more-button;
                    background: $brand-primary;
                    font-family: 'effra';
                    transition: all 0.15s ease-out;
                    &:hover {
                        background: $brand-hover!important;
                        color: white;
                        outline: 2px solid #fff;
                        box-shadow: 0 0 0 4px $brand-hover;
                        span {
                            color: #fff!important;
                        }
                    }
                    &:focus {
                        background: $brand-hover!important;
                        color: white;
                        outline: 2px solid #fff;
                        box-shadow: 0 0 0 4px $brand-hover;
                        span {
                            color: #fff!important;
                        }
                    }
                }
            }
        }
    }
}

.page-layout-2columns-right-post {
    .post-view {
        padding-right: 14%;
        @include media-query(1199px) {
            padding-right: 8%;
        }
        @include media-query(991px) {
            padding-right: 0;
        }
    }
}

.wordpress-page {
    .post-view {
        position: relative;
        padding-left: 14%;
        margin-bottom: 50px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: calc(7% - 15px);
            display: block;
            width: 1px;
            height: 100%;
            background-color: $pt-gray-04;
        }
        @include media-query(1199px) {
            padding-left: 8%;
            &:before {
                left: calc(4% - 15px);
            }
        }
        @include media-query(991px) {
            padding-left: 0;
            &:before {
                content: none;
            }
        }
        @include media-query(767px) {
            margin-bottom: 0;
        }
    }
}

.post-view {
    .post-header {
        font-size: 0;
        border-bottom: 4px solid $brand-secondary;
        min-height: 80px;
        margin-bottom: 15px;
        padding-bottom: 35px;
        .postDate, .post-title {
            display: inline-block;
            vertical-align: middle;
        }
        .postDate {
            margin-right: 20px;
        }
        .post-title {
            width: calc(100% - 100px);
            h1 {
                margin: 0;
                border: none;
                color: $pt-black;
                font-size: 32px;
                line-height: 34px;
                font-weight: 700;
                text-transform: capitalize;
                padding-bottom: 0;
                margin-bottom: 0;
                @include media-query(580px) {
                    font-size: 24px;
                    line-height: 26px;
                }
                @include media-query(440px) {
                    font-size: 21px;
                    line-height: 23px;
                }
            }
        }
    }
    .post-meta {
        padding-bottom: 15px;
        border-bottom: 1px solid #eaeaea;
        .postTaxo {
            font-size: 15px;
            .postTag {
                margin-top: 10px;
            }
        }
    }
    .post-content {
        font-size: 16px;
        .post-image {
            margin-right: 0;
            margin-left: 10px;
            float: right;
            //width: 450px;
            max-width: 55%;
            @include media-query(540px) {
                margin-left: 0;
                margin-bottom: 15px;
                width: 100%;
                max-width: 100%;
            }
        }
        h1, h2, h3, h4,h5, h6 {
            color: $pt-black;
            font-weight: bold;
            text-transform: capitalize;
            font-family: 'effra';
        }
        h2 {
            margin-top: 30px;
            text-transform: capitalize;
        }
        p, ol, ul, img {
            margin-bottom: 15px;
        }
        blockquote {
            font-style: italic;
            border-left: 4px solid $brand-primary;
            p:last-child {
                margin-bottom: 0;
            }
        }
        ol, ul {
            list-style-type: none;
        }
        ol {
            counter-reset: li;
            li {
                counter-increment: li;
                &:before {
                    content: counter(li)'.';
                    color: $brand-primary;
                    font-weight: 700;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1.5em;
                    margin-right: 0.5em;
                    text-align: right;
                }
            }
        }
        ul {
            li {
                strong {
                    font-family: 'effra';
                }
                &:before {
                    content: '\2022';
                    color: $brand-primary;
                    font-weight: 700;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1.5em;
                    margin-right: 0.5em;
                    text-align: right;
                }
            }
        }
        img {
            height: auto;
        }
        a:not(.social-media) {
            position: relative;
            font-weight: 500;
            color: $brand-primary;
            &:hover {
                text-decoration: underline;
            }
            &:after {
                color: $brand-hover;
            }
        }
        a.btn-primary {
            color: #fff;
            line-height: 26px;
            &:hover {
                text-decoration: none;
            }
        }
        .btn-primary {
            background-color: $brand-primary;
            padding-right: 6px;
            padding-left: 6px;
            display: inline-block;
            color: #fff;
            font-family: 'effra';
            text-transform: uppercase;
            font-size: 15px;
            margin-right: 0;
            font-weight: bold;
            border: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 100%;
            height: 48px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            transition: all 0.15s ease-out;
            cursor: pointer;
            &:after {
                color: $brand-hover;
            }
            &:focus {
                background: $brand-hover!important;
                color: white;
                outline: 2px solid #fff;
                box-shadow: 0 0 0 4px $brand-hover;
            }
            &:hover {
                background: $brand-hover!important;
                color: white;
                outline: 2px solid #fff;
                box-shadow: 0 0 0 4px $brand-hover;
            } 
        }
        .share-content {
            margin-top: 40px;
        }
        @include media-query(767px) {
            margin-bottom: 40px;
        }
    }
}
