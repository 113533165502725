
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/fonts/icomoon.eot?8ij020');
  src:  url('../fonts/icomoon/fonts/icomoon.eot?8ij020#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/fonts/icomoon.ttf?8ij020') format('truetype'),
  url('../fonts/icomoon/fonts/icomoon.woff?8ij020') format('woff'),
  url('../fonts/icomoon/fonts/icomoon.svg?8ij020#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'paymentlogos' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-back-to-top-icon:before {
  content: "\e900";
  color: #fff;
}
.icon-close-icon-blue:before {
  content: "\e901";
  color: #fff;
}
.icon-customer-support-icon:before {
  content: "\e902";
  color: #fff;
}
.icon-delete-icon:before {
  content: "\e903";
  color: #fff;
}
.icon-edit-icon:before {
  content: "\e904";
  color: #fff;
}
.icon-email-icon:before {
  content: "\e905";
  color: #fff;
}
.icon-facebook-icon-2:before {
  content: "\e906";
  color: #fff;
}
.icon-in-stock-icon:before {
  content: "\e907";
  color: #fff;
}
.icon-innovative-solutions-icon:before {
  content: "\e908";
  color: #fff;
}
.icon-linkedin-icon-2:before {
  content: "\e909";
  color: #fff;
}
.icon-out-of-stock-icon:before {
  content: "\e90a";
  color: #fff;
}
.icon-partnerships-icon:before {
  content: "\e90b";
  color: #fff;
}
.icon-plus-icon:before {
  content: "\e90c";
  color: #fff;
}
.icon-spares-box-icon:before {
  content: "\e90d";
  color: #fff;
}
.icon-tooltip-icon:before {
  content: "\e90e";
  color: #fff;
}
.icon-trade-public-login:before {
  content: "\e90f";
  color: #fff;
}
.icon-twitter-icon-2:before {
  content: "\e910";
  color: #fff;
}
.icon-why-first-choice-icon:before {
  content: "\e911";
  color: #fff;
}
.icon-search-icon:before {
  content: "\e912";
}
.icon-quick-order-icon:before {
  content: "\e913";
}
.icon-track-order-icon:before {
  content: "\e914";
}
.icon-login-account-icon:before {
  content: "\e915";
}
.icon-wishlist-icon:before {
  content: "\e916";
}
.icon-basket-icon:before {
  content: "\e917";
  color: #fff;
}
.icon-logo .path1:before {
  content: "\e918";
  color: rgb(255, 255, 255);
}
.icon-logo .path2:before {
  content: "\e919";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path3:before {
  content: "\e91a";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path4:before {
  content: "\e91b";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path5:before {
  content: "\e91c";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path6:before {
  content: "\e91d";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path7:before {
  content: "\e91e";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path8:before {
  content: "\e91f";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path9:before {
  content: "\e920";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path10:before {
  content: "\e921";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path11:before {
  content: "\e922";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path12:before {
  content: "\e923";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path13:before {
  content: "\e924";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path14:before {
  content: "\e925";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path15:before {
  content: "\e926";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path16:before {
  content: "\e927";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path17:before {
  content: "\e928";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path18:before {
  content: "\e929";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path19:before {
  content: "\e92a";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path20:before {
  content: "\e92b";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path21:before {
  content: "\e92c";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path22:before {
  content: "\e92d";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path23:before {
  content: "\e92e";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path24:before {
  content: "\e92f";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path25:before {
  content: "\e930";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path26:before {
  content: "\e931";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path27:before {
  content: "\e932";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path28:before {
  content: "\e933";
  margin-left: -4.115234375em;
  color: rgb(239, 64, 53);
}
.icon-logo .path29:before {
  content: "\e934";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path30:before {
  content: "\e935";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-logo .path31:before {
  content: "\e936";
  margin-left: -4.115234375em;
  color: rgb(0, 85, 175);
}
.icon-iso-logo:before {
  content: "\e937";
  color: #fff;
}
.icon-twitter-icon .path1:before {
  content: "\e938";
  color: rgb(255, 255, 255);
}
.icon-twitter-icon .path2:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-facebook-icon .path1:before {
  content: "\e93a";
  color: rgb(255, 255, 255);
}
.icon-facebook-icon .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-linkedin-icon .path1:before {
  content: "\e93c";
  color: rgb(255, 255, 255);
}
.icon-linkedin-icon .path2:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-linkedin-icon .path3:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-youtube-icon .path1:before {
  content: "\e93f";
  color: rgb(255, 255, 255);
}
.icon-youtube-icon .path2:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-youtube-icon .path3:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-youtube-icon .path4:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-youtube-icon .path5:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-youtube-icon .path6:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-youtube-icon .path7:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-youtube-icon .path8:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-youtube-icon .path9:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-youtube-icon .path10:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(0, 63, 130);
}
.icon-tagline .path1:before {
  content: "\e949";
  color: rgb(239, 64, 53);
}
.icon-tagline .path2:before {
  content: "\e94a";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path3:before {
  content: "\e94b";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path4:before {
  content: "\e94c";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path5:before {
  content: "\e94d";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path6:before {
  content: "\e94e";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path7:before {
  content: "\e94f";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path8:before {
  content: "\e950";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path9:before {
  content: "\e951";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path10:before {
  content: "\e952";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path11:before {
  content: "\e953";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path12:before {
  content: "\e954";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path13:before {
  content: "\e955";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path14:before {
  content: "\e956";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path15:before {
  content: "\e957";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path16:before {
  content: "\e958";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path17:before {
  content: "\e959";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path18:before {
  content: "\e95a";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path19:before {
  content: "\e95b";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path20:before {
  content: "\e95c";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path21:before {
  content: "\e95d";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path22:before {
  content: "\e95e";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path23:before {
  content: "\e95f";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path24:before {
  content: "\e960";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path25:before {
  content: "\e961";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path26:before {
  content: "\e962";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path27:before {
  content: "\e963";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path28:before {
  content: "\e964";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path29:before {
  content: "\e965";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path30:before {
  content: "\e966";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path31:before {
  content: "\e967";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-tagline .path32:before {
  content: "\e968";
  margin-left: -24.0546875em;
  color: rgb(0, 85, 175);
}
.icon-search-icon-white:before {
  content: "\e969";
  color: #fff;
}
.icon-white-arrow-left:before {
  content: "\e96a";
  color: #fff;
}
.icon-white-icon-right:before {
  content: "\e96b";
  color: #fff;
}
.icon-down-arrow-blue:before {
  content: "\e96c";
  color: #1f3b70;
}
.icon-up-arrow-blue:before {
  content: "\e96d";
  color: #1f3b70;
}
.icon-icon:before {
  content: "\e96e";
}
.icon-arrow-left-blue:before {
  content: "\e96f";
  color: #003773;
}
.icon-arrow-right-blue:before {
  content: "\e970";
  color: #003773;
}
.icon-close-icon:before {
  content: "\e971";
}
.icon-basket-icon-blue:before {
  content: "\e972";
  color: #003773;
}
.icon-breadcrumb-arrow:before {
  content: "\e973";
  color: #e0e0e0;
}
.icon-calendar-icon:before {
  content: "\e974";
}
.icon-down-arrow-white:before {
  content: "\e975";
  color: #fff;
}
.icon-menu-icon:before {
  content: "\e976";
  color: #fff;
}
.icon-grid-icon:before {
  content: "\e977";
}
.icon-OR-graphic .path1:before {
  content: "\e978";
  color: rgb(221, 221, 221);
}
.icon-OR-graphic .path2:before {
  content: "\e979";
  margin-left: -31.650390625em;
  color: rgb(221, 221, 221);
}
.icon-OR-graphic .path3:before {
  content: "\e97a";
  margin-left: -31.650390625em;
  color: rgb(197, 196, 196);
}
.icon-OR-graphic .path4:before {
  content: "\e97b";
  margin-left: -31.650390625em;
  color: rgb(197, 196, 196);
}
.icon-phone-icon:before {
  content: "\e97c";
}
.icon-selected-thumbnail:before {
  content: "\e97d";
  color: #1857a8;
}
.icon-list-icon-blue:before {
  content: "\e97e";
  color: #0055af;
}
.icon-list-icon:before {
  content: "\e97f";
}
.icon-location-icon:before {
  content: "\e980";
}
.icon-up-arrow-white:before {
  content: "\e981";
  color: #fff;
}
.icon-zoom-icon-with-label:before {
  content: "\e982";
}
.icon-zoom-icon:before {
  content: "\e983";
}
.icon-cips-logo:before {
  content: "\e984";
  color: #fff;
}
.icon-envelope-icon:before {
  content: "\e985";
}
.icon-blue-tick:before {
  content: "\e986";
  color: #1857a8;
}
