.panel.wrapper {
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: $pt-gray-01;
  .calltoaction-container {
    
    .slick-arrow {
      display: none !important;
    }
    .call-action-block {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 1px;
        transform: translateY(-50%);
        display: block;
        width: 1px;
        height: 100%;
        background-color: #f1f2f4;
      }
      &:first-child {
        &:before {
          content: none;
        }
      }

      .text {
        color: #333333;
        font-size: 12px;
        font-weight: 600;
        span {
          color: $brand-primary;
          @include media-query(1199px, 768px){
            display: block;
          }
          @include media-query(480px){
            display: block;
          }
        }
      }
    }
    @include media-query(991px){
      width: 100%;
    }
  }
  .mobile-links-container {
    display: flex;
    margin: 0 auto;
    max-width: 1170px;
    padding-right: 20px;
    @media (max-width: 767px) {
      padding-right: 0;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      padding: 0 20px;
    }
    .additional-header-links {
      @media (max-width: 767px) {
        display: none;
      }
    }
    .header-messages {
      flex: 2;
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        flex: unset;
        width: auto;
      }
      &:before {
        content: '';
        background: $pt-gray-01;
      }
      ul {
        display: flex;
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        @media (max-width: 767px) {
          justify-content: center;
        }
        li {
          &::before {
            font-family: 'icomoon';
            font-size: 13px;
            color: $brand-primary;
            width: 20px;
            height: 20px;
          }
          &.header-strapline {
            margin-right: 20px;
            padding: 10px 5px;
            height: 40px;
            display: block;
            color: $pt-black;
            @media (max-width: 767px) {
              margin: 0;
            }
            @media screen and (min-width: 768px) and (max-width: 1169px) {
              margin-right: 10px
            }
            h1 {
              font-size: 14px;
              margin-top: 0;
              line-height: 1.42857;
            }
          }
          &.header-phone {
            margin-right: 20px;
            @media (max-width: 1169px) {
              display: none;
            }
            a {
              color: $brand-primary;
              padding: 10px 5px;
              height: 40px;
              display: block;
              &:before {
                content: "\e97c";
                font-family: 'icomoon';
                margin-right: 5px;
                color: $brand-primary;
              }
            }
          }
          &.header-location {
            margin-right: 10px;
            @media (max-width: 1169px) {
              display: none;
            }
            a {
              color: $pt-black;
              padding: 10px 5px;
              height: 40px;
              display: block;
              &:before {
                content: "\e980";
                font-family: 'icomoon';
                margin-right: 5px;
                text-decoration: underline;
                color: $brand-primary;
              }
            }
            &:hover {
              background: $pt-gray-04;
            }
          }
          &.tablet-contact {
            margin-right: 10px;
            display: none;
            @media screen and (min-width: 768px) and (max-width: 1169px) {
                display: block;
                margin-right: 0;
            }
            a {
              color: $pt-black;
              padding: 10px 5px;
              height: 40px;
              display: block;
              &:before {
                content: "\e980";
                font-family: 'icomoon';
                margin-right: 5px;
                text-decoration: underline;
                color: $brand-primary;
              }
            }
            &:hover {
              background: $pt-gray-04;
            }
          }
        }
      }
    }
  }
}